import "./login-footer.scss";
import { useTranslation } from "react-i18next";
import moment from "moment";

const LoginFooter = () => {
  const { t } = useTranslation();
  var year = moment().format("YYYY");
  return (
    <>
      <div className="ep-login-footer">
        <p className="copy-rights">
          {`${t("common_footer_copyright")} ${year} TRICOR ${t(
            "common_footer_rights"
          )}`}
        </p>
      </div>
    </>
  );
};

export default LoginFooter;
