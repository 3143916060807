import axios from "axios";
import { useEffect } from "react";
import { RESPONSE_MESSAGE_ERROR } from "../../../constants";
import { UtilityService } from "../../../services/hrmnet-api";
import { StatusEnum } from "./feature-scheduler-panel";

const ResponseMessage = ({ data, updateStatus, updateMessages }) => {
  const { cancel, token } = axios.CancelToken.source();
  const requestConfig = {
    cancelToken: token,
  };

  useEffect(() => {
    UpdateClientSubscription(data, [data.id]);

    return () => {
      cancel(RESPONSE_MESSAGE_ERROR.CANCEL);
    };
  }, []);

  const UpdateClientSubscription = async (data, ids) => {
    var status = StatusEnum.fail;
    var message = [];
    updateMessages(message);

    try {
      const res = await UtilityService.utilityManageClientSubscriptions(
        {
          body: ids,
        },
        requestConfig
      );

      if (res && res.length > 0) {
        const _featureResponse = res.find((x) => x.featureName === data.name);
        if (_featureResponse) {
          message = _featureResponse?.messages;
          status = _featureResponse.status
            ? StatusEnum.completed
            : StatusEnum.fail;
        }
      }
    } catch (error) {
      message = [error];
      status = StatusEnum.fail;
    }

    if (message.length > 0) {
      updateMessages(message);
    }
    updateStatus(status);
  };

  const DisplayMessageContainer = () => {
    return <div />;
  };

  return DisplayMessageContainer();
};

export default ResponseMessage;
