import { useRef } from "react";
import { TEMPLATE_INDEX } from "../../../../constants/config";
import { UtilityService } from "../../../../services/hrmnet-api";
import UploadDocumentContainer from "../../../upload-document-container/upload-document-container";
import "./rental-form-configuration.scss";

const RentalFormConfiguration = () => {
  const documentUploader = useRef();
  const container = {
    title: "Rental Form Configuration",
    description: (
      <>
        Upload{" "}
        <a
          href={TEMPLATE_INDEX.eRentalFormsConfigurations}
          target="_blank"
          rel="noreferrer"
        >
          rental form configuration
        </a>
        .
      </>
    ),
  };

  const fileUpload = {};

  const modal = {
    title: "Upload rental form configuration",
    content: "Are you sure to upload rental form configuration?",
    primaryButtonText: "Sure",
  };

  const submitAction = async (params, options) => {
    return await UtilityService.utilityUploadRentaFormConfiguration(
      params,
      options
    );
  };

  return (
    <UploadDocumentContainer
      config={{ container: container, fileUpload: fileUpload, modal: modal }}
      submitAction={submitAction}
      ref={documentUploader}
    />
  );
};

export default RentalFormConfiguration;
