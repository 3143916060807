import "./portal.scss";

import HorizontalLayout from "./layouts/horizontal-layout";
import PortalRouter from "./portal-router";

function Portal(props) {
  return (
    <HorizontalLayout>
      <PortalRouter />
    </HorizontalLayout>
  );
}

export default Portal;
