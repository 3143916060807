import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ErrorPage } from "../../components/main-error-page/error-404";
import { clearClientInfo } from "../../redux/actions/client";

const NewClientOnboard = () => {
  const dispatch = useDispatch();
  const pageConfig = {
    title: "New Client Onboard",
    style: { margin: "1rem" },
    subtitle: "Work in progress",
  };

  useEffect(() => {
    dispatch(clearClientInfo());
  }, [dispatch]);

  return <ErrorPage {...pageConfig} />;
};

export default NewClientOnboard;
