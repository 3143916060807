import { RESPONSE_MESSAGE_TYPE, MESSAGE_SEVERITY } from "./../constants";

/**
 *
 * @param {*} res
 * @param {*} toast
 * @param {*} alert
 * @param {*} i18n
 */
export const MessageSwitch = async (res, toast, alert, i18n) => {
  let msg = alert || toast;

  if (msg === undefined || msg === null) {
    return;
  }

  msg.clear();
  var messages = [];
  if (Array.isArray(res.messages)) {
    messages = res.messages;
  } // message from error
  else {
    var text = res.data;
    if (res.data instanceof Blob) {
      text = await new Response(res.data).text();
      messages.push({
        statusText: res.statusText,
        text: text,
        type: RESPONSE_MESSAGE_TYPE.ERROR,
      });
    }

    if (res.data.Message) {
      messages.push({
        statusText: res.statusText,
        text: res.data.Message,
        type: RESPONSE_MESSAGE_TYPE.ERROR,
      });
    }
  }

  messages.forEach((element) => {
    const param = {};
    if (element.placeholder) {
      element.placeholder.forEach((e, placeIndex) => {
        param["placeIndex" + placeIndex] = e;
      });
    }

    switch (element.type) {
      case RESPONSE_MESSAGE_TYPE.ERROR: {
        msg.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: msg === alert ? "" : i18n.t(element.statusText),
          detail: (
            <div
              id="message-switch-error"
              className="hris-message-error"
              // dangerouslySetInnerHTML={{
              //   __html: element.text,
              // }}
            />
          ),
          life: 3000,
          sticky: msg === alert,
        });
        document.getElementById("message-switch-error").innerHTML =
          element.text;
        break;
      }

      case RESPONSE_MESSAGE_TYPE.ALERT: {
        alert.show({
          severity: MESSAGE_SEVERITY.INFO,
          detail: (
            <div
              id="message-switch-info"
              className="hris-message-info"
              // dangerouslySetInnerHTML={{
              //   __html: element.text,
              // }}
            />
          ),
          sticky: msg === alert,
        });
        document.getElementById("message-switch-info").innerHTML = element.text;
        break;
      }

      case RESPONSE_MESSAGE_TYPE.WARNING: {
        msg.show({
          severity: MESSAGE_SEVERITY.WARN,
          detail: (
            <div
              id="message-switch-warn"
              className="hris-message-warn"
              // dangerouslySetInnerHTML={{
              //   __html: element.text,
              // }}
            />
          ),
          life: 3000,
          sticky: msg === alert,
        });
        document.getElementById("message-switch-warn").innerHTML = element.text;
        break;
      }
      case RESPONSE_MESSAGE_TYPE.TOAST: {
        toast.show({
          severity: MESSAGE_SEVERITY.INFO,
          summary: i18n.t("misc_axios_notification"),
          detail: element.text,
          life: 3000,
        });
        break;
      }
      case RESPONSE_MESSAGE_TYPE.SUCCESS: {
        msg.show({
          severity: MESSAGE_SEVERITY.SUCCESS,
          summary: msg === alert ? "" : i18n.t("misc_axios_notification"),
          detail: element.text,
          life: 3000,
          sticky: msg === alert,
        });
        break;
      }

      default: {
        msg.show({
          severity: MESSAGE_SEVERITY.WARN,
          detail: (
            <div
              id="message-switch-warn"
              className="hris-message-detail"
              // dangerouslySetInnerHTML={{
              //   __html: element.text,
              // }}
            />
          ),
          life: 3000,
          sticky: msg === alert,
        });
        document.getElementById("message-switch-warn").innerHTML = element.text;
        break;
      }
    }
  });
};
