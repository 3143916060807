import { createStore } from "redux";
// import rootReducer from "./reducers";
import { persistStore } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import { parse, stringify } from "flatted";
import persistedReducer from "./reducers";

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);

const persistor = persistStore(store);

export { store, persistor };
// export default createStore(rootReducer);
