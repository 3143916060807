import { useRef } from "react";
import { TEMPLATE_INDEX } from "../../../../constants/config";
import { UtilityService } from "../../../../services/hrmnet-api";
import UploadDocumentContainer from "../../../upload-document-container/upload-document-container";
import "./employee-tab-configuration.scss";

const EmployeeTabConfiguration = () => {
  const documentUploader = useRef();

  const container = {
    title: "Employee Tabs Configuration",
    description: (
      <>
        Upload{" "}
        <a
          href={TEMPLATE_INDEX.EmployeeTabsConfiguration}
          target="_blank"
          rel="noreferrer"
        >
          employee tab configuration
        </a>{" "}
        that defines the layout of employee-myinfo webpage.
      </>
    ),
  };

  const fileUpload = {};

  const modal = {
    title: "Upload Employee Tabs Configuration",
    content: "Are you sure to upload employee tab configuration?",
    primaryButtonText: "Sure",
  };

  const submitAction = async (params, options) => {
    return await UtilityService.utilityUploadEmployeeTabConfiguration(
      params,
      options
    );
  };

  return (
    <UploadDocumentContainer
      config={{ container: container, fileUpload: fileUpload, modal: modal }}
      submitAction={submitAction}
      ref={documentUploader}
    />
  );
};

export default EmployeeTabConfiguration;
