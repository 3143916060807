import { AUTH_SET_INFO } from "../redux-constants";
import { AUTH_RESET_INFO } from "../redux-constants";
const initialState = {
  isValidUser: false,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case AUTH_SET_INFO: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case AUTH_RESET_INFO: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
