import { useRef } from "react";
import { UtilityService } from "../../../../services/hrmnet-api";
import UploadDocumentContainer from "../../../upload-document-container/upload-document-container";

const SettingsConfiguration = () => {
  const documentUploader = useRef();

  const container = {
    title: "Settings Configuration",
    description: (
      <>
        Upload settings configuration form and setting up global/company specify
        parameter settings.
      </>
    ),
  };

  const fileUpload = {};

  const modal = {
    title: "Upload Setting Configuration",
    content: "Are you sure to upload translation?",
    primaryButtonText: "Sure",
  };

  const submitAction = async (params, options) => {
    return await UtilityService.utilityUploadDefaultConfigurationSettings(
      params,
      options
    );
  };

  return (
    <UploadDocumentContainer
      config={{ container: container, fileUpload: fileUpload, modal: modal }}
      submitAction={submitAction}
      ref={documentUploader}
    />
  );
};

export default SettingsConfiguration;
