export enum STORAGE_KEY {
  //header
  HRMNET_ENTITY_NAME = "Entity-Name",
  HRMNET_TENANT_NAME = "Tenant-Name",
  HRMNET_LANGUAGE = "Language",

  //
  PATH_AFTER_LOGIN = "path_after_login",

  PASSWORD_POLICY = "PASSWORD_POLICY",
  PORTAL_CODE = "PORTAL_CODE",
  TOKEN_INFO = "TOKEN_INFO",

  //redux
  GLOBAL = "persist:global",
  ROOT = "persist:root",
}

export enum RESPONSE_MESSAGE_TYPE {
  ERROR = "Error",
  ALERT = "Alert",
  WARNING = "Warning",
  TOAST = "Toast",
  SUCCESS = "Success",
}

export enum MESSAGE_SEVERITY {
  ERROR = "error",
  SUCCESS = "success",
  WARN = "warn",
  INFO = "info",
}

export enum PATH {
  HOME = "home",
  CLIENTS = "clients",
  LOGOUT = "logout",
  LOGIN = "login",
  UPLOADTEMPLATE = "uploadtemplate",

  //r2
  DEFAULT = "",
  CLIENT_MANAGEMENT = "client-management",
  PRIVILEGED_ACCESS_MANAGEMENT = "privileged-access-management",
  NEW_CLIENT_ONBOARD = "new-client-onboard",
  LOG_FILES = "log-files",

  //r2
  CONFIGURATION = "configuration",
  CLIENT_DETAILS = "client-details",
  FEATURES = "client-subscriptions",
  //upload path
  UPLOADCOMPANYDOCUMENT = "uploadcompanydocument",
  EMPLOYEE_TAB_CONFIGURATION = "profile-tab-configuration",
  MESSAGE_TEMPLATE_CONFIGURATOIN = "upload-message-tamplate-configuration",
  RENTAL_FORM_CONFIGURATOIN = "rental-configuration",
  TRANSLATION = "translation-configuration",
  UNIFY_RENTAL_FORM_CONFIGURATOIN = "upload-unify-rental-form-configuration",
  SETTINGS_CONFIGURATION = "settings-configuration",

  // Redirect Client Portal URL and Query Parameter
  REDIRECT_CLIENT_PORTAL_URL = "https://hris-eep-dev.azurewebsites.net",
  QUERY_SOURCE_SUPPORT = "?source=support",
}

export enum ERROR_PAGE {
  MAIN_ERROR_404 = "404",
  ERROR_500 = "error-500",
  MAIN_ERROR_500 = "500",
}

export enum IMAGE_DEFAULT {
  TRICOR_LOGO = "/assets/images/logo.png",
  DEFAULT = "/assets/images/default.png",
  ERROR = "/assets/images/default.png",
  LOGIN_BACKGROUND = "/assets/images/login-bg.png",
}

export enum EEPORTAL_LABEL_LOGIN {
  LOGIN = "eeportal_login.label",
  PWD_POLICY = "eeportal_pwd_policy.label",
}

export enum EEPORTAL_LABEL_BASE_CONTROL {
  BASE_CONTROL = "eeportal_base_control.label",
}

export enum EEPORTAL_LABEL_GENERAL {
  MISC = "eeportal_misc.label",
  COMMON = "eeportal_common.label",
  DIALOG = "eeportal_dialog.label",
  PROFILE = "eeportal_profile.label",
}
export enum SSO_INTERACTION_TYPE {
  REDIRECT = "loginRedirect",
  POP_UP = "loginPopup",
}

export enum DATATABLE_MODE {
  LIST = "list",
}
export enum RESPONSE_MESSAGE_ERROR {
  CANCEL = "Cancel",
}
