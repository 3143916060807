import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { TieredMenu } from "primereact/tieredmenu";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IMAGE_DEFAULT, PATH } from "./../../../../../../constants";
import { setSideBar } from "./../../../../../../redux/actions/sidebar";
import "./main-user.scss";

/**
 *
 */
const MainUser = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isOpenSidebarMobile = useSelector(
    (state) => state.sidebar?.isOpenSidebarMobile
  );
  const name = useSelector((state) => state.user?.name);
  let op = useRef();

  const renderAvatar = (profileImageUrl) => {
    return (
      <img
        className="avatar-main"
        alt="user-avatar"
        src={profileImageUrl ?? IMAGE_DEFAULT.DEFAULT}
        onError={(e) => (e.target.src = IMAGE_DEFAULT.ERROR)}
      />
    );
  };

  const toggleOverLayPanel = async (e) => {
    op.toggle(e);
  };

  const items = [
    {
      label: "Log out",
      icon: "pi pi-mu pi-power-off",
      command: () => {
        history.push(`/${PATH.LOGOUT}`);
      },
    },
  ];

  return (
    <>
      <div className="main-user p-grid p-nogutter p-align-center">
        <Button
          type="button"
          onClick={(e) => {
            // Close mobile sidebar if open while opening the user profile overlay
            if (isOpenSidebarMobile) {
              let newSideBarInfo = {};
              newSideBarInfo = {
                isOpenSidebarMobile: false,
              };
              dispatch(setSideBar(newSideBarInfo));
            }
            toggleOverLayPanel(e);
          }}
          className="p-button-secondary p-button-text main-user-button"
          iconPos="right"
        >
          {renderAvatar()}
        </Button>
        <OverlayPanel
          ref={(el) => (op = el)}
          id="overlay_panel"
          className="main-user-panel"
          dismissable
          appendTo={document.getElementById("main-header")}
        >
          <div className="user-info">
            <div className="avatar">
              <div>{renderAvatar()}</div>
              <p className="fullname">{name}</p>
            </div>
            <div className="info">
              <TieredMenu model={items} />
            </div>
          </div>
        </OverlayPanel>
      </div>
    </>
  );
};
export default MainUser;
