import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Animated } from "react-animated-css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { BaseTablePortal } from "../../components";
import { DEFAULT_BT_SEARCH } from "../../components/base-table/base-table-model";
import { PATH, RESPONSE_MESSAGE_TYPE } from "../../constants";
import { clearClientInfo, setListTenant } from "../../redux/actions/client";
import { store } from "../../redux/store";
import { MessageSwitch } from "../../utils";
import { MainMyPortal } from "../layouts/components/main-header";
import {
  DefaultColumnConfig,
  DefaultDataTableConfig,
  DefaultDataTableResultObj,
} from "../utils";
import { AccessRightsService } from "./../../services/hrmnet-api/index";
import "./clients.scss";

const Clients = () => {
  const childDatas = useRef();
  const dispatch = useDispatch();
  const [state, setState] = useState({ displayedRecord: [] });
  const searchConfig = DEFAULT_BT_SEARCH;
  const match = useRouteMatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    //clear client info
    //affect sidebar, header display
    dispatch(clearClientInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableSearchFn = async () => {
    const result = DefaultDataTableResultObj(searchConfig);

    try {
      const res = await AccessRightsService.supportAccessGetUserClients();

      if (res) {
        result.datas = res.map((data) => {
          return {
            id: data.clientId,
            tenantName: data.clientDataStore,
            tenantFullName: data.clientFullName,
          };
        });
        result.total = res.length;
        dispatch(setListTenant({ datas: result.datas }));

        setState({ ...state, displayedRecord: result.datas });
      }

      return result;
    } catch (error) {
      return result;
    }
  };

  const tableSearchChild = async (tenantName) => {
    const result = DefaultDataTableResultObj(searchConfig);

    try {
      const res = await AccessRightsService.supportAccessGetCompanies({
        tenantName: tenantName,
      });

      if (res) {
        result.datas = res.map((data, index) => {
          return {
            id: index,
            key: data.value,
            label: data.name,
          };
        });
        result.total = res.length;
      }

      return result;
    } catch (error) {
      return result;
    }
  };

  const configModel = () => {
    /*const tenantNameBodyTemplate = (rowData) => {
      return (
        <Link
          to={{
            pathname: `${rowData.tenantName}/${PATH.HOME}`,
            state: rowData,
          }}
        >
          {rowData.tenantName}
        </Link>
      );
    };
    const clientFullNameTemplate = (rowData) => {
      return (
        <Link
          to={{
            pathname: `${rowData.tenantName}/${PATH.HOME}`,
            state: rowData,
          }}
        >
          {rowData.tenantFullName}
        </Link>
      );
    };*/

    function CopyToUrl(rowData) {
      navigator.clipboard.writeText(
        `${window.REACT_CLIENT_APP_URL}/${PATH.LOGIN}/${rowData.tenantName}`
      );
      MessageSwitch(
        {
          messages: [
            {
              type: RESPONSE_MESSAGE_TYPE.SUCCESS,
              text: "Copied!",
            },
          ],
        },
        store.getState().global?.toast,
        null,
        i18n
      );
    }

    const actionsTemplate = (rowData) => {
      return (
        <div className="action">
          <Link
            to={{
              pathname: `/${PATH.CLIENT_MANAGEMENT}/${PATH.CLIENT_DETAILS}`,
              state: rowData,
            }}
          >
            Manage Client
          </Link>
          |
          <MainMyPortal tenantName={rowData.tenantName} />|
          <Link to={`${match.path}`} onClick={() => CopyToUrl(rowData)}>
            Copy URL
          </Link>
        </div>
      );
    };

    const config = {};
    //config.tableConfig.stateStorage = "session"
    //config.tableConfig.stateKey = "tenants-session"
    config.title = "Client List";
    config.columns = [
      DefaultColumnConfig({
        header: "Client Code",
        key: "tenantName",
        control: { placeholder: "Search by client code" },
        // body: tenantNameBodyTemplate,
      }),
      DefaultColumnConfig({
        header: "Client Full Name",
        key: "tenantFullName",
        control: { placeholder: "Search by client full name" },
        // body: clientFullNameTemplate,
      }),
      DefaultColumnConfig({
        header: "Actions",
        key: "tenantName",
        body: actionsTemplate,
        filter: false,
      }),
    ];
    config.showGlobal = true;

    //layout for expander - display of entity list , TODO: R1
    const rowExpansionTemplate = (e, column) => {
      const entityNameTemplate = (rowData) => {
        const rowState = {
          ...e,
          datas: childDatas.current.getDataSync(),
          selectedEntity: rowData,
        };

        return (
          <Link
            to={{
              //pathname: `${e.tenantName}/${rowData.key}/${PATH.HOME}`,
              pathname: `${e.tenantName}/${PATH.HOME}`,
              state: rowState,
            }}
          >
            {rowData.label}
          </Link>
        );
      };

      try {
        const childConfig = {
          columns: [
            DefaultColumnConfig({
              header: "Entity",
              key: "label",
              body: entityNameTemplate,
            }),
          ],
          //tableConfig: {
          //    stateStorage: "session",
          //    stateKey: "entity-session",
          //    dataKey: "id"
          //}
        };

        return (
          <BaseTablePortal
            ref={childDatas}
            configModel={DefaultDataTableConfig(childConfig)}
            searchConfig={searchConfig}
            searchFn={() => tableSearchChild(e.tenantName)}
            isClientSize={true}
            id="clients-data-table-child"
          />
        );
      } catch (error) {
        return null;
      }
    };

    return DefaultDataTableConfig(
      config,
      state,
      setState,
      rowExpansionTemplate
    );
  };

  const currConfigModel = configModel();
  return (
    <div className="clients data-table-expander-container">
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
      >
        <BaseTablePortal
          configModel={currConfigModel}
          searchConfig={searchConfig}
          searchFn={() => tableSearchFn()}
          isClientSize={true}
          id="clients-data-table"
        />
      </Animated>
    </div>
  );
};

export default Clients;
