import "./main-sidebar.scss";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { ScrollPanel } from "primereact/scrollpanel";
import { Sidebar } from "primereact/sidebar";

import { setSideBar } from "../../../../redux/actions/sidebar";
import { setSideBarMenu } from "../../../../redux/actions/menu";

import { ERROR_PAGE } from "../../../../constants";
import { ProcessMenuTree } from "./main-menu-tree";
import MainSideBarLeaf from "./main-sidebar-leaf";

import { PrivateRoutes } from "../../../portal-router";

/**
 * Get the leaf keys in pathname (maximum depth should be 2)
 *
 * @param {*} leafKeys
 * @param {*} sidebarMenu
 * @param {*} returnKey
 * @returns
 */
const getDeepestValidLeafKeys = (leafKeys, sidebarMenu, returnKey = "") => {
  const leafKeySplitIdx = leafKeys.indexOf("~");
  const currentLeafKey =
    leafKeySplitIdx >= 0 ? leafKeys.substr(0, leafKeySplitIdx) : leafKeys;
  const remainingLeafKey =
    leafKeySplitIdx >= 0 ? leafKeys.substr(leafKeySplitIdx + 1) : "";

  if (!currentLeafKey) return returnKey;

  const targetMenu = sidebarMenu.find((menu) => menu.url === currentLeafKey);

  if (!targetMenu) return returnKey;

  returnKey = returnKey ? `${returnKey}~${currentLeafKey}` : currentLeafKey;
  return getDeepestValidLeafKeys(
    remainingLeafKey,
    targetMenu.subMenus,
    returnKey
  );
};

const getMenus = (history) => {
  try {
    const items = PrivateRoutes.find(
      (x) => x.path === window.location.pathname.split("/")[1]
    )?.subMenus?.filter((x) => x.displaySideBarMenu);

    // items.push({
    //   icon: "pi hris-policy-menu",
    //   id: 100,
    //   key: "Admin",
    //   name: "Admin",
    //   type: "Menu",
    //   url: "admin",
    //   subMenus: [{
    //     id: 12,
    //     key: "employee-data-amendment",
    //     name: "Employee Data Amendment",
    //     subMenus: [],
    //     type: "Menu",
    //     url: "admin/employee-data-amendment",
    //   }]
    // });
    if (items) {
      function UpdateUrl(x, rootUrl) {
        return {
          key: x.id,
          ...x,
          subMenus: x.subMenus?.map((y, index) => {
            return UpdateUrl(y, `${rootUrl}/${x.path}`);
          }),
          url: `${rootUrl}/${x.path}`,
          display: true,
        };
      }

      const menuItems = items.map((x) => {
        return UpdateUrl(x, "/" + window.location.pathname.split("/")[1]);
      });

      return menuItems;
    }

    return [];
  } catch (error) {
    history.push(`/${ERROR_PAGE.MAIN_ERROR_500}`);
    return [];
  }
};

function MainSideBar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sideBarInfo = useSelector((state) => state.sidebar) || null;
  const sideBarMenus = useSelector((state) => state.menu.sideBar);
  const selectedLang = useSelector(
    (state) => state.language.language?.selectedLang
  );
  const entityCode = useSelector(
    (state) => state.global?.clientInfo?.entityCode
  );

  let history = useHistory();
  let location = useLocation();
  const [menuTree, setMenuTree] = useState([]);

  const currIntialLoad = useRef(true);
  const currSelectedLang = useRef(selectedLang);
  const currEntity = useRef(entityCode);

  const isMenuTreeHasLabel = menuTree[0]?.label;

  /**
   * Update these when pathname format is updated.
   *
   * minLengthOfPathName => 1 when there is other info in pathname other than module && function code in the beginning of pathname string.
   *
   * If pathname only have module and function code in the beginning of pathname string,
   * please just use:
   *
   * minLengthOfPathName = 1
   *
   * for example: /app/mod/func, minLengthOfPathName = 3
   *
   * indexOfModuleCd is the index/position of module in pathname
   * indexOfFunctionCd is the index/position of function in pathname
   * appendWithRootPathName flag to ensure the /app/ is appended to moduleCd
   */
  const indexOfModuleCd = 2;
  const indexOfFunctionCd = 3;
  const minLengthOfPathName = 3; //normally is equal to indexOfFunctionCd
  const appendWithRootPathName = true;

  function GetPathNameRange(
    string,
    separator,
    range,
    currentRange,
    currentString
  ) {
    if (range === currentRange) {
      return currentString;
    }
    if (string.split(separator)[currentRange])
      currentString += `/${string.split(separator)[currentRange]}`;
    return GetPathNameRange(
      string,
      separator,
      range,
      currentRange + 1,
      currentString
    );
  }

  const caculateKeys = (pathname) => {
    const path = pathname;
    let split = path.split("/");
    let _expandedKeys = {};
    let leafKeys;
    let moduleCd, functionCd;
    if (split.length >= minLengthOfPathName) {
      moduleCd = appendWithRootPathName
        ? GetPathNameRange(path, "/", indexOfModuleCd + 1, 0, "")
        : split[indexOfModuleCd];
      functionCd = split[indexOfFunctionCd];
      _expandedKeys[moduleCd] = true;

      leafKeys = functionCd
        ? getDeepestValidLeafKeys(
            moduleCd + "~" + moduleCd + "/" + functionCd,
            sideBarMenus
          )
        : moduleCd;

      return {
        expandedKeys: _expandedKeys,
        moduleCd,
        leafKeys,
      };
    } else {
      return {
        expandedKeys: undefined,
        leafKeys: undefined,
      };
    }
  };

  // ----------main-sidebar--useEffect()---------------------------------------
  const resetSideBarInfo = {
    ...sideBarInfo,
    selectedKey: "",
    expandedKeys: {},
    leafKeys: "",
  };

  const menuTreeAndSideBarSetup = (info, menusItem) => {
    const genMenuTree = ProcessMenuTree(t, info, menusItem);
    const selectMenu = genMenuTree.find((x) => x.isActive);
    dispatch(
      setSideBar({
        ...info,
        isLeafNode:
          selectMenu !== undefined ? selectMenu.children?.length > 0 : false,
      })
    );
    setMenuTree(genMenuTree);
  };

  const getNavigation = async () => {
    // check if language got changed
    const isLangChange = currSelectedLang.current !== selectedLang;

    // check if it is initial load
    if (
      ((sideBarMenus.length === 0 || menuTree.length === 0) &&
        currIntialLoad.current) ||
      isLangChange
    ) {
      currIntialLoad.current = false; // set current initial load to false
      currSelectedLang.current = selectedLang; // remember the current language
      const menusItem = getMenus(history);
      dispatch(setSideBarMenu(menusItem));
    }

    // check if entity got changed
    const isEntityChange = currEntity.current !== entityCode;

    if (isEntityChange) {
      currEntity.current = entityCode; // remember the current entity
      const menusItem = getMenus(history);
      dispatch(setSideBarMenu(menusItem));
      // Reload to force all components to retrieve data from backend again
      window.location.reload();
    }

    if (sideBarMenus.length > 0) {
      const keys = caculateKeys(location.pathname);

      let selectedKey = keys.leafKeys;
      if (!selectedKey) {
        menuTreeAndSideBarSetup(resetSideBarInfo, sideBarMenus);
        return;
      }
      let _expandedKeys = {};
      let split = selectedKey.split("~");
      if (split.length === 1) {
        _expandedKeys[split[0]] = true;
      }

      if (split.length >= 2) {
        _expandedKeys[split[0]] = true;
        _expandedKeys[split[0] + "~" + split[1]] = true;
      }

      let newSideBarInfo = {
        selectedKey: selectedKey,
        expandedKeys: _expandedKeys,
      };
      menuTreeAndSideBarSetup(newSideBarInfo, sideBarMenus);
    }
  };

  useEffect(() => {
    const _getNavigation = async () => {
      await getNavigation();
    };
    _getNavigation();

    return () => {
      setMenuTree([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    history,
    location.pathname,
    t,
    sideBarMenus,
    selectedLang,
    entityCode,
    isMenuTreeHasLabel,
  ]);

  /* --------------------------------- Rework Sidebar Mobile Implementation Logic Start ------------------------------------- */
  const onDispatchRework = (props) => {
    let newSideBarInfo = {};
    let _expandedKeys = {};
    let leafKeys = {};
    let link;

    link = props.functionCd;
    history.push(link);

    let _hasSubMenu = props.functionCd.split("/");
    _expandedKeys[_hasSubMenu[0]] = true;
    _expandedKeys[_hasSubMenu[0] + "~" + props.functionCd] = true;

    leafKeys = props.moduleCd;

    newSideBarInfo = {
      selectedKey:
        _hasSubMenu.length === 2
          ? _hasSubMenu[0] + "~" + props.functionCd
          : props.functionCd,
      expandedKeys: _expandedKeys,
      leafKeys: leafKeys,
      isOpenSidebarMobile: props.isOpenSidebarMobile,
      isLeafNode: props.isLeafNode,
    };

    dispatch(setSideBar(newSideBarInfo));
  };

  const onSelectRework = async (e) => {
    let data = e.data;

    if (data.module) {
      let _rework = {
        functionCd: data.function?.url,
        isLeafNode: e.children !== undefined || e.leaf,
        isOpenSidebarMobile: data.function?.url?.split("/").length === 2,
      };

      if (data.module?.subMenus?.length > 0) {
        _rework = {
          ..._rework,
          isOpenSidebarMobile: e.children !== undefined,
        };
      } else if (e.leaf) {
        return;
      }

      onDispatchRework(_rework);
    }
  };

  // Sidebar Menus
  function SidebarMenus(props) {
    const menus = props.menus;

    return (
      <>
        <ul id="sidebar-menus-container">
          {menus.length > 0 ? (
            menus
              .filter((x) => x.display)
              .map((menu, index) => {
                return <SidebarMenuItem key={index} menuItem={menu} />;
              })
          ) : (
            <></>
          )}
        </ul>
      </>
    );
  }

  function SidebarMenuItem(props) {
    const menuItem = props.menuItem;
    const menuItemKey =
      menuItem.data.function && menuItem.data.function.functionCd;

    // If data.function is undefined then current selectedKey ==== menuItem.key
    const selectedKeyWithUndefinedFunction =
      !menuItemKey && sideBarInfo.selectedKey === menuItem.key;

    // If data.function is defined then current selectedKey === menuItemKey
    const selectedKeyWithDefinedFunction =
      menuItem.isActive && menuItem.display;

    const selectedSidebarMenu =
      selectedKeyWithUndefinedFunction || selectedKeyWithDefinedFunction;

    return (
      <>
        <li
          className={`${
            selectedSidebarMenu
              ? "sidebar-menu-item-list-container selected-sidebar-menu-item-list-container"
              : "sidebar-menu-item-list-container"
          }`}
          onClick={() => {
            //when same menu is repeatively clicked
            if (
              sideBarInfo.selectedKey
                .split("~")
                .find((x) => x === menuItem.key && menuItem.children)
            ) {
              let newSideBarInfo = {};
              newSideBarInfo = {
                ...sideBarInfo,
                isLeafNode: !sideBarInfo.isLeafNode,
              };
              dispatch(setSideBar(newSideBarInfo));
              return;
            }

            onSelectRework(menuItem);
          }}
        >
          <div
            className={`${
              selectedSidebarMenu
                ? "sidebar-menu-item-container selected-sidebar-menu-item-container"
                : "sidebar-menu-item-container"
            }`}
          >
            <div
              className={`${
                selectedSidebarMenu
                  ? `sidebar-menu-item-icon ${menuItem.data.module.icon} selected-sidebar-menu-item-icon`
                  : `sidebar-menu-item-icon ${menuItem.data.module.icon}`
              }`}
            />
            <div
              className={`${
                selectedSidebarMenu
                  ? "sidebar-menu-item-text selected-sidebar-menu-item-text"
                  : "sidebar-menu-item-text"
              }`}
            >
              {menuItem.data.module.name}
            </div>
          </div>
        </li>
      </>
    );
  }

  const renderSideBar = (isOpenSidebarMobile = true) => {
    let selectedSidebarSubMenus = sideBarInfo.isLeafNode ?? false;
    return (
      <Sidebar
        className={
          selectedSidebarSubMenus
            ? "sidebar-left sidebar-left-submenu-mobile-expand"
            : "sidebar-left"
        }
        visible={isOpenSidebarMobile}
        modal={true}
        showCloseIcon={false}
        baseZIndex={999}
        onHide={() => {
          let newSideBarInfo = {
            ...sideBarInfo,
            isOpenSidebarMobile: false,
          };
          dispatch(setSideBar(newSideBarInfo));
        }}
      >
        <ScrollPanel className="sidebar-left-scroll-panel">
          <SidebarMenus menus={menuTree} />
        </ScrollPanel>
        <MainSideBarLeaf
          onSelectRework={(e) => onSelectRework({ ...e, leaf: true })}
          menu={
            menuTree.length > 0 &&
            menuTree.find(
              (x) => x.isActive && x.display && x.children?.length > 0
            )
          }
        />
      </Sidebar>
    );
  };

  // Use Media Query to control what to display depending on device width
  const isDesktop = useMediaQuery({ minWidth: "769px" });
  const isMobile = useMediaQuery({ maxWidth: "768px" });

  return (
    <>
      {isDesktop && (
        <>
          <div className="p-d-none p-d-md-block">
            <div className={`card open`}>
              {renderSideBar(sideBarInfo.isOpenSidebar)}
            </div>
          </div>
        </>
      )}
      {isMobile && (
        <>
          <div className="hide-md">
            <div
              className={`card ${
                sideBarInfo.isOpenSidebarMobile ? "open" : "close"
              }`}
            >
              {renderSideBar(sideBarInfo.isOpenSidebarMobile)}
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default MainSideBar;
