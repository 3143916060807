import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import "./App.scss";
import {
  GuardedRoute,
  IdleTimer,
  MainModal,
  MainToast,
  Page404,
} from "./components/index";
import { PATH } from "./constants";
import { Login } from "./portal/index";
import MainLoading from "./portal/layouts/components/main-loading/main-loading";
import { LogoutService } from "./portal/login-modules/components";
import Portal from "./portal/portal";

function App() {
  const auth = useSelector((state) => state.auth);
  const modalIsOpen = useSelector((state) => state.modal.isOpen ?? false);
  const { ready } = useTranslation();

  // Start idle timer if user is logged in and autoSignoffEnabled is true
  // Remark: auto sign off timer values unit is in minute(s)
  const startIdleTimer = auth.isValidUser;

  if (!ready) {
    return <MainLoading />;
  }

  return (
    <>
      <Switch>
        <Route path={`/${PATH.LOGOUT}`}>
          <LogoutService />
        </Route>
        <GuardedRoute path={"/"} component={Portal} auth={auth.isValidUser}>
          <Route path={`/`} component={Login} exact={true} />
          <Route component={Page404} />
        </GuardedRoute>
      </Switch>
      {modalIsOpen && <MainModal />}
      <MainToast />
      {startIdleTimer && <IdleTimer />}
      <MainLoading />
    </>
  );
}

export default App;
