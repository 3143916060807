import {
  SET_LIST_TENANT,
  CLEAR_USER_INFO,
  SET_CLIENT_INFO,
  SET_SELECTED_TENANT,
} from "./../redux-constants";

/**
 * set list of entities
 */
export const setListTenant = (clientInfo) => {
  return {
    type: SET_LIST_TENANT,
    payload: clientInfo,
  };
};

/**
 * set client info
 */
export const setClientInfo = (clientInfo) => {
  return {
    type: SET_CLIENT_INFO,
    payload: clientInfo,
  };
};

/**
 * set selected entity
 */
export const setSelectedEntity = (clientInfo) => {
  return {
    type: SET_SELECTED_TENANT,
    payload: clientInfo,
  };
};

/**
 * clear user status
 */
export const clearClientInfo = () => {
  return {
    type: CLEAR_USER_INFO,
  };
};
