import { Button } from "primereact/button";
// import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { jsonEqual, sleep } from "./../../../utils/index";
import {
  BCProps,
  BCStates,
  isError,
  renderError,
  validateControl,
} from "./../base-control";
import "./input-control.scss";

export interface InputControlAction {
  /**
   * Max length
   */
  label?: string;
  /**
   * Max length
   */
  icon?: string;
  /**
   * Max length
   */
  tooltip?: string;
  /**
   * Max length
   */
  clickFn?: () => any;
  /**
   * Max length
   */
  className?: string;
  /**
   * onMouseDown
   */
  onMouseDown?: () => any; //aw
  /**
   * onMouseLeave
   */
  onMouseLeave?: () => any; //aw
  /**
   * onTouchStart
   */
  onTouchStart?: () => any; //aw
  /**
   * onTouchEnd
   */
  onTouchEnd?: () => any; //aw
  /**
   * onMouseUp
   */
  onMouseUp?: () => any; //aw
  /**
   * onTouchMove
   */
  onTouchMove?: () => any; //aw
}
export interface InputControlProps extends BCProps {
  /**
   * Max length
   */
  maxLength?: number;
  /**
   * Min length
   */
  minLength?: number;
  /**
   * pattern
   */
  pattern?: string;
  /**
   * pattern message
   */
  patternMessage?: string;
  /**
   * Force display Uppercase
   */
  tranformUpperCase?: boolean;
  /**
   * Action
   */
  action?: InputControlAction;
}
export interface InputControlState extends BCStates {}
const InputControl: React.FC<InputControlProps> = (props) => {
  const { t } = useTranslation();
  // extract props data
  const id = props.id || "";
  const lazy = props.fromFilter;
  const ruleList = props.ruleList || [];
  if (props.required) {
    ruleList.push({
      name: "required",
    });
  }
  let maxLength = props.maxLength;

  if (!maxLength) {
    if (props.type === "textarea") {
      maxLength = 500;
    } else {
      maxLength = 100;
    }
  }

  if (maxLength) {
    ruleList.push({
      name: "maxLength",
      param: maxLength,
    });
  }

  if (props.minLength) {
    ruleList.push({
      name: "minLength",
      param: props.minLength,
    });
  }
  if (props.type === "email") {
    ruleList.push({
      name: "pattern",
      // eslint-disable-next-line no-useless-escape
      param:
        /^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})*$/,
      error: t("base_control_input_email_invalid"),
    });
  }
  if (props.type === "phone") {
    ruleList.push({
      name: "pattern",
      // eslint-disable-next-line no-useless-escape
      param:
        /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/,
      error: t("base_control_input_email_invalid"),
    });
  }

  if (props.pattern) {
    ruleList.push({
      name: "pattern",
      param: props.pattern,
      error: props.patternMessage || t("base_control_input_field_invalid"),
    });
  }

  // init state control
  let initState: InputControlState = {
    touched: false,
    value: props.value,
    valueStr: (props.value || "").toString(),
    controlState: {
      invalid: false,
    },
  };
  initState.controlState =
    props.controlState || validateControl(ruleList || [], initState.value, t);
  const [state, setState] = useState(initState);
  const mountedRef = useRef(true);
  // unsubcribe
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  // Update state if control state changed
  useEffect(() => {
    const ruleList = props.ruleList || [];
    if (props.required) {
      ruleList.push({
        name: "required",
      });
    }

    let controlState =
      props.controlState || validateControl(ruleList || [], props.value, t);
    if (!mountedRef.current) return;
    setState({
      ...state,
      value: props.value,
      setDefault: true,
      controlState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.controlState, props.required, props.value]);
  // Update if rule update
  useEffect(() => {
    if (!mountedRef.current) return;
    onChange(state.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.required, props.ruleList]);

  useEffect(() => {
    if (
      props.defaultValue &&
      props.defaultValue !== state.lastDefault &&
      state.setDefault
    ) {
      onChange(props.defaultValue, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue, state.lastDefault]);

  // Update display value if state value changed
  useEffect(() => {
    if (lazy) {
      try {
        // debugger;
        if (id) {
          let elm = document.getElementById(id) as any;
          if (elm) elm.value = state.value || "";
        }
      } catch (error) {}
    }
  }, [id, lazy, state.value]);

  const onChange = async (value: string, updateLastDefault = false) => {
    if (value && maxLength && state.value && value.length > maxLength + 1) {
      value = value.substring(0, maxLength + 1);
    }

    let valueStr = value ? value.toString() : "";

    if (valueStr && props.tranformUpperCase) {
      valueStr = valueStr.toUpperCase();
    }

    const controlState = validateControl(ruleList, value, t);
    let _state = { ...state };
    if (updateLastDefault) {
      _state.lastDefault = props.defaultValue;
    }
    // onChange
    if (!lazy && props.onChange) {
      props.onChange({
        controlState: controlState,
        value: value,
        valueStr: valueStr,
      });
    }
    if (!lazy) {
      // do set state
      _state.value = value;
      _state.valueStr = valueStr;
      _state.controlState = controlState;
      if (!jsonEqual(_state, state)) {
        setState(_state);
      }
      return;
    }
    await localStorage.setItem(id, value);
    await sleep(500);
    const storageValue = await localStorage.getItem(id);
    if (storageValue === value) {
      if (props.onTrueUpdateValue) {
        props.onTrueUpdateValue({
          controlState: controlState,
          value: value,
          valueStr: valueStr,
        });
      }
      if (lazy && props.onChange) {
        props.onChange({
          controlState: controlState,
          value: value,
          valueStr: valueStr,
        });
      }

      if (lazy) {
        _state.value = value;
        _state.valueStr = valueStr;
        _state.controlState = controlState;
        if (_state !== state) {
          setState(_state);
        }
      }
    }
  };
  const onBlur = () => {
    if (props.onTrueUpdateValue) {
      props.onTrueUpdateValue({
        controlState: state.controlState,
        value: state.value,
        valueStr: state.valueStr,
      });
    }
  };
  const onKeyUp = (event: any) => {
    if (event.key === "Enter") {
      if (props.onTrueUpdateValue) {
        props.onTrueUpdateValue({
          controlState: state.controlState,
          value: state.value,
          valueStr: state.valueStr,
        });
      }
    }
  };
  const onKeyDown = (event: any) => {
    if (props.fromFilter) {
      try {
        if (id) {
          let elm = document.getElementById(id) as any;
          var startPos = elm.selectionStart;
          if (
            (!elm.value || elm.value.trim() === "" || startPos === 0) &&
            event.keyCode === 32
          ) {
            event.preventDefault();
          }
        }
      } catch (error) {}
    }
  };
  const onFocus = () => {
    if (props.onTouched) {
      props.onTouched();
      return;
    }
    if (!state.touched) {
      setState({
        ...state,
        touched: true,
      });
    }
  };
  const renderControl = () => {
    let commonProp = {
      ...props.config,
      id,
      className: `${props.className} ${
        isError(state, props) ? "p-invalid" : ""
      }  ${props.tranformUpperCase ? "text-uppercase" : ""}`,
      placeholder: props.placeholder,
      autoFocus: props.autoFocus,
      onChange: (event: any) => onChange(event.currentTarget.value),
      onBlur: () => onBlur(),
      onKeyUp: (event: any) => onKeyUp(event),
      onFocus: () => onFocus(),
      onKeyDown: (event: any) => onKeyDown(event),
      tooltipOptions: { position: "top" },
    };
    commonProp.tooltip = state.valueStr || "";
    if (!lazy) {
      commonProp.value = state.value || "";
    }

    return <InputText {...commonProp} />;
  };
  const renderAction = () => {
    if (!props.action) {
      return null;
    } else {
      return (
        <Button
          label={props.action?.label}
          icon={props.action?.icon}
          tooltip={props.action?.tooltip}
          tooltipOptions={{ position: "top" }}
          onClick={props.action?.clickFn}
          onMouseDown={props.action?.onMouseDown} // added custom function - aw
          onMouseLeave={props.action?.onMouseLeave} // added custom function - aw
          onTouchStart={props.action?.onTouchStart} // added custom function - aw
          onTouchEnd={props.action?.onTouchEnd} // added custom function - aw
          onMouseUp={props.action?.onMouseUp} // added custom function - aw
          onTouchMove={props.action?.onTouchMove} // added custom function - aw
          className={props.action?.className + " custom-input-action-btn"}
          type="button"
        />
      );
    }
  };

  return (
    <>
      <div
        className={`input-control-inner p-field ${
          props.noLabel ? "no-label" : ""
        }`}
      >
        <label htmlFor={props.id}>
          {props.label}
          {props.required && !props.noRequiredLabel ? (
            <small className="required p-invalid">&nbsp;*</small>
          ) : null}
          {props.tooltip ? (
            <Button
              type="button"
              tooltip={props.tooltip}
              tooltipOptions={{ position: "top" }}
              icon="pi pi-info-circle"
              className="p-button-rounded label-help p-button-text p-button-plain"
            />
          ) : null}
        </label>
        <div
          className={`p-inputgroup ${
            isError(state, props) ? "p-inputgroup-error" : ""
          }`}
        >
          {renderControl()}
          {renderAction()}
          {props.hintRight && (
            <span className={"control-hint-right"}>{props.hintRight}</span>
          )}
        </div>
        {props.hintBottom && (
          <div className={"control-hint-bottom"}>{props.hintBottom}</div>
        )}
        {renderError(state, props, t)}
      </div>
    </>
  );
};

export default InputControl;
