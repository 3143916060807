import "./horizontal-layout.scss";

import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

//components
import {
  MainHeader,
  MainSideBar /*BottomNavBar, ContentFooter*/,
} from "./index";
import { Footer } from "../login-modules/components";

/**
 * Horizontal Layout
 */
const HorizontalLayout = (props) => {
  const sideBar = useSelector((state) => state.sidebar);
  const tenantName = useSelector((state) => state.client?.tenantName);

  let expandedKeysIsDOC = sideBar?.isLeafNode || false;
  let isSidebarMobileOpen = sideBar?.isOpenSidebarMobile || false;
  const isDesktop = useMediaQuery({ minWidth: "769px" });
  const isMobile = useMediaQuery({ maxWidth: "768px" });

  // Disable scrolling in mobile when sidebar is open
  useEffect(() => {
    if (isMobile && isSidebarMobileOpen)
      document.body.classList.add("block-scroll-in-mobile");
    return () => {
      if (isMobile) document.body.classList.remove("block-scroll-in-mobile");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidebarMobileOpen]);

  const mainClassStyle = () => {
    if (tenantName) {
      return `open-sidebar ${
        expandedKeysIsDOC && isDesktop
          ? "main-content push-margin"
          : "main-content"
      }`;
    } else {
      if (!isDesktop) {
        return `main-content`;
      } else return "";
    }
  };

  return (
    <>
      <MainHeader />
      {tenantName && <MainSideBar />}
      <main className={mainClassStyle()}>
        {props.children}
        {!isMobile && <Footer />}
      </main>
      {/*isMobile && <BottomNavBar />*/}
    </>
  );
};

export default HorizontalLayout;
