import "./file-upload.scss";
import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import { getFileSizeString } from "./utils";
import { makeId } from "../../../utils";

interface CustomFile {
  id: string | number;
  name: string;
  size: string;
}
interface FileUploadProps {
  readOnlyHeader: {
    filename: string;
    action: string;
  };
  files: (CustomFile | File)[];
  onSelect: any;
  onRemove: any;
  onDownload: any;
  onFileError: any;
  multiple?: boolean;
  accept?: string;
  maxFileSize?: number;
  hideDownload: boolean;
  onTouched?: (e: boolean) => any;
  renderCustomTool?: any;
}

const FileUpload = (props: FileUploadProps) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    dragging: false,
    dragCounter: 0,
  });
  const dropRef =
    React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    const div = dropRef.current as any;
    div.addEventListener("dragenter", handleDragIn);
    div.addEventListener("dragleave", handleDragOut);
    div.addEventListener("dragover", handleDrag);
    div.addEventListener("drop", (e: any) => handleDrop(e));
    return () => {
      div.removeEventListener("dragenter", handleDragIn);
      div.removeEventListener("dragleave", handleDragOut);
      div.removeEventListener("dragover", handleDrag);
      div.removeEventListener("drop", handleDrop);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Drag Event handlers
  const handleDragIn = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    setState((state) => ({
      ...state,
      dragging: true,
    }));
  };

  const handleDragOut = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    setState((state) => ({
      ...state,
      dragging: false,
    }));
  };

  const handleDrag = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const checkFiles = (files: any[]) => {
    let isValid = true;

    // file size check
    if (props.maxFileSize != null) {
      const max = props.maxFileSize;
      // const isValid = targetFiles.every((x: File) => x.size <= max)
      isValid = files.every((x: File) => {
        return x.size <= max;
      });
    }

    // file extension check
    if (props.accept) {
      files.every((x: any) => {
        return props.accept
          ?.split(",")
          .includes(x.name.toLowerCase().split(".").pop());
      });
      isValid =
        isValid &&
        files.every((x: any) =>
          props.accept
            ?.split(",")
            .includes("." + x.name.toLowerCase().split(".").pop())
        );
    }
    return isValid;
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const targetFiles = Array.from(e.dataTransfer.files);

      if (checkFiles(targetFiles)) {
        props.onSelect?.({
          files: targetFiles,
          touched: true,
        });
      } else {
        props.onFileError?.({ defaultMessage: t("base_control_upload_error") });
      }

      e.dataTransfer.clearData();
    }
    setState((state) => ({
      ...state,
      dragging: false,
    }));
  };

  const handleClickUpload = () => {
    inputRef.current.click();

    if (props.onTouched) {
      props.onTouched(true);
    }
  };

  const handleCancel = () => {
    if (props.onRemove) {
      props.files.forEach((x: any, index: number) => {
        props.onRemove({
          index: index,
        });
      });
    }
  };

  const handleSelectFile = (e: any) => {
    let targetFiles = e.target.files;

    if (!targetFiles || targetFiles.length <= 0) {
      return;
    }

    targetFiles = Array.from(targetFiles);

    if (checkFiles(targetFiles)) {
      props.onSelect?.({
        files: targetFiles,
      });
    } else {
      props.onFileError?.({ defaultMessage: t("base_control_upload_error") });
    }
    e.target.value = null;
  };

  const handleDownloadFile = (e: any, f: number) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.onDownload) {
      props.onDownload(f);
    }
  };

  const handleRemoveFile = (e: any, index: number) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.onRemove) {
      props.onRemove({
        index: index,
      });
    }
    if (props.onTouched) {
      props.onTouched(true);
    }
  };

  const renderFileList = () => {
    return (
      <DataTable
        emptyMessage={t("base_control_upload_no_file")}
        className="file-download-table"
        value={props.files}
      >
        <Column className="filename-column" body={(f) => f.name} />
        <Column
          className="file-size-column"
          body={(f) => getFileSizeString(f.size)}
        />
        <Column
          className="action-column"
          body={(f, colProps: { rowIndex: number }) => (
            <div className="action-button-group">
              {props.hideDownload && !f.id ? (
                <div className="dummy-button"></div>
              ) : (
                <Button
                  type="button"
                  icon="pi pi-download"
                  className="p-button download-file-button"
                  onClick={(e) => {
                    handleDownloadFile(e, f);
                  }}
                ></Button>
              )}
              {props.multiple === true && (
                <Button
                  type="button"
                  icon="pi pi-times"
                  className="p-button remove-button"
                  onClick={(e) => {
                    handleRemoveFile(e, colProps.rowIndex);
                  }}
                ></Button>
              )}
            </div>
          )}
        />
      </DataTable>
    );
  };

  return (
    <div className="file-upload">
      <div className="file-upload__header">
        <input
          type="file"
          multiple={!!props.multiple}
          onChange={handleSelectFile}
          ref={inputRef}
          style={{ display: "none" }}
          accept={props.accept || undefined}
          id={makeId()}
        />
        <div
          className="p-button upload-button"
          onClick={handleClickUpload}
        >{`${t("base_control_upload_upload_button")} (${
          props.files ? props.files.length : 0
        })`}</div>
        <div
          className={`p-button upload-button ${
            props.files && props.files.length > 0 ? "" : "disable"
          }`}
          onClick={handleCancel}
        >
          Cancel
        </div>
        {!!props?.renderCustomTool && props?.renderCustomTool()}
      </div>
      <div
        className={`file-upload__drop-zone ${state.dragging ? "dragging" : ""}`}
        ref={dropRef}
      >
        {props.files && props.files.length > 0 && renderFileList()}
      </div>
    </div>
  );
};

export default FileUpload;
