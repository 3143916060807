import { SET_GLOBAL } from "../redux-constants";
const initialState = {};

export default function global(state = initialState, action) {
  switch (action.type) {
    case SET_GLOBAL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
