const DocumentMessage = ({ message }) => {
  const renderMessages = () => {
    return message.map((x, index) => {
      if (x.type === "Success") {
        return (
          <small
            key={`support-document-file-upload-message-${index}`}
            className="p-d-block support-document-file-upload-message"
          >
            {x.text}
          </small>
        );
      }

      if (x.type === "Error") {
        return (
          <small
            key={`support-document-file-upload-error-${index}`}
            className="p-invalid p-d-block p-invalid-custom"
          >
            {x.text}
          </small>
        );
      }

      return <></>;
    });
  };

  return (
    <>
      {Array.isArray(message) ? (
        renderMessages()
      ) : (
        <small
          id={`support-document-file-upload-message`}
          className="p-d-block support-document-file-upload-message"
        >
          {message}
        </small>
      )}
    </>
  );
};

export default DocumentMessage;
