import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Logout } from "../../portal/login-modules/components/sso-login";
import { clearRedux } from "../../redux/actions/clear-redux";
import { setCountdownTimer } from "../../redux/actions/countdown-timer";
import { openModal } from "../../redux/actions/modal";
import { CountdownTimer } from "./components";

const IdleTimer = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const client = useSelector((state) => state.client);
  const countdownTimerContent = useSelector((state) => state.countdownTimer);

  // ---------------------- Start of idle tracking ------------------------------------------------------
  const convertToMilliseconds = (time) => {
    // Milliseconds = time * 60 * 1000
    return time * 60 * 1000;
  };

  const convertToDateTimeCd = (time) => {
    // convertToDateTimeCd = new Date().getTime() + convertToMilliseconds(time);
    return new Date().getTime() + convertToMilliseconds(time);
  };

  const convertToSeconds = (time) => {
    // Seconds = time * 60
    return time * 60;
  };

  // const userSetIdleTimeout = 1000 * 10; // For testing - 10 seconds
  // Get user autoSignoffIdleTime and convert to milliseconds
  const userSetIdleTimeout = convertToMilliseconds(
    client?.autoSignoffIdleTime ?? window.REACT_APP_AUTO_SIGN_OFF_IDLE_TIME
  );

  // const timeBeforeAutoSignOff = 1000 * 5; // For testing - 5 seconds
  // Get user autoSignoffAlertCountDown and convert to milliseconds
  const timeBeforeAutoSignOff = convertToMilliseconds(
    client?.autoSignoffAlertCountDown ??
      window.REACT_APP_AUTO_AUTO_SIGN_OFF_ALERT_COUNT_DOWN
  );

  // Idle timeout calculation = user auto sign off idle time - user auto sign off alert countdown
  // For instance 15 minutes - 2 minutes = 13 minutes in milliseconds (idle time to timeout and display countdown timer modal)
  const idleTimeout = userSetIdleTimeout - timeBeforeAutoSignOff;

  // Then prompt countdown timer alert dialog
  const modalConfig = {
    title: t("misc_idle_dialog_title"),
    renderModalBody: () => {
      return (
        <CountdownTimer
          ssoRef={props.ssoRef}
          onComplete={() => {
            dispatch(
              // If countdown timer is up then disabled secondary button
              openModal({
                ...modalConfig,
                classNameMainDialog: "cd-main-complete-dialog",
                primaryButtonText: t(
                  "misc_idle_dialog_complete_primary_button"
                ),
                primaryButtonClickFn: async ({ closeFn }) => {
                  // Reset the idle timer
                  reset();
                  Logout(history);
                  closeFn();
                },
                hideSecond: true,
              })
            );
          }}
        />
      );
    },
    primaryButtonText: t("misc_idle_dialog_primary_button"),
    secondaryButtonText: t("misc_idle_dialog_secondary_button"),
    // Remove the close icon
    isClosable: false,
    primaryButtonClickFn: async ({ closeFn }) => {
      // Reset the idle timer
      reset();
      // Clear Redux
      dispatch(clearRedux());
      // Perform logout and history.push to login page
      Logout(history);
      closeFn();
    },
    secondButtonClickFn: ({ closeFn }) => {
      // Reset the idle timer
      reset();
      dispatch(setCountdownTimer({ isCountingDown: false }));
      closeFn();
    },
  };

  const handleOnIdle = () => {
    if (client?.autoSignoffEnabled || window.REACT_APP_AUTO_SIGN_OFF_ENABLED) {
      // Pause idle tracking on page refresh, if isCountingDown is true
      if (countdownTimerContent?.isCountingDown) {
        pause();
        return;
      }
      // Pause idle tracking
      pause();
      // For testing - 10 seconds
      // countdownTime: new Date().getTime() + 10 * 1000;
      const countdownObj = {
        userCustomizeCdTime: convertToSeconds(
          client?.autoSignoffAlertCountDown ??
            window.REACT_APP_AUTO_AUTO_SIGN_OFF_ALERT_COUNT_DOWN
        ),
        countdownTime: convertToDateTimeCd(
          client?.autoSignoffAlertCountDown ??
            window.REACT_APP_AUTO_AUTO_SIGN_OFF_ALERT_COUNT_DOWN
        ),
        isCountingDown: true,
      };
      dispatch(setCountdownTimer(countdownObj));
      dispatch(openModal(modalConfig));
    }
  };

  const { pause, reset } = useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  useEffect(() => {
    // Handle on page refresh
    const autoSignoffEnabled =
      client?.autoSignoffEnabled ?? window.REACT_APP_AUTO_SIGN_OFF_ENABLED;
    if (countdownTimerContent?.isCountingDown && autoSignoffEnabled) {
      dispatch(openModal({ ...modalConfig }));
      if (countdownTimerContent?.countdownTime === 0) {
        dispatch(
          openModal({
            ...modalConfig,
            classNameMainDialog: "cd-main-complete-dialog",
            primaryButtonText: t("misc_idle_dialog_complete_primary_button"),
            primaryButtonClickFn: async ({ closeFn }) => {
              // Reset the idle timer
              reset();
              // Clear Redux
              dispatch(clearRedux());
              // Perform logout and history.push to login page
              Logout(history);
              closeFn();
            },
            hideSecond: true,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalConfig]);
  // ---------------------- End of idle tracking --------------------------------------------------------
  return <></>;
};

export default IdleTimer;
