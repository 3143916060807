import "./main-loading.scss";

import Lottie from "react-lottie-player";
import loadingAnimation from "./main-loading.json";
import { useSelector } from "react-redux";

const MainLoading = () => {
  const loading = useSelector((state) => state.modal.loading);
  return (
    <>
      {loading && (
        <div className={`main-loading`}>
          <div>
            <Lottie
              loop
              animationData={loadingAnimation}
              play
              height={"100%"}
              width={"100%"}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default MainLoading;
