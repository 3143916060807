import {
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
} from "./serviceOptions";
const basePath = "";

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AccessRightsService {
  /**
   *
   */
  static accessGetMenus(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/access/menus";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessGetHomeShortcuts(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/access/shortcuts";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static accessGetMobileShortcuts(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/access/mobile-shortcuts";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static supportAccessGetUserClients(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/access/clients";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static supportAccessGetCompanies(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/access/companies";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static supportAccessVerifyClient(
    params: {
      /**  */
      clientDataStore?: string;
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/access/verifyclient";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { clientDataStore: params["clientDataStore"] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static supportAccessValidateUser(
    params: {
      /**  */
      emailId?: string;
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/access/validateuser";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { emailId: params["emailId"] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AuthenticationService {
  /**
   *
   */
  static authenticationAuthenticateByUserName(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_UserNameAuthenticationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/authentication/authenticatebyusername";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authenticationAuthenticateByEmail(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_EmailAuthenticationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/authentication/authenticatebyemail";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authenticationAuthenticateBySsoToken(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_SsoAuthenticationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/authentication/authenticatebyssotoken";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authenticationAuthenticateBySupportToken(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_SsoAuthenticationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/authentication/authenticatebysupporttoken";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authenticationAuthenticateByMfaOtp(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_OtpAuthenticationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/authentication/authenticatebyotp";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authenticationRefreshMfaOtp(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_RefreshOtpRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/authentication/refreshotp";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static authenticationRefreshToken(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_RefreshTokenRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/authentication/refresh-token";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static supportAuthenticationAuthenticateByAdSsoToken(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Authentication_SsoAuthenticationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/authentication/authenticatebysupportssotoken";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ContentService {
  /**
   * Get Company Policy
   */
  static contentGetCompanyPolicy(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/content/company-policy";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Privacy Policy
   */
  static contentGetPrivacyPolicy(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/content/privacy-policy";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Terms and Conditions
   */
  static contentGetTermsAndConditions(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/content/terms-and-conditions";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Assistance
   */
  static contentGetAssistance(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/content/assistance";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get FAQ
   */
  static contentGetFaq(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/content/faq";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static contentGetContentByContexts(
    params: {
      /**  */
      contexts?: string;
      /**  */
      language?: string;
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/content/getbycontexts";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        contexts: params["contexts"],
        language: params["language"],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static contentGetContentByKeys(
    params: {
      /**  */
      context?: string;
      /**  */
      keys?: string;
      /**  */
      language?: string;
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/content/getbykeys";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = {
        context: params["context"],
        keys: params["keys"],
        language: params["language"],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static contentGetContentByKey(
    params: {
      /**  */
      key?: string;
      /**  */
      language?: string;
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/content/getbykey";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );
      configs.params = { key: params["key"], language: params["language"] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static contentUpdateContentByKey(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Content_TransalationContentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/content";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "put",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DocumentsService {
  /**
   *
   */
  static documentGetPayslips(
    params: {
      /**  */
      employeeCode: string;
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/document/payslips/{employeeCode}";
      url = url.replace("{employeeCode}", params["employeeCode"] + "");

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static documentGetTaxReturns(
    params: {
      /**  */
      employeeCode: string;
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/document/taxreturns/{employeeCode}";
      url = url.replace("{employeeCode}", params["employeeCode"] + "");

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static documentGetCompensations(
    params: {
      /**  */
      employeeCode: string;
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/document/compensations/{employeeCode}";
      url = url.replace("{employeeCode}", params["employeeCode"] + "");

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static documentGetHrLetters(
    params: {
      /**  */
      employeeCode: string;
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/document/hr-letters/{employeeCode}";
      url = url.replace("{employeeCode}", params["employeeCode"] + "");

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static documentDownloadSingleFile(
    params: {
      /**  */
      fileId: number;
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/document/download/singlefile/{fileId}";
      url = url.replace("{fileId}", params["fileId"] + "");

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static documentGetFileDownload(
    params: {
      /**  */
      fileId: number;
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/document/download/{fileId}";
      url = url.replace("{fileId}", params["fileId"] + "");

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static documentUploadFiles(
    params: {
      /**  */
      tenantName: string;
      /**  */
      entityName: string;
      /**  */
      acceptLanguage: string;
      /**  */
      message: string;
      /**  */
      releaseOnDate: string;
      /**  */
      documentType: string;
      /**  */
      files: [];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/document";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        "Entity-Name": params["entityName"],
        "Accept-Language": params["acceptLanguage"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data = null;
      data = new FormData();
      if (params["message"]) {
        if (
          Object.prototype.toString.call(params["message"]) === "[object Array]"
        ) {
          for (const item of params["message"]) {
            data.append("Message", item as any);
          }
        } else {
          data.append("Message", params["message"] as any);
        }
      }

      if (params["releaseOnDate"]) {
        if (
          Object.prototype.toString.call(params["releaseOnDate"]) ===
          "[object Array]"
        ) {
          for (const item of params["releaseOnDate"]) {
            data.append("ReleaseOnDate", item as any);
          }
        } else {
          data.append("ReleaseOnDate", params["releaseOnDate"] as any);
        }
      }

      if (params["documentType"]) {
        if (
          Object.prototype.toString.call(params["documentType"]) ===
          "[object Array]"
        ) {
          for (const item of params["documentType"]) {
            data.append("DocumentType", item as any);
          }
        } else {
          data.append("DocumentType", params["documentType"] as any);
        }
      }

      if (params["files"]) {
        if (
          Object.prototype.toString.call(params["files"]) === "[object Array]"
        ) {
          for (const item of params["files"]) {
            data.append("Files", item as any);
          }
        } else {
          data.append("Files", params["files"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UtilityService {
  /**
   * Update translations based on uploaded excel file
   */
  static utilityUploadTranslation(
    params: {
      /**  */
      tenantName: string;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/upload-translation";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data = null;
      data = new FormData();
      if (params["file"]) {
        if (
          Object.prototype.toString.call(params["file"]) === "[object Array]"
        ) {
          for (const item of params["file"]) {
            data.append("file", item as any);
          }
        } else {
          data.append("file", params["file"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update rental form configuration based on uploaded excel file
   */
  static utilityUploadRentaFormConfiguration(
    params: {
      /**  */
      tenantName: string;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/upload-rentalform-configuration";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data = null;
      data = new FormData();
      if (params["file"]) {
        if (
          Object.prototype.toString.call(params["file"]) === "[object Array]"
        ) {
          for (const item of params["file"]) {
            data.append("file", item as any);
          }
        } else {
          data.append("file", params["file"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update unify rental form configuration based on uploaded excel file
   */
  static utilityUploadUnifyRentaFormConfiguration(
    params: {
      /**  */
      tenantName: string;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/upload-unify-rentalform-configuration";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data = null;
      data = new FormData();
      if (params["file"]) {
        if (
          Object.prototype.toString.call(params["file"]) === "[object Array]"
        ) {
          for (const item of params["file"]) {
            data.append("file", item as any);
          }
        } else {
          data.append("file", params["file"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update company document based on uploaded excel file
   */
  static utilityUploadCompanyDocument(
    params: {
      /**  */
      tenantName: string;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/upload-company-document";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data = null;
      data = new FormData();
      if (params["file"]) {
        if (
          Object.prototype.toString.call(params["file"]) === "[object Array]"
        ) {
          for (const item of params["file"]) {
            data.append("file", item as any);
          }
        } else {
          data.append("file", params["file"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update message template configuration based on uploaded excel file
   */
  static utilityUploadMessageTemplateConfiguration(
    params: {
      /**  */
      tenantName: string;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/upload-message-template-configuration";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data = null;
      data = new FormData();
      if (params["file"]) {
        if (
          Object.prototype.toString.call(params["file"]) === "[object Array]"
        ) {
          for (const item of params["file"]) {
            data.append("file", item as any);
          }
        } else {
          data.append("file", params["file"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update employee tab configuration based on uploaded excel file
   */
  static utilityUploadEmployeeTabConfiguration(
    params: {
      /**  */
      tenantName: string;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/upload-employee-tab-configuration";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data = null;
      data = new FormData();
      if (params["file"]) {
        if (
          Object.prototype.toString.call(params["file"]) === "[object Array]"
        ) {
          for (const item of params["file"]) {
            data.append("file", item as any);
          }
        } else {
          data.append("file", params["file"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update employee my info based on uploaded excel file
   */
  static utilityUploadEmployeeMyInfo(
    params: {
      /**  */
      tenantName: string;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/upload-employee-my-info";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data = null;
      data = new FormData();
      if (params["file"]) {
        if (
          Object.prototype.toString.call(params["file"]) === "[object Array]"
        ) {
          for (const item of params["file"]) {
            data.append("file", item as any);
          }
        } else {
          data.append("file", params["file"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update client configuration settings based on uploaded excel file
   */
  static utilityUploadClientConfigurationSettings(
    params: {
      /**  */
      tenantName: string;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/upload-client-configuration-settings";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data = null;
      data = new FormData();
      if (params["file"]) {
        if (
          Object.prototype.toString.call(params["file"]) === "[object Array]"
        ) {
          for (const item of params["file"]) {
            data.append("file", item as any);
          }
        } else {
          data.append("file", params["file"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update default configuration settings based on uploaded excel file
   */
  static utilityUploadDefaultConfigurationSettings(
    params: {
      /**  */
      tenantName: string;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/upload-default-configuration-settings";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "multipart/form-data",
        url,
        options
      );

      let data = null;
      data = new FormData();
      if (params["file"]) {
        if (
          Object.prototype.toString.call(params["file"]) === "[object Array]"
        ) {
          for (const item of params["file"]) {
            data.append("file", item as any);
          }
        } else {
          data.append("file", params["file"] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all features list
   */
  static utilityGetFeatureList(
    params: {
      /**  */
      tenantName: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/feature/list";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Manage all selected features for client subscription
   */
  static utilityManageClientSubscriptions(
    params: {
      /**  */
      tenantName: string;
      /** requestBody */
      body?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/manage-client-subscriptions";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all log files
   */
  static logGetAllLogFiles(
    params: {
      /**  */
      tenantName: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/log-files";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "get",
        "application/json",
        url,
        options
      );

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Download log file
   */
  static logDownloadLogsFile(
    params: {
      /**  */
      tenantName: string;
      /** requestBody */
      body?: HRIS_Api_Models_Request_Utility_LogFileDownloadRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + "/utility/log-files/download";

      options.headers = {
        "Tenant-Name": params["tenantName"],
        ...options.headers,
      };
      const configs: IRequestConfig = getConfigs(
        "post",
        "application/json",
        url,
        options
      );

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class HRIS_Api_Models_Request_Authentication_EmailAuthenticationRequest {
  /**  */
  "email"?: string;

  /**  */
  "password"?: string;

  constructor(data: undefined | any = {}) {
    this["email"] = data["email"];
    this["password"] = data["password"];
  }
}

export class HRIS_Api_Models_Request_Authentication_OtpAuthenticationRequest {
  /**  */
  "employeeCode"?: string;

  /**  */
  "otp"?: string;

  constructor(data: undefined | any = {}) {
    this["employeeCode"] = data["employeeCode"];
    this["otp"] = data["otp"];
  }
}

export class HRIS_Api_Models_Request_Authentication_RefreshOtpRequest {
  /**  */
  "employeeCode"?: string;

  constructor(data: undefined | any = {}) {
    this["employeeCode"] = data["employeeCode"];
  }
}

export class HRIS_Api_Models_Request_Authentication_RefreshTokenRequest {
  /**  */
  "token"?: string;

  /**  */
  "refreshToken"?: string;

  constructor(data: undefined | any = {}) {
    this["token"] = data["token"];
    this["refreshToken"] = data["refreshToken"];
  }
}

export class HRIS_Api_Models_Request_Authentication_SsoAuthenticationRequest {
  /**  */
  "token"?: string;

  constructor(data: undefined | any = {}) {
    this["token"] = data["token"];
  }
}

export class HRIS_Api_Models_Request_Authentication_UserNameAuthenticationRequest {
  /**  */
  "userName"?: string;

  /**  */
  "password"?: string;

  constructor(data: undefined | any = {}) {
    this["userName"] = data["userName"];
    this["password"] = data["password"];
  }
}

export class HRIS_Api_Models_Request_Content_TransalationContentRequest {
  /**  */
  "key"?: string;

  /**  */
  "context"?: string;

  /**  */
  "sourceText"?: string;

  /**  */
  "targetText"?: string;

  /**  */
  "language"?: string;

  /**  */
  "isHtml"?: boolean;

  constructor(data: undefined | any = {}) {
    this["key"] = data["key"];
    this["context"] = data["context"];
    this["sourceText"] = data["sourceText"];
    this["targetText"] = data["targetText"];
    this["language"] = data["language"];
    this["isHtml"] = data["isHtml"];
  }
}

export class HRIS_Api_Models_Request_Utility_LogFileDownloadRequest {
  /**  */
  "fileID"?: string;

  constructor(data: undefined | any = {}) {
    this["fileID"] = data["fileID"];
  }
}
