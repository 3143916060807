import { Card } from "primereact/card";
import "./../base-table/base-table.scss";
import "./base-container.scss";
/**
 * Render Default Container with header and description
 */
const BaseContainer = (props) => {
  return (
    <div className={props?.className}>
      <Card className="base-container" style={props.style}>
        <div className={`bt-table bt-table-custom`}>
          <div className="bt-header">
            <div className="bt-header-toolbar">
              <div className="bt-title-group">
                <div className="bt-title">{props.title}</div>
                <div className="bt-desc">{props.description}</div>
              </div>
            </div>
          </div>
          {props.children}
        </div>
      </Card>
    </div>
  );
};

export default BaseContainer;
