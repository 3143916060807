import "./array-control.scss";

import BaseControl, {
  isError,
  BCProps,
  BCStates,
  validateControl,
} from "./../base-control";
import React, { useEffect, useRef, useState } from "react";
import { jsonEqual, makeId } from "./../../../utils/index";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useTranslation } from "react-i18next";

export interface ArrayControlHeader {
  key: string;
  header: string;
}
export interface ArrayControlProps extends BCProps {
  headers?: ArrayControlHeader[];
}

export interface ArrayControlState extends BCStates {}

const ArrayControl: React.FC<ArrayControlProps> = (props) => {
  const { t } = useTranslation();
  // extract props
  const ruleList = props.ruleList || [];
  if (props.required) {
    ruleList.push({
      name: "required",
    });
  }

  // State
  let initState: ArrayControlState = {
    touched: false,
    loading: true,
    value: props.value ? props.value : null,
    valueStr: props.value ? props.value : null,
    controlState: {
      invalid: true,
    },
    form: {},
  };

  // prepare state
  const [state, setState] = useState(initState);
  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  /** Hook */
  useEffect(() => {
    const getData = async () => {
      let value = props.value ? props.value : [];
      const valueStr = JSON.stringify(value);
      const _ruleList = props.ruleList || [];
      if (props.required) {
        _ruleList.push({
          name: "required",
        });
      }

      const controlState = props.controlState
        ? props.controlState
        : validateControl(_ruleList || [], value, t);
      if (!mountedRef.current) return null;
      if (!Array.isArray(value)) {
        value = [];
      }
      setState({
        loading: false,
        touched: false,
        value,
        valueStr,
        controlState,
        form: {},
      });
    };
    getData();
  }, [props, setState, t]);

  const onChange = async (value: any, resetForm = false) => {
    const valueStr = JSON.stringify(value);
    const controlState = validateControl(ruleList, value, t);
    let _state = {
      ...state,
      value,
      valueStr,
      controlState,
      loading: false,
    } as any;
    if (resetForm) {
      _state.form = {};
    }
    if (props.onChange) {
      props.onChange({
        controlState: _state.controlState,
        value: _state.value,
        valueStr: _state.valueStr,
      });
    }
    if (props.onTrueUpdateValue) {
      props.onTrueUpdateValue({
        controlState: _state.controlState,
        value: _state.value,
        valueStr: _state.valueStr,
      });
    }
    if (!jsonEqual(_state, state)) {
      setState(_state);
    }
  };

  const renderColumn = () => {
    let headers = props.headers || [
      {
        key: "label",
        header: t("base_control_array_label"),
      },
      {
        key: "value",
        header: t("base_control_array_value"),
      },
    ];
    let headerColumn: any = [];
    headers.forEach((header: any, index: any) => {
      headerColumn.push(
        <Column
          key={index}
          field={header.key}
          header={header.header}
          body={(dataRow: any) => {
            if (dataRow.dump) {
              return (
                <BaseControl
                  key={makeId()}
                  fromFilter={false}
                  autoFocus={false}
                  required={true}
                  onChange={({ value }) => {
                    let _state = state;
                    _state.form[header.key] = value;
                    setState(_state);
                    return true;
                  }}
                  value={state.form[header.key]}
                />
              );
            } else {
              let value = dataRow[header.key];
              return value;
            }
          }}
        ></Column>
      );
    });
    headerColumn.push(
      <Column
        key={makeId()}
        field={"actions"}
        header={t("base_control_array_actions")}
        style={{ width: `5rem` }}
        body={(dataRow: any) => {
          if (dataRow.dump) {
            return (
              <>
                <Button
                  key={makeId()}
                  type="button"
                  icon="pi pi-plus"
                  onClick={() => {
                    let value = state.value || [];
                    if (!Array.isArray(value)) {
                      value = [];
                    }

                    let disabled = headers.find((x: any) => !state.form[x.key]);
                    if (disabled) {
                      return;
                    }
                    value.push({ ...state.form });
                    onChange(value, true);
                  }}
                  className="p-button-secondary p-button-text"
                />
              </>
            );
          }
          return (
            <>
              <Button
                type="button"
                icon="pi pi-trash"
                onClick={() => {
                  let index = state.value.indexOf(dataRow);
                  state.value.splice(index, 1);

                  let _state = { ...state };
                  _state.value = state.value;
                  onChange(state.value);
                }}
                className="p-button-secondary p-button-text"
              />
            </>
          );
        }}
      ></Column>
    );
    return headerColumn;
  };
  return (
    <>
      <div
        className={`array-control-inner p-field ${
          props.noLabel ? "no-label" : ""
        }`}
      >
        <label htmlFor={props.id}>
          {props.label}
          {props.required && !props.noRequiredLabel ? (
            <small className="required p-invalid">&nbsp;*</small>
          ) : null}
        </label>
        <div
          className={`p-inputgroup ${
            isError(state, props) ? "p-inputgroup-error" : ""
          }`}
        >
          <DataTable
            value={state.value ? [{ dump: true }, ...state.value] : []}
            style={{ width: "600px" }}
            className="array-control-table"
          >
            {renderColumn()}
          </DataTable>
        </div>
      </div>
    </>
  );
};

export default ArrayControl;
