import { BREADCRUMB_SET_DYNAMIC_PATHS } from "../redux-constants";
import { BREADCRUMB_RESET_DYNAMIC_PATHS } from "../redux-constants";

/**
 * set breadcrumb dynamic paths
 */
export const setBcDynamicPaths = (paths) => {
  return {
    type: BREADCRUMB_SET_DYNAMIC_PATHS,
    payload: paths,
  };
};

/**
 * reset breadcrumb dynamic paths
 */
export const resetBcDynamicPaths = () => {
  return {
    type: BREADCRUMB_RESET_DYNAMIC_PATHS,
  };
};
