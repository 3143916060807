/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
export interface IRequestOptions {
    headers?: any;
}

export interface IRequestPromise<T = any>
    extends Promise<IRequestResponse<T>> {}

export interface IRequestResponse<T = any> {
    data: T;
    status: number;
    statusText: string;
    headers: any;
    config: any;
    request?: any;
}

export interface IRequestInstance {
    (config: any): IRequestPromise;
    (url: string, config?: any): IRequestPromise;
    request<T = any>(config: any): IRequestPromise<T>;
}

export interface IRequestConfig {
    method?: any;
    headers?: any;
    url?: any;
    data?: any;
    params?: any;
}

// Add options interface
export interface ServiceOptions {
    axios?: IRequestInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(
    configs: IRequestConfig,
    resolve: (p: any) => void,
    reject: (p: any) => void
): Promise<any> {
    if (serviceOptions.axios) {
        return serviceOptions.axios
            .request(configs)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    } else {
        throw new Error("please inject yourself instance like axios  ");
    }
}

export function getConfigs(
    method: string,
    contentType: string,
    url: string,
    options: any
): IRequestConfig {
    const configs: IRequestConfig = { ...options, method, url };
    configs.headers = {
        ...options.headers,
        "Content-Type": contentType,
    };
    return configs;
}
