import { Button } from "primereact/button";
import {
  getBTConfig,
  getColumModel,
} from "../components/base-table/base-table-model";
import { DEFAULT_BT_SEARCH } from "../components/base-table/base-table-model";
import { DATATABLE_MODE } from "../constants";

/**
 * default data table object's result
 * @param {any} searchConfig
 */
export const DefaultDataTableResultObj = (searchConfig = DEFAULT_BT_SEARCH) => {
  return {
    datas: [],
    total: 0,
    searchConfig: searchConfig,
  };
};

/**
 * default object for column configuration
 * @param {any} header
 * @param {any} key
 * @param {any} control
 * @param {any} hideInMobile
 * @param {any} body
 * @param {any} frozen
 * @param {any} dataType
 */
export const DefaultColumnConfig = (props) => {
  let _columnConfig = {
    ...props,
    header: props.header,
    key: props.key,
    dataType: props.dataType ?? "string",
    control: props.control,
    mobileWidthAuto: true,
    hideInMobile: props.hideInMobile ?? false,
  };
  if (props.body) {
    _columnConfig.render = (rowData) => {
      return props.body(rowData);
    };
  }
  if (props.dataType === "date") {
    _columnConfig.filterMatchMode = "range";
  }

  return getColumModel(_columnConfig);
};

export const DefaultDataTableConfig = (
  config = {},
  state = null, //expand control
  setState = null, //expand control
  rowExpansionTemplate = null
) => {
  const hasExpansionTemplate =
    state &&
    setState &&
    rowExpansionTemplate &&
    config.columns.filter((x) => x.hideInMobile).length > 0;

  if (hasExpansionTemplate) {
    const expandAll = () => {
      setState({
        ...state,
        expandedRows: state?.displayedRecord,
        expandAllDisables: true,
        collapseAllDisabled: false,
      });
    };

    const collapseAll = () => {
      setState({
        ...state,
        expandedRows: null,
        expandAllDisables: false,
        collapseAllDisabled: true,
      });
    };
    config.mobileHeader = (
      <div className="table-header-container p-grid">
        <Button
          disabled={state?.expandAllDisabled}
          iconPos="right"
          icon="pi pi-plus"
          label="Expand All"
          onClick={expandAll}
          className="button-enabled"
        />
        <Button
          disabled={
            state?.collapseAllDisabled === undefined ||
            state?.collapseAllDisabled
          }
          iconPos="right"
          icon="pi pi-minus"
          label="Collapse All"
          onClick={collapseAll}
          className="button-enabled"
        />
      </div>
    );

    config.onRowToggle = (e) => {
      let newState = {
        ...state,
        expandedRows: e.data,
        expandAllDisabled: e.data.length === state.displayedRecord.length,
        collapseAllDisabled: e.data.length === 0,
      };
      setState(newState);
    };

    config.expandedRows = state?.expandedRows;
    config.rowExpansionTemplate = (e) =>
      rowExpansionTemplate(e, config.columns);
  }

  return getBTConfig(config);
};

export const SaveAsExcelFile = (buffer, fileName) => {
  import("file-saver").then((FileSaver) => {
    let EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let EXCEL_EXTENSION = ".xlsx";
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  });
};

export const ExportExcel = (file, props) => {
  SaveAsExcelFile(file, file.name);
};
