import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearClientInfo } from "../../redux/actions/client";
import { resetBcDynamicPaths } from "./../../redux/actions/breadcrumb";
import ShortcutIcons from "./components/shortcut-icons";
import "./home.scss";
import { WelcomeMessage } from "./index";

const Home = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetBcDynamicPaths());
    dispatch(clearClientInfo());
  }, [dispatch]);

  return (
    <div className="p-d-flex p-flex-column p-flex-lg-row home">
      <div className="p-col-12 p-md-12 p-lg-12 home-left-panel">
        <WelcomeMessage />
        <ShortcutIcons {...props} />
      </div>
    </div>
  );
};

export default Home;
