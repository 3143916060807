import { useSelector } from "react-redux";
import { PATH } from "../../../../constants/index";
import { setAuthInfo } from "../../../../redux/actions/auth";
import { closeLoading } from "../../../../redux/actions/modal";

/**
 * Perform login
 * @param {any} res
 * @param {any} dispatch
 * @param {any} history
 */
export const OnLogin = async (res, dispatch, history) => {
  if (res?.data && res?.data?.isValidUser) {
    dispatch(setAuthInfo(res.data));
    history.push("/");
  } else {
    dispatch(closeLoading());
    return false;
  }
};

export function Logout(history = null) {
  if (history) {
    history.push(`/${PATH.LOGOUT}`);
  } else window.location.href = `/${PATH.LOGOUT}`;
}

/**
 * get link when error 400 and error 500 encountered
 * */
export const GetLink = () => {
  const auth = useSelector((state) => state.auth);
  if (auth.isValidUser) {
    return "/";
  } else return PATH.LOGOUT;
};
