import "./welcome-message.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";

const WelcomeMessage = () => {
  const name = useSelector((state) => state.user?.name);

  // i18n
  const { t } = useTranslation();
  return (
    <>
      {/* <div className="p-col-12 p-md-9 p-lg-9"> */}
      <div className="p-grid welcome-bg">
        <span className="p-col-7 welcome-info">
          <span className="p-col">
            <div>
              <h1>{t("home_welcomeMessage_hi", { name: name })}</h1>
              <p>{t("home_welcomeMessage_readyToStart")}</p>
              {/* <div>Test --- {t("Jury_Duty_Leave")}</div> */}
            </div>
          </span>
        </span>
        <div className="p-col-5">
          <LazyLoadImage
            alt="logo"
            effect="blur"
            src="/assets/images/welcome.svg"
            className="welcome-img"
          />
        </div>
      </div>

      {/* </div> */}
    </>
  );
};

export default WelcomeMessage;
