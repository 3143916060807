import "./checkbox-control.scss";

import {
  isError,
  renderError,
  BCProps,
  BCStates,
  validateControl,
} from "./../base-control";
import React, { useState, useEffect } from "react";

import { Checkbox } from "primereact/checkbox";
import { useTranslation } from "react-i18next";

export interface CheckboxControlProps extends BCProps {}

export interface CheckboxControlState extends BCStates {}

const CheckboxControl: React.FC<CheckboxControlProps> = (props) => {
  const { t } = useTranslation();
  // extract props
  const ruleList = props.ruleList || [];
  if (props.required) {
    ruleList.push({
      name: "required",
    });
  }

  // State
  let initState: CheckboxControlState = {
    touched: false,
    value: props.value ? true : false,
    valueStr: props.value
      ? t("base_control_checkbox_true")
      : t("base_control_checkbox_false"),
    controlState: {
      invalid: false,
    },
  };
  initState.controlState =
    props.controlState ||
    validateControl(ruleList || [], initState.value, t, "checkbox");
  // prepare state
  const [state, setState] = useState(initState);

  // Update state if control state changed
  useEffect(() => {
    const ruleList = props.ruleList || [];
    if (props.required) {
      ruleList.push({
        name: "required",
      });
    }

    let controlState =
      props.controlState ||
      validateControl(ruleList || [], props.value, t, "checkbox");
    setState({
      ...state,
      value: props.value,
      setDefault: true,
      controlState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.controlState, props.required, props.value]);

  /**
   * On change
   * @param {*} event
   */
  const onChange = (value: boolean) => {
    const valueStr = value
      ? t("base_control_checkbox_true")
      : t("base_control_checkbox_false");
    const controlState = validateControl(ruleList, value, t, "checkbox");
    let _state = {
      ...state,
      value,
      valueStr,
      controlState,
    };
    if (props.onChange) {
      props.onChange({
        controlState: _state.controlState,
        value: _state.value,
        valueStr: _state.valueStr,
      });
    }
    if (props.onTrueUpdateValue) {
      props.onTrueUpdateValue({
        controlState: _state.controlState,
        value: _state.value,
        valueStr: _state.valueStr,
      });
    }
    setState(_state);
  };

  const onFocus = () => {
    if (props.onTouched) {
      props.onTouched();
      return;
    }
    if (!state.touched) {
      setState({
        ...state,
        touched: true,
      });
    }
  };
  return (
    <>
      <div className="checkbox-control p-field">
        <div
          className={`p-inputgroup ${
            isError(state, props) ? "p-inputgroup-error" : ""
          }`}
        >
          <Checkbox
            {...props.config}
            id={props.id}
            className={`${props.className} ${
              isError(state, props) ? "p-invalid" : ""
            }`}
            checked={state.value}
            onChange={(event) => onChange(event.checked)}
            onFocus={() => onFocus()}
            autoFocus={props.autoFocus}
          />
          <label className="p-checkbox-label" htmlFor={props.id}>
            {props.label}
          </label>
          {props.hintRight && (
            <span className={"control-hint-right"}>{props.hintRight}</span>
          )}
        </div>
        {props.hintBottom && (
          <div className={"control-hint-bottom"}>{props.hintBottom}</div>
        )}
        {renderError(state, props, t)}
      </div>
    </>
  );
};

export default CheckboxControl;
