import "./log-files.scss";
import moment from "moment";
import { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { useDispatch } from "react-redux";
import { BaseTablePortal } from "../../components";
import { BCType } from "../../components/base-control/base-control";
import { getControlModel } from "../../components/base-control/base-cotrol-model";
import { DEFAULT_BT_SEARCH } from "../../components/base-table/base-table-model";
import { clearClientInfo } from "../../redux/actions/client";
import {
  DefaultColumnConfig,
  DefaultDataTableConfig,
  DefaultDataTableResultObj,
} from "../utils";
import { UtilityService } from "../../services/hrmnet-api";
import { saveAs } from "file-saver";

const FILE_CREATED_CONFIGURATION_SORT_KEY = "fileCreatedDate";

const LogFiles = () => {
  const dispatch = useDispatch();

  /** Default search */
  const searchConfig = {
    ...DEFAULT_BT_SEARCH,
    sortObj: {
      key: FILE_CREATED_CONFIGURATION_SORT_KEY,
      direction: -1,
    },
  };
  const [state, setState] = useState({ displayedRecord: [] });

  useEffect(() => {
    dispatch(clearClientInfo());
  }, [dispatch]);

  const configModel = () => {
    const config = {};

    config.title = "Log File Lists";
    config.columns = [
      DefaultColumnConfig({
        header: "File Name",
        key: "fileName",
        control: { placeholder: "Search by file name" },
      }),
      DefaultColumnConfig({
        header: "File Created Date",
        key: "fileCreatedDate",
        control: getControlModel({
          placeholder: "Search by date",
          type: BCType.daterange,
        }),
        filterMatchMode: "range",
        body: (e) => (
          <div
            dangerouslySetInnerHTML={{
              __html: moment(e.fileCreatedDate).format("YYYY/MM/DD"),
            }}
          />
        ),
      }),
    ];

    config.rowHover = true;

    config.actionsSingle = [
      {
        renderIcon: "pi pi-download",
        clickFn: async (item) => {
          var filename;
          const axiosCallback = (resp) => {
            filename = resp.headers["x-suggested-filename"];
          };
          var res = await UtilityService.logDownloadLogsFile(
            {
              body: { fileId: item.fileName },
            },
            {
              responseType: "blob",
              callback: axiosCallback,
            }
          );

          if (res) {
            saveAs(res, filename);
          }
        },
      },
    ];
    config.actionWidth = 2;

    return DefaultDataTableConfig(config);
  };

  const tableSearchFn = async () => {
    const result = DefaultDataTableResultObj(searchConfig);

    try {
      var res = await UtilityService.logGetAllLogFiles();

      result.datas = res?.data;
      result.total = result.datas.length;

      setState({ ...state, displayedRecord: result.datas });

      return result;
    } catch (error) {
      return result;
    }
  };

  const currConfigModel = configModel();

  return (
    <div className="log-files">
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
      >
        <BaseTablePortal
          configModel={currConfigModel}
          searchConfig={searchConfig}
          searchFn={() => tableSearchFn()}
          isClientSize={true}
          id="log-files-data-table"
        />
      </Animated>
    </div>
  );
};

export default LogFiles;
