import { ScrollPanel } from "primereact/scrollpanel";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setSideBar } from "../../../../redux/actions/sidebar";

const MainSideBarLeaf = (props) => {
  const dispatch = useDispatch();
  const sideBarInfo = useSelector((state) => state.sidebar) || null;

  // My Docs Submenus
  function SidebarSubmenus(props) {
    const subMenus = props.subMenus;

    let selectedSidebarSubMenus = sideBarInfo.isLeafNode ?? true;

    return (
      <>
        <div
          className={`${
            selectedSidebarSubMenus
              ? "menu-item-my-docs-submenus-container selected-menu-item-my-docs-submenus-container"
              : "menu-item-my-docs-submenus-container"
          }`}
        >
          <div className="menu-item-my-docs-submenus-content">
            <ScrollPanel className="sidebar-submenu-scroll-panel">
              {props.subMenus !== undefined ? (
                <ul className="my-docs-container">
                  <SidebarSubmenuItem
                    key={subMenus.key}
                    subMenuItem={subMenus?.children}
                    onSelectRework={(e) => props.onSelectRework(e)}
                  />
                </ul>
              ) : null}
            </ScrollPanel>
          </div>
        </div>
      </>
    );
  }

  function SidebarSubmenuItem(props) {
    const subMenuItem = props.subMenuItem;

    return (
      <>
        {subMenuItem?.map((subMenu) => {
          const selectedSubMenuItem = sideBarInfo.selectedKey === subMenu.key;

          return (
            <li
              key={subMenu.key}
              className="my-docs-list-container"
              onClick={() => {
                if (selectedSubMenuItem) {
                  let newSideBarInfo = {};
                  newSideBarInfo = {
                    ...sideBarInfo,
                    isOpenSidebarMobile: false,
                  };
                  dispatch(setSideBar(newSideBarInfo));
                  return;
                }
                props.onSelectRework(subMenu);
              }}
            >
              <div
                className={`${
                  selectedSubMenuItem
                    ? "my-docs-item-container selected-my-docs-item-container"
                    : "my-docs-item-container"
                }`}
              >
                <div
                  className={`${
                    selectedSubMenuItem
                      ? "my-docs-item-text selected-my-docs-item-text"
                      : "my-docs-item-text"
                  }`}
                >
                  {subMenu.label}
                </div>
              </div>
            </li>
          );
        })}
      </>
    );
  }

  const renderContent = () => {
    if (props.menu) {
      return (
        <SidebarSubmenus
          subMenus={props.menu}
          onSelectRework={(e) => props.onSelectRework(e)}
        />
      );
    }
    return null;
  };

  return renderContent();
};
export default MainSideBarLeaf;
