import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { BaseContainer } from "../../../components";

const Configuration = (props) => {
  let match = useRouteMatch();
  const pageConfig = {
    title: "Configuration",
  };

  return (
    <Switch>
      <Route key="default-path" path={`${match.path}`} exact={true}>
        <Redirect to={`${match.path}/${props.subMenus[0].path}`} />
      </Route>
      {props.subMenus.map((route, index) => {
        return (
          <Route
            key={index}
            path={`${match.path}/${route.path}`}
            render={() => <route.component {...route} />}
            {...route.routeProperty}
          />
        );
      })}
      <Route exact path={`${match.path}`}>
        <BaseContainer {...pageConfig}></BaseContainer>
      </Route>
    </Switch>
  );
};

export default Configuration;
