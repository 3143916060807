import { BreadCrumb } from "primereact/breadcrumb";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PATH } from "../../../../../../constants";
import { PAGE_EXCLUDE_SIDEBAR } from "../../../../../../constants/config";
import { resetBcDynamicPaths } from "../../../../../../redux/actions/breadcrumb";
import { clearSideBar } from "../../../../../../redux/actions/sidebar";
import { PrivateRoutes } from "../../../../../portal-router";
import { ProcessMenuTree } from "./../../../main-sidebar/main-menu-tree";
import "./main-breadcrumb.scss";

const MainBreadcrumb = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const client = useSelector((state) => state.client);
  const sideBarInfo = useSelector((state) => state.sidebar) || null;
  const sideBarMenus = useSelector((state) => state.menu?.sideBar) || [];
  const isSideBarMenuAppendedToDynamicPath = false;

  const genMenuTree = ProcessMenuTree(t, sideBarInfo, sideBarMenus);
  const bcDynamicPaths = useSelector((state) => state.breadcrumb?.dynamicPaths);
  let isActiveLabel;
  let isActiveSubMenuLabel;
  let activeLabels;
  let activeItems = [];

  function ClearDynamicPath() {
    bcDynamicPaths.splice(0, bcDynamicPaths.length);
  }

  function GetDynamicPath() {
    ClearDynamicPath();
    var currentRoutes = PrivateRoutes;
    const currentPathname = window.location.pathname
      .split("/")
      .filter((x) => !!x);
    var parentUrl = "";

    currentPathname.forEach((x, index) => {
      const currentRoute = currentRoutes?.find((y) => y.path === x);
      if (currentRoute && currentRoute.name) {
        parentUrl = `${parentUrl}/${currentRoute.path}`;
        if (currentRoute.displayOnBreadCrumb) {
          bcDynamicPaths.push({
            label: currentRoute.name,
            url: parentUrl,
          });

          if (
            currentRoute.path === PATH.CLIENT_MANAGEMENT &&
            client.tenantName &&
            client.tenantFullName
          ) {
            bcDynamicPaths.push({
              label: client.tenantName,
              url: `${parentUrl}/${PATH.CLIENT_DETAILS}`,
            });
          }
        }
        currentRoutes = currentRoute?.subMenus;
      }
    });
  }

  //reset breadcrumb when pathname change accordingly
  useEffect(() => {
    dispatch(resetBcDynamicPaths());
    if (PAGE_EXCLUDE_SIDEBAR.indexOf(window.location.pathname) > -1) {
      dispatch(clearSideBar());
    }

    if (bcDynamicPaths.length === 0) {
      GetDynamicPath();
    } else {
      ClearDynamicPath();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, window.location.pathname]);

  if (bcDynamicPaths.length === 0) {
    GetDynamicPath();
  }

  // Get current active Menu label
  for (var menuTree in genMenuTree) {
    if (genMenuTree[menuTree].isActive) {
      isActiveLabel = genMenuTree[menuTree].label;
    }
  }

  // Get current active SubMenu label
  if (
    genMenuTree.length > 0 &&
    genMenuTree.find((x) => x.isActive && x.children?.length > 1)
  ) {
    let getSubMenus = genMenuTree.find(
      (x) => x.isActive && x.children?.length > 1
    );
    for (var subMenuTree in getSubMenus.children) {
      if (getSubMenus.children[subMenuTree].isActive) {
        isActiveSubMenuLabel = getSubMenus.children[subMenuTree].label;
      }
    }
  }

  // Store an array of active labels into this variable
  // If the parent label is the same as children label then
  // If don't want to display then add the below statement into the if statement
  // -> (isActiveLabel !== isActiveSubMenuLabel &&)
  activeLabels = [isActiveLabel];
  if (genMenuTree.find((x) => x.isActive && x.children?.length > 1)) {
    if (isActiveSubMenuLabel) {
      activeLabels.push(isActiveSubMenuLabel);
    }
  }

  if (client.tenantName && client.tenantFullName) {
    activeItems = activeItems.concat({
      label: client.tenantFullName,
      url: `${PATH.HOME}`,
    });
  }

  /*
  if (
    client?.datas &&
    client?.datas?.length > 0 &&
    client.selectedEntity?.label
  ) {
    activeItems = activeItems.concat({
      label: client.selectedEntity?.label,
      //disabled: client.datas.length < 1, //TODO: R1
      command: (event) => {
        //if (props.overlayPanelControl) {
        //    props.overlayPanelControl.current.toggle(event)
        //}
      },
    });
  }
  */

  //activeItems is items from the sideBar
  //bcDynamicPaths is custom breadcrumb for each page
  if (!isSideBarMenuAppendedToDynamicPath) {
    activeItems = bcDynamicPaths;
  } else {
    // Map the array and model the object into the model format
    if (isActiveLabel) {
      activeItems = activeItems.concat(
        activeLabels.map((x) => {
          return {
            label: x,
          };
        })
      );
      activeItems = activeItems.concat(bcDynamicPaths);
    } else {
      activeItems = bcDynamicPaths;
    }
  }

  return (
    <div
      className="main-breadcrumb p-d-none p-d-lg-block p-d-md-block"
      id="main-breadcrumb"
    >
      <BreadCrumb model={activeItems} />
    </div>
  );
};
export default MainBreadcrumb;
