import { useSelector } from "react-redux";
import { PATH } from "../../../../../../constants";
import "./main-my-portal.scss";

const MainMyPortal = (props) => {
  const token = useSelector((state) => state.auth?.token);
  const tenantName = useSelector((state) => state.client.tenantName) || props.tenantName;

  return (
    <span className="main-my-portal">
      <a
        href={`${window.REACT_CLIENT_APP_URL}/${PATH.LOGIN}/${tenantName}${PATH.QUERY_SOURCE_SUPPORT}&t=${token}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Sunshine Portal
      </a>
    </span>
  );
};

export default MainMyPortal;
