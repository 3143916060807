import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { STORAGE_KEY } from "../../../../constants";
import { resetAuthInfo } from "../../../../redux/actions/auth";
import { clearRedux } from "../../../../redux/actions/clear-redux";
import { setCountdownTimer } from "../../../../redux/actions/countdown-timer";
import { closeModal } from "../../../../redux/actions/modal";
import { clearSideBar } from "../../../../redux/actions/sidebar";
import { StorageSerive } from "../../../../utils";

const LogoutService = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = async (history, dispatch) => {
    await StorageSerive.removeToken();
    let url = "/";
    localStorage.removeItem(STORAGE_KEY.ROOT);
    sessionStorage.clear();
    dispatch(clearSideBar());
    dispatch(resetAuthInfo());
    dispatch(setCountdownTimer({ isCountingDown: false }));
    dispatch(closeModal());
    localStorage.clear();
    //TODO: double reload login page to force refresh
    history.push(url);
    window.location.href = url;
  };

  useEffect(() => {
    // Clear Redux
    dispatch(clearRedux());
    // Perform logout operation
    logout(history, dispatch);
  }, []);

  return <></>;
};

export default LogoutService;
