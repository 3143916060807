import {
  SET_USER_LANGUAGE,
  LANGUAGE_SET_LOADED_CONTEXT,
  LANGUAGE_CLEAR_LOADED_CONTEXT,
  SET_USER_SELECTED_LANGUAGE,
} from "./../redux-constants";

// ----------------------------------------------------------------------------
// loadedContext - usage/example
// loadedContext['en-HK']['eeportal.label.xx'] = true;
//
// ----------------------------------------------------------------------------

/**
 * @param language
 * @param loadedContext
 */
const initialState = {
  language: {
    /**
     * @field selectedLang
     * @field languageList
     */
    selectedLang: {
      /**
       * @field label
       * @field key
       * @field data
       */
      label: "English",
      key: "en",
      data: {},
    },
    languageList: [],
  },
  loadedContext: {
    context: {},
    data: {},
  },
};

export default function language(state = initialState, action) {
  switch (action.type) {
    case SET_USER_LANGUAGE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case LANGUAGE_SET_LOADED_CONTEXT: {
      return {
        ...state,
        loadedContext: action.payload,
      };
    }
    case LANGUAGE_CLEAR_LOADED_CONTEXT: {
      return {
        ...state,
        loadedContext: { context: {}, data: {} },
      };
    }
    case SET_USER_SELECTED_LANGUAGE: {
      return {
        ...state,
        language: {
          ...state.language,
          selectedLang: state.language.languageList.find(
            (language) => language.key === action.payload
          ) ?? { key: action.payload },
        },
      };
    }
    default: {
      return state;
    }
  }
}
