import { useRef } from "react";
import { TEMPLATE_INDEX } from "../../../../constants/config";
import { UtilityService } from "../../../../services/hrmnet-api";
import UploadDocumentContainer from "../../../upload-document-container/upload-document-container";
import "./translation-configuration.scss";

const TranslationConfiguration = () => {
  const documentUploader = useRef();

  const container = {
    title: "Translation",
    description: (
      <>
        Upload{" "}
        <a
          href={TEMPLATE_INDEX.LanguageTranslations}
          target="_blank"
          rel="noreferrer"
        >
          translation files
        </a>{" "}
        for different languages.
      </>
    ),
  };

  const fileUpload = {};

  const modal = {
    title: "Upload Translation",
    content: "Are you sure to upload translation?",
    primaryButtonText: "Sure",
  };

  const submitAction = async (params, options) => {
    return await UtilityService.utilityUploadTranslation(params, options);
  };

  return (
    <UploadDocumentContainer
      config={{ container: container, fileUpload: fileUpload, modal: modal }}
      submitAction={submitAction}
      ref={documentUploader}
    />
  );
};

export default TranslationConfiguration;
