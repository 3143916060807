import "./features.scss";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useReducer } from "react";
import ResponseMessage from "./response-message";
import { Animated } from "react-animated-css";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";

export const StatusEnum = {
  waiting: "Waiting",
  inProgress: "In Progress",
  completed: "Completed",
  fail: "Fail",
  abort: "Abort",
  sleep: "Sleep",
};

export const ProcessingStatus = {
  inProgress: "In Progress",
  fail: "Process completed - failed",
  completed: "Process completed successfully",
  failPartially: "Process completed partially",
};

function activeStepsReducer(state, action) {
  switch (action?.type) {
    case "update-status": {
      const content = [
        ...state.map((x) => {
          if (x.id === action.payload.data.id) {
            return {
              ...x,
              status: action.payload.status,
            };
          }

          //abort the rest if status of current feature is fail
          if (
            action.payload.status === StatusEnum.fail &&
            x.step === action.payload.data.step &&
            x.order > action.payload.data.order
          ) {
            return {
              ...x,
              status:
                x.status === StatusEnum.waiting
                  ? StatusEnum.abort
                  : StatusEnum.sleep,
            };
          }

          //start next step/order when completed
          const _state = [...state].sort((a, b) => {
            if (a.order === b.order) {
              return a.name - b.name;
            }
            return a.order > b.order ? 1 : -1;
          });

          if (action.payload.status === StatusEnum.completed) {
            const index = _state.findIndex(
              (s) => s.id === action.payload.data.id
            );
            const nextState = _state[index + 1];
            if (
              x.status === StatusEnum.waiting &&
              action.payload.data.order === x.order &&
              action.payload.data.mode === "sequential" &&
              !!nextState &&
              nextState?.id === x.id
            ) {
              return { ...x, status: StatusEnum.inProgress };
            }

            if (!!nextState) {
              const nextOrder = _state
                .filter((y) => nextState.order === y.order)
                .sort((s) => s.name);
              if (x.status === StatusEnum.sleep && Array.isArray(nextOrder)) {
                if (
                  nextOrder.some(
                    (next, index) => next.id === x.id && index === 0
                  ) &&
                  nextOrder[0].mode === "sequential"
                ) {
                  return { ...x, status: StatusEnum.inProgress };
                }
                if (
                  nextOrder.some(
                    (next, index) => next.id === x.id && index > 0
                  ) &&
                  nextOrder[0].mode === "sequential"
                ) {
                  return { ...x, status: StatusEnum.waiting };
                }
                if (
                  nextOrder.some((next, index) => next.id === x.id) &&
                  nextOrder[0].mode === "concurrent"
                ) {
                  return { ...x, status: StatusEnum.inProgress };
                }
              }
            }
          }

          return x;
        }),
      ];

      return content;
    }
    case "update-statuses-abort": {
      const content = [
        ...state.map((x) => {
          if (x.id !== action.payload) {
            return {
              ...x,
              status: StatusEnum.abort,
            };
          }

          return x;
        }),
      ];

      return content;
    }
    case "run-fail": {
      const content = [
        ...state.map((x) => {
          if (x.status === StatusEnum.fail) {
            return {
              ...x,
              status: StatusEnum.inProgress,
            };
          }

          return x;
        }),
      ];

      return content;
    }
    case "update-messages": {
      const content = [
        ...state.map((x) => {
          if (x.id === action.payload.data.id) {
            return {
              ...x,
              messages: action.payload.messages,
            };
          }

          return x;
        }),
      ];

      return content;
    }
    case "remove-messages": {
      const content = [
        ...state.map((x) => {
          if (x.id === action.payload.data.id) {
            delete x["messages"];
          }

          return x;
        }),
      ];

      return content;
    }
    case "init": {
      let firstOrder =
        Array.isArray(action?.payload) &&
        Array.from(action?.payload, (x) => x.order).sort();
      let content = !!action?.payload
        ? [
            ...action?.payload.map((x) => {
              x.status = StatusEnum.sleep;
              x.mode =
                action?.payload.filter((y) => y.order === x.order)?.length > 1
                  ? "concurrent"
                  : "sequential";
              let _group = action?.payload
                .filter((y) => y.order === firstOrder[0])
                ?.sort((s) => s.name);
              if (_group[0] && _group[0]?.id === x.id) {
                x.status = StatusEnum.inProgress;
              } else if (_group.some((e) => e.id === x.id)) {
                x.status = StatusEnum.inProgress;
              }
              return {
                ...x,
              };
            }),
          ]
        : [];

      if (
        content.length > 0 &&
        content.filter((x) => x.status === StatusEnum.inProgress).length === 0
      ) {
        content.reduce(function (prev, curr) {
          return prev.id < curr.id ? prev : curr;
        }).status = StatusEnum.inProgress;
      }

      return content.sort((a, b) => {
        if (a.order === b.order) {
          return a.name - b.name;
        }
        return a.order > b.order ? 1 : -1;
      });
    }
    case "reset": {
      return [];
    }
    default:
      throw new Error();
  }
}

const FeatureSchedulerPanel = ({
  content,
  result,
  updateProcessing,
  requestId,
}) => {
  const [activeSteps, setActiveSteps] = useReducer(activeStepsReducer, []);

  useEffect(() => {
    setActiveSteps({ type: "init", payload: content });

    return () => {
      setActiveSteps({ type: "reset" });
    };
  }, [requestId]);

  useEffect(() => {
    if (activeSteps.length > 0) {
      ProcessingStatusHandler();
    }
  }, [activeSteps]);

  function ProcessingStatusHandler() {
    var _result = null;

    if (
      activeSteps.filter((x) => x.status === StatusEnum.completed).length > 0 &&
      activeSteps.filter((x) => x.status === StatusEnum.inProgress).length === 0
    ) {
      _result = ProcessingStatus.completed;
    }

    if (
      activeSteps.filter((x) => x.status === StatusEnum.fail).length > 0 &&
      activeSteps.filter((x) => x.status === StatusEnum.completed).length > 0 &&
      activeSteps.filter((x) => x.status === StatusEnum.inProgress).length === 0
    ) {
      _result = ProcessingStatus.failPartially;
    }

    if (
      activeSteps.filter((x) => x.status === StatusEnum.fail).length > 0 &&
      activeSteps.filter((x) => x.status === StatusEnum.completed).length ===
        0 &&
      activeSteps.filter((x) => x.status === StatusEnum.inProgress).length === 0
    ) {
      _result = ProcessingStatus.fail;
    }

    if (
      activeSteps.filter((x) => x.status === StatusEnum.inProgress).length > 0
    ) {
      _result = ProcessingStatus.inProgress;
    }

    if (!!_result && result !== _result) {
      updateProcessing(_result);
    }
  }

  const renderMessageDetails = (message) => {
    return (
      <table>
        <tbody>
          {message.map((m, index) => {
            return (
              <tr
                key={`features-message-details-${index}`}
                className="features-message-details"
              >
                <td
                  key={`features-message-details-type-${index}`}
                  style={{ width: "5rem" }}
                >
                  {m.type === "Success" ? (
                    <i className="pi pi-check pi-check"></i>
                  ) : (
                    <i className="pi pi-times pi-times"></i>
                  )}
                </td>
                <td
                  key={`features-message-details-description-${index}`}
                  style={{ minWidth: "14rem" }}
                >
                  ${m.text}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderMessagePanelColumn = () => {
    const config = {
      field: "name",
      header: "Features",
      style: { minWidth: "14rem" },
      body: (data) => {
        return (
          <div className="features-message-panel">
            <table>
              <tbody>
                <tr>
                  <td style={{ width: "3rem" }}>
                    <div className="features-message-panel-status">
                      <Tooltip target=".features-message-panel-status" />
                      {data.status === StatusEnum.waiting && (
                        <Tag rounded severity="info" icon="pi pi-clock"></Tag>
                      )}
                      {data.status === StatusEnum.inProgress && (
                        <Tag
                          rounded
                          severity="info"
                          icon={`feature-status${data.id} pi pi-spin pi-spinner`}
                        ></Tag>
                      )}
                      {data.status === StatusEnum.completed && (
                        <Tag
                          rounded
                          severity="success"
                          icon={`feature-status${data.id} pi pi-check`}
                        ></Tag>
                      )}
                      {data.status === StatusEnum.abort && (
                        <Tag
                          className="abort"
                          rounded
                          severity="warn"
                          icon={`feature-status${data.id} pi pi-exclamation-triangle`}
                        ></Tag>
                      )}
                      {data.status === StatusEnum.fail && (
                        <Tag
                          rounded
                          severity="error"
                          icon={`feature-status${data.id} pi pi-times`}
                        ></Tag>
                      )}
                    </div>
                  </td>
                  <td style={{ minWidth: "14rem" }}>
                    <div className="features-message-panel-details">
                      <small>{data.name}</small>
                      {data.status === StatusEnum.inProgress && (
                        <ResponseMessage
                          requestId={requestId}
                          data={data}
                          updateStatus={(status) => {
                            setActiveSteps({
                              type: "update-status",
                              payload: {
                                status: status,
                                data: data,
                              },
                            });
                          }}
                          updateMessages={(messages) => {
                            setActiveSteps({
                              type: "update-messages",
                              payload: {
                                messages: messages,
                                data: data,
                              },
                            });
                          }}
                          removeMessage={() => {
                            setActiveSteps({
                              type: "remove-messages",
                              payload: {
                                data: data,
                              },
                            });
                          }}
                        />
                      )}
                      {data?.messages && data?.messages.length > 0 && (
                        <small className="feature-scheduler-log-header">
                          Log Details
                        </small>
                      )}
                      {data?.messages && renderMessageDetails(data.messages)}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      },
    };
    return <Column {...config} />;
  };

  const renderHeader = () => {
    let element = document.getElementById(requestId);
    element?.scrollIntoView({ behavior: "smooth", block: "center" });

    return (
      <div className="bt-header">
        <div className="wrapper">
          <div>
            <h2>Selected Features for Subscription </h2>
          </div>
          <div className="features-menu">
            {activeSteps?.filter((x) => x.status === StatusEnum.fail).length >
              0 && (
              <Animated
                animationIn="slideInUp"
                animationOut="slideOutDown"
                animationInDuration={200}
                animationOutDuration={200}
              >
                <div className="bt-toolbar bt-toolbar-batch flex sm:justify-content-end">
                  <Button
                    type="button"
                    onClick={() => {
                      setActiveSteps({ type: "run-fail" });
                    }}
                    label="Run failed feature(s)"
                  />
                </div>
              </Animated>
            )}
          </div>
        </div>
      </div>
    );
  };

  const header = renderHeader();
  const footer = `${result}`;
  return (
    <DataTable
      id={requestId}
      value={activeSteps}
      header={header}
      dataKey="id"
      sortField="step"
      sortOrder={1}
      rowHover
      footer={footer}
      className="features-panel"
    >
      {renderMessagePanelColumn()}
    </DataTable>
  );
};

export default FeatureSchedulerPanel;
