import {
  SET_LIST_TENANT,
  CLEAR_USER_INFO,
  SET_SELECTED_TENANT,
  SET_CLIENT_INFO,
} from "../redux-constants";
const initialState = {
  datas: [],
  tenantName: "",
  selectedEntity: null,
};

export default function client(state = initialState, action) {
  switch (action.type) {
    case SET_LIST_TENANT: {
      return {
        ...state,
        datas: action.payload.datas,
        selectedEntity: action.payload?.selectedEntity ?? {},
      };
    }
    case SET_CLIENT_INFO: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_SELECTED_TENANT: {
      return {
        ...state,
        tenantName: action.payload.tenantName,
        tenantFullName: action.payload.tenantFullName,
      };
    }
    case CLEAR_USER_INFO: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
