import axios from "axios";
import qs from "qs";
import {
  REQUEST_EXCLUDE_TOKEN,
  URL_SESSION_EXCLUDED,
} from "../constants/config";
import i18n from "../i18n";
import { Logout } from "../portal/login-modules/components/sso-login";
import { MessageSwitch, StorageSerive } from "../utils";
import { RESPONSE_MESSAGE_ERROR, STORAGE_KEY } from "./../constants/index";
import { AuthenticationService } from "./hrmnet-api";

const refreshAccessToken = async (
  axiosApiInstance,
  originalRequest,
  history,
  store
) => {
  try {
    // wait for other thread that is doing refresh to complete
    let counter = 0;
    while (axiosApiInstance.inRefresh && counter < 10) {
      counter++;
      await sleep(200);
    }

    // flag to prevent other thread to refresh token too frequently
    axiosApiInstance.inRefresh = true;
    let keys = store.getState().auth;

    if (!keys || !keys.refreshToken) {
      gotoLogin(history);
      return Promise.resolve({
        body: null,
      });
    }

    let recentlyRefreshed = false;
    if (axiosApiInstance.lastRefresh) {
      let ts = new Date();
      if (ts.getTime() - axiosApiInstance.lastRefresh.getTime() < 2000) {
        recentlyRefreshed = true;
      }
    }
    if (!recentlyRefreshed) {
      var res = await AuthenticationService.authenticationRefreshToken({
        body: {
          token: keys?.token,
          refreshToken: keys?.refreshToken,
        },
      });

      if (res && res.data) {
        // 1) put token to LocalStorage
        let newToken = keys;
        newToken.token = res.data.token;
        newToken.refreshToken = res.data.refreshToken;
        await StorageSerive.setToken(newToken);
        axiosApiInstance.lastRefresh = new Date();
        // 2) Change Authorization header - not required - request interceptor will check the token from localStorage

        // 3) return originalRequest object with Axios.
        return axiosApiInstance(originalRequest); //
      }
    } else {
      return axiosApiInstance(originalRequest);
    }
    gotoLogin(history);
    // Dummy ignore error
    return Promise.resolve({
      data: null,
    });
  } catch (err) {
  } finally {
    axiosApiInstance.inRefresh = false;
  }
};

//
const gotoLogin = async (history) => {
  if (URL_SESSION_EXCLUDED.indexOf(window.location.pathname) < 0) {
    window.sessionStorage.setItem(
      STORAGE_KEY.PATH_AFTER_LOGIN,
      window.location.pathname
    );
  }

  // Use window.location.href to perform PATH.LOGOUT operation correctly and refresh page
  // When token expired
  // TODO: pass history
  Logout();
};
const gotoPage = async (history, page) => {
  history.push({ pathname: `/${page}` });
};

function handlerAPIError(error, store, history) {
  let toast = store.getState().global.toast;
  let alert = store.getState().global.alert;

  if (error.message === RESPONSE_MESSAGE_ERROR.CANCEL) {
    return Promise.reject(error);
  }

  if (!error || !error.response || !error.response.status) {
    if (toast) {
      toast.show({
        severity: "error",
        summary: i18n.t("misc_axios_notification"),
        detail: i18n.t("misc_axios_network_error"),
        life: 3000,
      });
    }
    return error;
  }
  switch (error.response.status) {
    case 500:
      MessageSwitch(error.response, toast, alert, i18n);
      break;
    case 404: {
      MessageSwitch(error.response, toast, alert, i18n);
      return Promise.reject(error); // trigger exception
    }
    case 403:
      if (toast) {
        toast.show({
          severity: "error",
          summary: i18n.t("misc_axios_forbidden"),
          detail: i18n.t("misc_axios_permission_denied"),
          life: 5000,
        });
      }
      return Promise.reject(error); // trigger exception
    // break;
    case 401:
      gotoLogin(history);
      break;
    case 400: {
      MessageSwitch(error.response, toast, alert, i18n);
      return Promise.reject(error); // trigger exception
    }
    default:
      break;
  }

  return error.response;
}

function handlerMessage(response, store, history) {
  // If suppressErrorMsg is true then return
  if (response?.config?.suppressErrorMsg) {
    return;
  }
  let res = response?.data;
  let toast = store.getState().global.toast;
  let alert = store.getState().global.alert;
  if (alert) {
    alert.clear();
  }
  if (res && res.message && res.message.toast && res.message.toast.length > 0) {
    res.message.toast.forEach((element) => {
      const param = {};
      if (element.placeholder) {
        element.placeholder.forEach((element, placeIndex) => {
          param[placeIndex + ""] = element;
        });
      }
      if (toast) {
        toast.show({
          severity: element.level.toLowerCase(),
          summary: i18n.t("misc_axios_notification"),
          detail: i18n.t(element.labelCode, param),
          life: 3000,
        });
      } else {
      }
    });
  }

  if (res && res.message && res.message.alert && res.message.alert.length > 0) {
    res.message.alert.forEach((element) => {
      const param = {};
      if (element.placeholder) {
        element.placeholder.forEach((e, placeIndex) => {
          param["placeIndex" + placeIndex] = e;
        });
      }
      if (alert) {
        alert.clear();
        alert.show({
          severity: element.level.toLowerCase(),
          detail: (
            <div
              className="hris-message-detail"
              dangerouslySetInnerHTML={{
                __html: i18n.t(element.labelCode, param),
              }}
            />
          ),
          sticky: true,
        });
      } else {
      }
    });
  }
  if (res && res.messages && res.messages.length > 0) {
    MessageSwitch(res, toast, alert, i18n);
  }

  if (res && res.redirectUrl) {
    gotoPage(history, res.redirectUrl);
  }
}
export function createAxiosApiInstance(baseURL, store, history) {
  const instance = axios.create({
    baseURL: baseURL,
    timeout: 1800000,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
  // Request interceptor for API calls
  instance.interceptors.request.use(
    async (config) => {
      // get key
      const token = store.getState().auth.token;
      if (REQUEST_EXCLUDE_TOKEN.indexOf(config.url) < 0 && token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      config.headers["Accept-Language"] = "en-HK";

      const tenantName = store.getState().client.tenantName;
      const entityName = store.getState().client.selectedEntity?.key;

      if (!config.headers[STORAGE_KEY.HRMNET_TENANT_NAME]) {
        config.headers[STORAGE_KEY.HRMNET_TENANT_NAME] = tenantName;
      }

      config.headers[STORAGE_KEY.HRMNET_ENTITY_NAME] = entityName;

      if (
        config.headers[STORAGE_KEY.HRMNET_ENTITY_NAME] === undefined ||
        config.headers[STORAGE_KEY.HRMNET_ENTITY_NAME].length === 0
      ) {
        delete config.headers[STORAGE_KEY.HRMNET_ENTITY_NAME];
      }

      if (
        config.headers[STORAGE_KEY.HRMNET_TENANT_NAME] === undefined ||
        config.headers[STORAGE_KEY.HRMNET_TENANT_NAME].length === 0
      ) {
        delete config.headers[STORAGE_KEY.HRMNET_TENANT_NAME];
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  // Response interceptor for API calls
  instance.interceptors.response.use(
    (response) => {
      // tweak to get the response object
      if (response.config.callback) {
        response.config.callback(response);
      }

      handlerMessage(response, store, history);
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      handlerMessage(error.response, store, history);
      if (
        error &&
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        return await refreshAccessToken(
          instance,
          originalRequest,
          history,
          store
        );
      }
      return handlerAPIError(error, store, history);
    }
  );
  return instance;
}

// sleep function
function sleep(milliseconds) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
}
//#endregion
