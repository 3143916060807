import { DocumentsService } from "../../../services/hrmnet-api";
import { saveAs } from "file-saver";

export async function downloadFile(file) {
  if (file.id) {
    var res = await DocumentsService.documentDownloadSingleFile(
      {
        employeeCode: "",
        fileId: file.id,
      },
      {
        responseType: "blob",
      }
    );
    if (res) {
      var filename = file.name ? file.name : "Document";
      saveAs(res, filename);
    }
  } else {
    // Is already blob
    saveAs(file, file.name);
  }
}

export function getFileSizeString(input, toFixed = 0) {
  let size = parseFloat(input);
  if (!size && size !== 0) return "";
  const units = ["KB", "MB", "GB"];
  let curUnit = "B";
  units.forEach((unit) => {
    if (size >= 1024) {
      size = size / 1024;
      curUnit = unit;
    }
  });
  return `${size.toFixed(toFixed)} ${curUnit}`;
}
