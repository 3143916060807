import { Configuration /* , LogLevel */ } from "@azure/msal-browser";
import { PATH } from "../constants";

const AzureActiveDirectoryAppClientId: any = (window as any)
  .REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID;

const AzureActiveDirectoryAppRedirectUrl: any = (window as any)
  .REACT_APP_AZURE_REDIRECT_URL;

export const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: AzureActiveDirectoryAppClientId,
    redirectUri: AzureActiveDirectoryAppRedirectUrl, // TOOD: fix - Hash value cannot be processed because it is empty. Please verify that your redirectUri is not clearing the hash.
    postLogoutRedirectUri: window.location.origin + "/" + PATH.LOGOUT,
  },
  cache: {
    cacheLocation: "localStorage",
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // switch (level) {
        //   case LogLevel.Error:
        //     console.error(message);
        //     return;
        //   case LogLevel.Info:
        //     console.info(message);
        //     return;
        //   case LogLevel.Verbose:
        //     console.debug(message);
        //     return;
        //   case LogLevel.Warning:
        //     console.warn(message);
        //     return;
        // }
      },
    },
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
  prompt: "select_account",
};
