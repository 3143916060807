import { PATH } from "./../constants";

/**
 * Get Login Path in respective of tenant code and entity code
 *
 * @param {*} clientInfo
 * @returns {string}
 */
export const LoginLink = (clientInfo: any) => {
  if (clientInfo && IsNotNullOrDefined(clientInfo.tenantCode)) {
    if (IsNotNullOrDefined(clientInfo.entityCode)) {
      return `/${PATH.LOGIN}/${clientInfo?.tenantCode}/${clientInfo?.entityCode}`;
    }
    return `/${PATH.LOGIN}/${clientInfo?.tenantCode}`;
  }

  return `/`;
};

/**
 * Object null checking
 *
 * @param {*} data
 * @returns {boolean}
 */
export const IsNotNullOrDefined = (data: any) => {
  if (data !== null && data !== undefined) {
    return true;
  }
  return false;
};

/**
 * Generate unique id
 *
 * @param {*}
 * @returns {string}
 */
export const makeId = () => {
  return "hris_xxxxxxxxxx".replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-mixed-operators
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/**
 * Compare JSON Object
 *
 * @param {*} a
 * @param {*} b
 * @returns {boolean}
 */
export const jsonEqual = (a: any, b: any) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

/**
 * Object empty check
 *
 * @param {*} a
 * @param {*} b
 * @returns {boolean}
 */
export const isObjectEmpty = (obj: any) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};
