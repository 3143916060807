import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./shortcut.scss";

const ShortcutIcons = (props) => {
  const menu = useSelector((state) => props.menu ?? state.menu.sideBar);
  const tenantName = useSelector((state) => state.client?.tenantName);
  const renderShortcutConfig = menu.map((item, index) => {
    if (item && !window.location.pathname.includes(item.url)) {
      return (
        <div key={index} className="p-col-12 p-lg-3 shortcut">
          <Link
            to={{
              pathname: `${props.path ?? "/" + tenantName}/${
                item.url ?? item.path
              }`,
              breadCrumbName: item?.name,
            }}
          >
            <div className="p-col-12">
              <p className="p-col-9 p-jc-start p-jc-lg-center p-lg-12">
                {item?.name}
              </p>
            </div>
          </Link>
        </div>
      );
    }
    return <div key={index}></div>;
  });

  return (
    <div className="p-grid shortcut-container" style={props.menuStyle}>
      {" "}
      {renderShortcutConfig}
    </div>
  );
};

export default ShortcutIcons;
