import { BaseContainer } from "../../../components";

const ClientDetails = () => {
  const pageConfig = {
    title: "Client Details",
  };

  return <BaseContainer {...pageConfig}></BaseContainer>;
};

export default ClientDetails;
