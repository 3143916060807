import { BaseContainer } from "../../../components";
import "./features.scss";
import { useEffect, useReducer } from "react";
import { Button } from "primereact/button";
import { Animated } from "react-animated-css";
import { useDispatch } from "react-redux";
import { UtilityService } from "../../../services/hrmnet-api";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import {
  closeLoading,
  openLoading,
  openModal,
} from "../../../redux/actions/modal";
import FeatureSchedulerPanel, {
  ProcessingStatus,
  StatusEnum,
} from "./feature-scheduler-panel";
import { v4 as uuidv4 } from "uuid";

function stateReducer(state, action) {
  switch (action?.type) {
    case "update-messages": {
      const _state = {
        ...state,
        content: state.content.map((x) => {
          if (action.payload.data.id === x.id) {
            return {
              ...x,
              messages: action.payload.messages,
            };
          }
          return x;
        }),
      };

      return _state;
    }
    case "remove-messages": {
      const _state = {
        ...state,
        content: state.content.map((x) => {
          if (action.payload.data.id === x.id) {
            delete x["messages"];
          }
          return x;
        }),
      };

      return _state;
    }
    case "update-status": {
      const _state = {
        ...state,
        content: state.content.map((x) => {
          if (action.payload.data.id === x.id) {
            return {
              ...x,
              status: action.payload.status,
            };
          }
          return x;
        }),
      };

      return _state;
    }
    case "add-status": {
      const _state = {
        ...state,
        content: state.content.map((x) => {
          if (action.payload.includes(x.id)) {
            return {
              ...x,
              status: StatusEnum.processing,
            };
          }
          return x;
        }),
      };

      return _state;
    }
    case "set": {
      return action.payload;
    }
    case "reset": {
      return { loading: true, content: [], id: null };
    }
    default:
      throw new Error();
  }
}

function selectionReducer(state, action) {
  switch (action?.type) {
    case "update": {
      let data = action.payload.data;

      const content = action.payload.content;
      const requiredModules = content.filter((x) => x.isRequired === true);

      if (data.length > 0 && requiredModules.length > 0) {
        data = data.filter((x) => x.isRequired !== true);
        data = [...data, ...requiredModules];
      }

      return data;
    }
    case "reset": {
      return [];
    }
    default:
      throw new Error();
  }
}

function featuresProcessingReducer(state, action) {
  switch (action?.type) {
    case "update": {
      const _state = {
        status: action.payload.status,
        content: action.payload.content,
        id: action.payload.id,
      };

      return _state;
    }
    case "update-status": {
      const _state = { ...state };
      _state.status = action.payload;
      return _state;
    }
    case "reset": {
      return null;
    }
    default:
      throw new Error();
  }
}

const Features = () => {
  const dispatch = useDispatch();
  const stateInit = { loading: true, content: [] };
  const [state, setState] = useReducer(stateReducer, stateInit);
  const [selectedFiles, setSelectedFiles] = useReducer(selectionReducer, []);
  const [featuresProcessing, setFeaturesProcessing] = useReducer(
    featuresProcessingReducer,
    null
  );

  //get data from API bind to checkbox
  useEffect(() => {
    const getData = async () => {
      const _state = { loading: false, content: [] };
      try {
        var res = await UtilityService.utilityGetFeatureList();
        // var res = { "data": [{ "id": 0, "name": "Default Database Configuration" }, { "id": 1, "name": "Employee Profile" }, { "id": 2, "name": "Rental - RRS" }, { "id": 3, "name": "Leave" }, { "id": 4, "name": "My Documents" }, { "id": 5, "name": "Company Policy" }, { "id": 6, "name": "Payroll - HRM Net" }], "messages": [] };
        if (res && res?.data) {
          _state.content = res?.data;
        }
      } catch (error) {}
      setState({ type: "set", payload: _state });
    };
    getData();

    return () => {
      setState({ type: "reset" });
      setFeaturesProcessing({ type: "reset" });
    };
  }, []);

  //send data to API
  async function SelectFeatures() {
    //confirmation message
    dispatch(
      openModal({
        title: "Confirm Submission",
        content: "Are you sure you want to subscribe/enable selected features?",
        classNameMainDialog: "confirm-message-modal",
        primaryButtonText: "Confirm",
        primaryButtonClickFn: async ({ closeFn }) => {
          closeFn();
          dispatch(openLoading());
          const requestId = uuidv4();
          if (selectedFiles.length > 0) {
            setFeaturesProcessing({
              type: "update",
              payload: {
                content: selectedFiles,
                status: ProcessingStatus.inProgress,
                id: requestId,
              },
            });
          }
          dispatch(closeLoading());
        },
        secondButtonClickFn: ({ closeFn }) => {
          dispatch(openLoading());
          closeFn();
          dispatch(closeLoading());
        },
      })
    );
  }

  const renderHeader = () => {
    return (
      <div className="bt-header">
        <div className="wrapper">
          <div>
            <h1>Client Feature Subscriptions </h1>
          </div>
          <div className="features-menu">
            <Animated
              animationIn="slideInUp"
              animationOut="slideOutDown"
              animationInDuration={200}
              animationOutDuration={200}
              isVisible={selectedFiles.length > 0}
            >
              {selectedFiles.length > 0 && (
                <div className="bt-toolbar bt-toolbar-batch flex sm:justify-content-end">
                  <Button
                    type="button"
                    onClick={() => SelectFeatures()}
                    label="Subscribe Features"
                    loading={
                      featuresProcessing?.status &&
                      featuresProcessing.status === ProcessingStatus.inProgress
                    }
                  />
                </div>
              )}
            </Animated>
          </div>
        </div>
      </div>
    );
  };

  const renderNameColumn = (e) => {
    return (
      <Column field="name" header="Features" style={{ minWidth: "14rem" }} />
    );
  };

  const renderSelection = (e) => {
    return <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />;
  };

  const header = renderHeader();
  return (
    <BaseContainer className="features">
      <DataTable
        loading={state?.loading}
        value={state?.content}
        header={header}
        dataKey="id"
        rowHover
        selection={selectedFiles}
        onSelectionChange={(e) =>
          setSelectedFiles({
            type: "update",
            payload: { data: e.value, content: state.content },
          })
        }
      >
        {renderSelection()}
        {renderNameColumn()}
      </DataTable>
      {!!featuresProcessing && (
        <>
          <hr />
          <FeatureSchedulerPanel
            requestId={featuresProcessing.id}
            content={featuresProcessing.content}
            result={featuresProcessing.status}
            updateProcessing={(status) =>
              setFeaturesProcessing({ type: "update-status", payload: status })
            }
          />
        </>
      )}
    </BaseContainer>
  );
};

export default Features;
