import { Button } from "primereact/button";
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Animated } from "react-animated-css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BaseContainer } from "../../components";
import { PATH, RESPONSE_MESSAGE_ERROR } from "../../constants";
import { ExportExcel } from "../utils";
import "./../../components/base-control/upload-control/file-upload.scss";
import "./../../components/base-control/upload-control/upload-control.scss";
import "./upload-document-container.scss";
import { openLoading, closeLoading } from "../../redux/actions/modal";
import UploadControl from "../../components/base-control/upload-control/upload-control";
import DocumentMessage from "./document-message";
import MultiSelectControl from "../../components/base-control/multiselect-control/multiselect-control";
import axios from "axios";

const UploadDocumentContainer = React.forwardRef((props, ref) => {
  // init state control
  let initState = {
    controlState: {
      invalid: false,
    },
    value: [],
  };
  const tenantName = useSelector((state) => state.client?.tenantName);
  const tenantList = useSelector((state) =>
    state.client?.datas?.map((x) => ({
      label: x.tenantName,
      value: x.tenantName,
      disabled: x.tenantName === tenantName,
    }))
  );
  const [state, setState] = useState(initState);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [selectedMultiTenants, setSelectedMultiTenants] = useState([
    tenantName,
  ]);
  const [message, setMessage] = useState([]);
  const { cancel, token } = axios.CancelToken.source();

  const history = useHistory();
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({}));

  useEffect(() => {
    dispatch(closeLoading());

    return () => {
      dispatch(closeLoading());
      cancel(RESPONSE_MESSAGE_ERROR.CANCEL);
    };
  }, []);

  //confirmation on uploading modal
  const onSubmitDocument = async () => {
    if (!props.submitAction) {
      return;
    }
    setMessage([]);

    dispatch(openLoading());
    setMessage([]);
    for (const file of state.value) {
      for (const tenant of selectedMultiTenants) {
        try {
          const res = await props.submitAction(
            {
              tenantName: tenant,
              file: file,
            },
            {
              suppressErrorMsg: true,
              cancelToken: token,
            }
          );

          if (res) {
            if (res.Message && res.TraceId) {
              setMessage((messages) => {
                return [
                  ...messages,
                  {
                    type: "Error",
                    text: `${tenant} | ${file.name} - ${res.TraceId}:${res.Message}`,
                  },
                ];
              });
            } else if (res.data.status) {
              setMessage((messages) => {
                return [
                  ...messages,
                  ...res?.messages?.map((x) => ({
                    type: x.type,
                    text: `${tenant} | ${file.name} - ${x.text}`,
                  })),
                  {
                    type: "Success",
                    text: `${tenant} | ${file.name} - Update success.`,
                  },
                ];
              });
            } else if (res.messages.length > 0) {
              setMessage((messages) => {
                return [
                  ...messages,
                  ...res?.messages?.map((x) => ({
                    type: x.type,
                    text: `${tenant} | ${file.name} - ${x.text}`,
                  })),
                ];
              });
            }
          }
        } catch (error) {
          setMessage((messages) => {
            return [
              ...message,
              {
                type: "Error",
                text: `${tenant} | ${file.name} - Exception encountered while running. Please try again.`,
              },
            ];
          });
        } finally {
        }
      }
    }
    dispatch(closeLoading());
  };

  //footer layout : submit && cancel
  const renderFooter = () => {
    return (
      <div className="footer p-grid p-align-center p-justify-between">
        <Button
          onClick={() => {
            history.push(`/${tenantName}/${PATH.HOME}`);
          }}
          className="p-button-outlined secondary back-button"
        >
          {"Back"}
        </Button>
        <Button
          onClick={onSubmitDocument}
          className="p-button upload-button"
          disabled={disableSubmit || state.value.length === 0}
        >
          {"Submit"}
        </Button>
      </div>
    );
  };

  const onSelect = (e, ref) => {
    let file = [];
    if (!Array.isArray(e?.files) && !!e.files) {
      file.push(e.files);
    } else {
      file = e?.files;
    }

    //checking file format
    if (Array.isArray(file)) {
      //enable submit
      setDisableSubmit(false);

      //update file
      let newState = state;
      newState.value = file;
      setState(newState);
    } else {
      //enable submit
      setDisableSubmit(true);
      setMessage([]);

      let newState = state;
      newState.value = [];
      setState(newState);
    }
    return;
  };

  const renderMessage = useMemo(
    () => <DocumentMessage message={message} />,
    [message]
  );

  return (
    <>
      <BaseContainer {...props.config.container}>
        <Animated
          animationIn="slideInRight"
          animationOut="slideOutRight"
          animationInDuration={200}
          animationOutDuration={200}
          isVisible={true}
        >
          <div className="file-uploader-container">
            <MultiSelectControl
              onChange={(x) => setSelectedMultiTenants(x.value)}
              value={selectedMultiTenants}
              enum={tenantList}
            />
            <UploadControl
              onDownload={(data) => ExportExcel(data)}
              multiple={true}
              value={state.value}
              onChange={(e) => onSelect({ files: e.value || [] }, e)}
              accept=".xlsx, application / msexcel *"
              {...props.config.fileUpload}
            />
          </div>
        </Animated>
        {renderMessage}
        {renderFooter()}
      </BaseContainer>
    </>
  );
});

export default UploadDocumentContainer;
