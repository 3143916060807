import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { jsonEqual } from "./../../../utils/index";
import {
  BCProps,
  BCStates,
  isError,
  renderError,
  validateControl,
} from "./../base-control";
import "./radio-control.scss";

export interface RadioControlProps extends BCProps {
  /**
   * search Function
   */
  searchFn?: () => any;
  /**
   * data options
   */
  enum?: RadioControlOption[];
  /**
   * vertical
   */
  vertical?: boolean[];
}

export interface RadioControlState extends BCStates {}

export interface RadioControlOption {
  /**
   * Item group
   */
  group?: string;
  /**
   * Item label
   */
  label: string;
  /**
   * Item value
   */
  value: string;
  /**
   * Item data
   */
  className?: string;
  /**
   * Item data
   */
  data?: string;

  /**
   * Addtional props
   */
  [key: string]: any;
}

const groupOption = (items: RadioControlOption[]) => {
  items = items.sort((a, b) => {
    return (a.group || "").trim().localeCompare((b.group || "").trim());
  });
  let listGroup: string[] = [];
  let newItems: RadioControlOption[] = [];
  items.forEach((item) => {
    if (!listGroup.includes(item.group || "")) {
      listGroup.push(item.group || "");
      newItems.push({
        label: item.group || "",
        value: item.group || "",
        disabled: true,
        className: "menu-group",
      });
    }
    newItems.push(item);
  });
  return newItems;
};
const RadioControl: React.FC<RadioControlProps> = (props) => {
  const { t } = useTranslation();
  // extract props
  const ruleList = props.ruleList || [];
  if (props.required) {
    ruleList.push({
      name: "required",
    });
  }

  // State
  let initState: RadioControlState = {
    touched: false,
    loading: true,
    items: [],
    value: props.value ? props.value : null,
    valueStr: props.value ? props.value : null,
    valueObj: {
      value: props.value ? props.value : null,
    },
    controlState: {
      invalid: true,
    },
  };

  // prepare state
  const [state, setState] = useState(initState);
  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  /** Hook */
  useEffect(() => {
    const getData = async () => {
      let items: any = [];
      if (props.searchFn) {
        const res = await props.searchFn();
        if (res) {
          items = res;
          if (items && items.length > 0 && items[0].group) {
            items = groupOption(items);
          }
        }
      } else {
        items = props.enum || [];
        if (items && items.length > 0 && items[0].group) {
          items = groupOption(items);
        }
      }
      const value = props.value ? props.value : null;
      const valueObj = items.find((x: any) => x.value === value);
      const valueStr = valueObj?.label;
      const _ruleList = props.ruleList || [];
      if (props.required) {
        _ruleList.push({
          name: "required",
        });
      }

      const controlState = props.controlState
        ? props.controlState
        : validateControl(_ruleList || [], value, t);
      if (!mountedRef.current) return null;
      setState({
        loading: false,
        touched: false,
        items,
        value,
        valueStr,
        valueObj,
        controlState,
      });
    };
    getData();
  }, [props, setState, t]);

  const onChange = async (value: string) => {
    const valueObj = state.items.find((x: any) => x.value === value);
    const valueStr = valueObj?.label;
    const controlState = validateControl(ruleList, value, t);
    let _state = {
      ...state,
      value,
      valueStr,
      controlState,
      valueObj,
      loading: false,
    };

    if (props.onChange) {
      props.onChange({
        controlState: _state.controlState,
        value: _state.value,
        valueStr: _state.valueStr,
        valueObj: _state.valueObj,
      });
    }
    if (props.onTrueUpdateValue) {
      props.onTrueUpdateValue({
        controlState: _state.controlState,
        value: _state.value,
        valueStr: _state.valueStr,
        valueObj: _state.valueObj,
      });
    }
    if (!jsonEqual(_state, state)) {
      setState(_state);
    }
  };

  // const onFocus = () => {
  //   if (props.onTouched) {
  //     return props.onTouched();
  //   }
  //   if (!state.touched) {
  //     setState({
  //       ...state,
  //       touched: true,
  //     });
  //   }
  // };

  return (
    <>
      <div
        className={`radio-control-inner p-field ${
          props.noLabel ? "no-label" : ""
        }`}
      >
        <label htmlFor={props.id}>
          {props.label}
          {props.required && !props.noRequiredLabel ? (
            <small className="required p-invalid">&nbsp;*</small>
          ) : null}
          {props.tooltip ? (
            <Button
              type="button"
              tooltip={props.tooltip}
              tooltipOptions={{ position: "top" }}
              icon="pi pi-info-circle"
              className="p-button-rounded label-help p-button-text p-button-plain"
            />
          ) : null}
        </label>
        <div
          className={`p-inputgroup ${
            isError(state, props) ? "p-inputgroup-error" : ""
          }`}
        >
          <div className={`radio-group ${props.vertical ? "vertical" : ""}`}>
            {state.items.map((item: RadioControlOption, index: number) => {
              return (
                <div
                  className="p-field-radiobutton"
                  key={`${index}-${item.value}-${props.id}`}
                >
                  <RadioButton
                    inputId={`${index}-${item.value}-${props.id}`}
                    name={`${props.label}-radio`}
                    value={item.value}
                    onChange={(event) => onChange(event.value)}
                    checked={state.value === item.value}
                    style={{ width: "100%" }}
                    disabled={props.config.readOnly}
                    // onFocus={() => onFocus()}
                    // onBlur={() => onBlur()}
                  />
                  <label htmlFor={`${index}-${item.value}-${props.id}`}>
                    {item.label}&nbsp;
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        {renderError(state, props, t)}
      </div>
    </>
  );
};

export default RadioControl;
