import { Animated } from "react-animated-css";
import { Trans, useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { GetLink } from "../../portal/login-modules";
import "./error-404.scss";
import ErrorContainer from "./error-container";

const Page404 = () => {
  const { t } = useTranslation();

  return (
    <ErrorContainer>
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
      >
        <div className="error-bg">
          <p>
            <span className="error-title">404</span>
            <span className="error-subtitle">{t("common_error_404")}</span>
          </p>
        </div>
      </Animated>
    </ErrorContainer>
  );
};

export default Page404;

export const PageNotFound = () => {
  const { t } = useTranslation();
  let history = useHistory();

  const content = () => {
    return (
      <div className="error-404-bg">
        <p>
          <span className="error-404-title">404</span>

          <span className="error-404-subtitle">{t("common_error_404")}</span>
          <span className="error-404-text">
            <Trans i18nKey="common_error_404_message_1">
              <Link to={GetLink()} />
            </Trans>

            <Trans i18nKey="common_error_404_message_2">
              <span
                className="error-404-text-message"
                onClick={history.goBack}
              ></span>
            </Trans>
          </span>
        </p>
      </div>
    );
  };

  return content();
};

export const ErrorPage = (props) => {
  let history = useHistory();

  const content = () => {
    return (
      <div className="error-404-bg">
        <p>
          <span className="error-404-title">{props.title}</span>

          <span className="error-404-subtitle">{props.subtitle}</span>
          <span className="error-404-text">
            <Trans i18nKey="common_error_404_message_1">
              <Link to={GetLink()} />
            </Trans>

            <Trans i18nKey="common_error_404_message_2">
              <span
                className="error-404-text-message"
                onClick={history.goBack}
              ></span>
            </Trans>
          </span>
        </p>
      </div>
    );
  };

  return content();
};
