import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";
import { setGlobal } from "../../redux/actions/global";
import "./main-toast.scss";

function MainToast() {
  const dispatch = useDispatch();
  return (
    <>
      <Toast
        ref={(el) => {
          dispatch(
            setGlobal({
              toast: el,
            })
          );
        }}
      />
    </>
  );
}
export default MainToast;
