export const REQUEST_EXCLUDE_TOKEN = [
  "/authentication/authenticatebysupportssotoken",
];

export const URL_SESSION_EXCLUDED = ["/", "/logout"];
export const PAGE_EXCLUDE_SIDEBAR = ["/clients"];

export const TEMPLATE_INDEX = {
  CompanyDocumentConfiguration:
    "https://tricor365.sharepoint.com/:x:/r/sites/HRISProject/Shared%20Documents/Onboarding%20Automation/Configurations/5.%20Company%20Document%20Configuration/CompanyDocument.xlsx?d=w62cb799333f04a1aba74e6fd2a165963&csf=1&web=1&e=6NhEzN",
  LanguageTranslations:
    "https://tricor365.sharepoint.com/:f:/r/sites/HRISProject/Shared%20Documents/Onboarding%20Automation/Configurations/1.%20Language%20Translations?csf=1&web=1&e=teGIDJ",
  eRentalFormsConfigurations:
    "https://tricor365.sharepoint.com/:f:/r/sites/HRISProject/Shared%20Documents/Onboarding%20Automation/Configurations/2.%20eRental%20Forms%20Configurations?csf=1&web=1&e=r8N3Wg",
  EmployeeTabsConfiguration:
    "https://tricor365.sharepoint.com/:x:/r/sites/HRISProject/Shared%20Documents/Onboarding%20Automation/Configurations/3.%20Employee%20Tabs%20Configuration/Employee%20Tabs%20Configuration.xlsx?d=w68d96c0e73f7400db9b1da95596deba4&csf=1&web=1&e=phTxV5",
  ClientSettingsConfiguration:
    "https://tricor365.sharepoint.com/:f:/r/sites/HRISProject/Shared%20Documents/Onboarding%20Automation/Configurations/4.%20Client%20Settings%20Configuration?csf=1&web=1&e=aar5PL",
  EmailTemplateConfiguration:
    "https://tricor365.sharepoint.com/:f:/r/sites/HRISProject/Shared%20Documents/Onboarding%20Automation/Configurations/6.%20Email%20Template%20Configuration?csf=1&web=1&e=gzhReQ",
  EmployeeMyInfo:
    "https://tricor365.sharepoint.com/:x:/r/sites/HRISProject/Shared%20Documents/Onboarding%20Automation/Configurations/7.%20Employee%20(My%20Info)/Employee%20(My%20Info).xlsx?d=w41607bf7da93475b8cbd89f6e858c3c1&csf=1&web=1&e=zwSOUe",
};
