import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { createBrowserHistory } from "history";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { MSAL_CONFIG } from "./azure/azure-authentication-config";
import "./index.scss";
import { persistor, store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { createAxiosApiInstance } from "./services/axios-config";
import { serviceOptions as serviceOptionsHrmnetApi } from "./services/hrmnet-api/serviceOptions";

// configure process.env
require("dotenv").config();

// browser history for services call
const history = createBrowserHistory();

serviceOptionsHrmnetApi.axios = createAxiosApiInstance(
  window.REACT_APP_API_ENDPOINT,
  store,
  history
);

const msalInstance = new PublicClientApplication(MSAL_CONFIG);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MsalProvider instance={msalInstance}>
        <Router>
          <App />
        </Router>
      </MsalProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
