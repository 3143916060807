import { SIDEBAR_MENU } from "../redux-constants";
const initialState = {
  sideBar: [],
};

export default function sidebar(state = initialState, action) {
  switch (action.type) {
    case SIDEBAR_MENU: {
      return {
        ...state,
        sideBar: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
