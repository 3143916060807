import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../../azure/azure-authentication-config";
import { Button } from "primereact/button";
import { useState } from "react";
import "./sign-in-button.scss";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
  const { instance } = useMsal();
  const [error, setError] = useState();

  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      try {
        instance.loginPopup(loginRequest);
      } catch (error) {
        setError(error);
      }
    } else if (loginType === "redirect") {
      try {
        instance.loginRedirect(loginRequest);
      } catch (error) {
        setError(error);
      }
    }
  };
  return (
    <>
      <Button
        className="authentication-button"
        label="Login"
        onClick={() => handleLogin(window.MS_AZURE_LOGIN_INTERACTION_TYPE)}
      />
      <small className="p-invalid p-d-block p-invalid-custom">{error}</small>
    </>
    // <DropdownButton variant="secondary" className="ml-auto" drop="left" title="Sign In">
    //     <Dropdown.Item as="button" onClick={() => handleLogin(window.MS_AZURE_LOGIN_TYPE)}>Sign in using Popup</Dropdown.Item>
    //     <Dropdown.Item as="button" onClick={() => handleLogin("redirect")}>Sign in using Redirect</Dropdown.Item>
    // </DropdownButton>
  );
};
