import { SET_SIDE_MODAL, SET_SIDE_MODAL_DYNA } from "./../redux-constants";

const DEFAULT_MODAL = {
  hidePrimary: false,
  hideSecond: false,
  secondButtonClickFn: ({ closeFn }) => {
    closeFn();
  },
  primaryButtonClickFn: async ({ closeFn }) => {
    closeFn();
  },
};
/**
 * openModal
 */
export const openSideModal = (modal) => {
  return {
    type: SET_SIDE_MODAL,
    payload: {
      ...DEFAULT_MODAL,
      ...modal,
      isOpen: true,
    },
  };
};

/**
 * Close modal
 */
export const closeSideModal = () => {
  return {
    type: SET_SIDE_MODAL,
    payload: {
      isOpen: false,
    },
  };
};

/**
 * set modal config with key
 * @param {*} key
 * @param {*} modal {isOpen: true, data: {}}
 */
export const setSideModalDyna = (key, modal) => {
  return {
    type: SET_SIDE_MODAL_DYNA,
    payload: {
      key: key,
      modal: modal,
    },
  };
};
