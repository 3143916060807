import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { setClientInfo } from "../../redux/actions/client";

/**
 * For protecting the pages to be accessible after login
 * @param {} param0
 */
const GuardedRoute = ({ component: Component, auth, ...rest }) => {
  const dispatch = useDispatch();

  //save client full name
  useEffect(() => {
    const verifyClient = async () => {
      if (rest.location?.state) {
        dispatch(setClientInfo(rest.location?.state));
      }
    };

    verifyClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.location?.state]);

  return (
    <Switch>
      {auth && (
        <Route path={`/`} render={(props) => <Component {...props} />} />
      )}
      {!auth && rest.children}
    </Switch>
  );
};
export default GuardedRoute;
