import { AUTH_SET_INFO } from "./../redux-constants";
import { AUTH_RESET_INFO } from "./../redux-constants";

/**
 * set user status
 */
export const setAuthInfo = (authInfo) => {
  return {
    type: AUTH_SET_INFO,
    payload: authInfo,
  };
};

/**
 * reset user status
 */
export const resetAuthInfo = () => {
  return {
    type: AUTH_RESET_INFO,
  };
};
