export const ProcessMenuTree = (t, sideBarInfo, menus) => {
  var result = menus.map((module) => {
    let children, key;
    if (module.subMenus && module.subMenus.length > 0) {
      children = module.subMenus.map((_function, index) => {
        key = module.url;
        if (module.url !== _function.url) {
          key = module.url + "~" + _function.url;
        }
        const isActive =
          sideBarInfo &&
          sideBarInfo.expandedKeys &&
          sideBarInfo.expandedKeys[key];

        const _result = {
          key,
          label: t(_function.name),
          icon: _function.icon,
          isActive: isActive,
          leaf: true,
          data: {
            module,
            function: _function,
          },
          display: module.display,
        };
        return _result;
      });
    }

    key = module.url;
    const isActive =
      sideBarInfo && sideBarInfo.expandedKeys && sideBarInfo.expandedKeys[key];

    const _result = {
      key: module.url,
      label: t(module.name),
      icon: module.icon,
      isActive: isActive,
      children,
      data: {
        module,
        function: { url: module.url },
      },
      display: module.display,
    };
    return _result;
  });
  return result;
};
