// import { Animated } from "react-animated-css";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IMAGE_DEFAULT } from "../../constants";
import { LoginAlert } from "../layouts";
import { Footer } from "./components";
import { SignInButton } from "./components/sso-login/sign-in-button";
import { SignOutButton } from "./components/sso-login/sign-out-button";
import "./login.scss";

// component

const Login = () => {
  const loginRenderContent = () => {
    return (
      <div className="ep-login">
        <div className="p-grid p-grid-fullpage ep-login-no-margin">
          <div className="p-col-12 p-md-6 p-lg-9 p-d-none p-d-md-block ep-login-bg">
            <div className={`ep-login-img-full`}>
              <LazyLoadImage
                alt="logo"
                effect="blur"
                src={IMAGE_DEFAULT.LOGIN_BACKGROUND}
              />
            </div>
            <div className="ep-login-info">
              <div className="p-grid">
                <div className="p-col-11 p-md-11 p-lg-9 p-offset-1"></div>
              </div>
            </div>
          </div>
          <div className="p-col-12 p-md-6 p-lg-3 ep-login-panel">
            <div className="ep-login-panel-form">
              <div className="ep-login-header">
                <LazyLoadImage
                  alt="logo"
                  effect="blur"
                  className="logo"
                  src={IMAGE_DEFAULT.TRICOR_LOGO}
                />
              </div>
              <span>
                <h1 className="login-form-entity-display-name">
                  Login into your account
                </h1>
              </span>
              <LoginAlert />
              <div className="ep-login-body">
                <AuthenticatedTemplate>
                  <SignOutButton />
                </AuthenticatedTemplate>

                <UnauthenticatedTemplate>
                  <SignInButton />
                </UnauthenticatedTemplate>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    );
  };
  return loginRenderContent();
};

export default Login;
