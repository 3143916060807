import { useMsal } from "@azure/msal-react";
import { Button } from "primereact/button";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { OnLogin } from ".";
import { loginRequest } from "../../../../azure/azure-authentication-config";
import { PATH, RESPONSE_MESSAGE_TYPE } from "../../../../constants";
import { setUserInfo } from "../../../../redux/actions/user";
import { AuthenticationService } from "../../../../services/hrmnet-api";
import "./sign-out-button.scss";

function RequestToken(
  loginRequest,
  accounts,
  instance,
  onAuthenticated,
  dispatch,
  history
) {
  // Silently acquires an access token which is then attached to a request for MS Graph data
  if (accounts.length > 0) {
    delete loginRequest["prompt"];
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        dispatch(setUserInfo(response.account));
        onAuthenticated(response.idToken);
      })
      .catch((error) => {
        history.push(PATH.LOGOUT);
      });
  }
}

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
  const { instance, accounts } = useMsal();
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorMessages, setErrorMessages] = useState();

  const onAuthenticated = useCallback(
    async (idToken) => {
      const res =
        await AuthenticationService.supportAuthenticationAuthenticateByAdSsoToken(
          {
            body: {
              token: idToken,
            },
          }
        );

      if (
        res?.messages.length > 0 &&
        res?.messages.filter((x) => x.type === RESPONSE_MESSAGE_TYPE.ERROR)
      ) {
        setErrorMessages(res.messages);
        return;
      }

      const result = await OnLogin(res, dispatch, history);
      setErrorMessages(result);
      return;
    },
    [dispatch, history]
  );

  useEffect(() => {
    RequestToken(
      loginRequest,
      accounts,
      instance,
      onAuthenticated,
      dispatch,
      history
    );

    return () => {
      setErrorMessages();
    };
  }, [accounts, instance, onAuthenticated]);

  const handleLogout = (logoutType) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };
  return (
    <div className="sign-out">
      <Button
        className="authentication-button"
        label="Logout"
        onClick={() => handleLogout(window.MS_AZURE_LOGIN_TYPE)}
        disabled={true}
      />
      {errorMessages && (
        <Button
          className="retry-message-button"
          label={"Try Again"}
          onClick={() =>
            RequestToken(
              loginRequest,
              accounts,
              instance,
              onAuthenticated,
              dispatch,
              history
            )
          }
        />
      )}
    </div>
  );
};
