import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { clearClientInfo } from "../../redux/actions/client";

const ClientManagement = (props) => {
  let match = useRouteMatch();
  const dispatch = useDispatch();
  const tenantName = useSelector((state) => state.client.tenantName);
  const tenantFullName = useSelector((state) => state.client.tenantFullName);
  const history = useHistory();

  useEffect(() => {
    if (window.location.pathname === match.path) {
      dispatch(clearClientInfo());
    } else if (!tenantName && !tenantFullName) {
      history.push(match.path);
    }
  }, [match.path, dispatch, tenantName, tenantFullName, history]);

  return (
    <Switch>
      {props.subMenus.map((route, index) => {
        return (
          <Route
            key={index}
            path={`${match.path}/${route.path}`}
            render={() => <route.component {...route} />}
            {...route.routeProperty}
          />
        );
      })}
    </Switch>
  );
};

export default ClientManagement;
