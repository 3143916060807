import "./main-language.scss";

import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayPanel } from "primereact/overlaypanel";
import { useTranslation } from "react-i18next";
import { Tree } from "primereact/tree";

import { StorageSerive } from "./../../../../../../utils";
import { STORAGE_KEY } from "./../../../../../../constants";

//redux
import { setSideBar } from "./../../../../../../redux/actions/sidebar";
import { openModal } from "./../../../../../../redux/actions/modal";
import { setUserLanguage } from "./../../../../../../redux/actions/language";

function MainLanguage({ ...props }) {
  const { t, i18n } = useTranslation();
  let op = useRef();
  const language = useSelector((state) => state.language) || null;
  const isOpenSidebarMobile = useSelector(
    (state) => state.sidebar?.isOpenSidebarMobile
  );
  const dispatchSidebar = useDispatch();
  const dispatch = useDispatch();

  const [state, setState] = useState({});
  // useEffect(() => {
  //   const loadData = () => {
  //     if (language) {
  //       setState({ ...language.language });
  //     }
  //   };
  //   loadData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [language]);

  // console.log("langguage", language)

  useEffect(() => {
    const updateLanguage = async () => {
      if (state.selectedLang) {
        // await loadCustomTranslation(state.selectedLang?.value, i18n).finally(
        await i18n.changeLanguage(state.selectedLang?.key).finally(() => {
          dispatch(setUserLanguage({ language: state }));
        });
        // );
        // reload saved bundle
        if (language?.loadedContext?.data[language]) {
          i18n.addResourceBundle(
            language,
            "translation",
            language?.loadedContext?.data[language],
            true,
            true
          );
        }
      }
    };
    updateLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedLang]);

  // console.log("--selectedLang--", language.language);

  useEffect(() => {
    if (language) {
      setState({ ...language.language });
    }
  }, [language]);

  const updateLanguage = async (e) => {
    dispatch(
      openModal({
        title: t("dialogue_language_title"),
        content: t("dialogue_language_content"),
        primaryButtonText: t("dialogue_language_confirm"),

        primaryButtonClickFn: async ({ closeFn }) => {
          await StorageSerive.setItem(STORAGE_KEY.SELECTED_LANGUAGE, e);
          if (op) {
            op.hide();
          }
          setState({
            ...state,
            selectedLang: e,
          });
          closeFn();
        },
        secondButtonClickFn: ({ closeFn }) => {
          closeFn();
        },
      })
    );
  };
  const toggleNotification = async (e) => {
    op.toggle(e);
  };

  return (
    <>
      <div className="main-language p-grid p-nogutter p-align-center">
        {state.languageList?.length <= 1 ? null : (
          <>
            <Button
              type="button"
              className={`p-button-secondary p-button-text ${
                props.isAuthenticated
                  ? "main-language-button"
                  : "main-language-login-button"
              }`}
              icon={`pi pi-globe ${
                props.isAuthenticated
                  ? "language-icon-size"
                  : "language-icon-size-login"
              }`}
              label={props.isAuthenticated ? "" : t("login_language")}
              iconPos="right"
              onClick={(e) => {
                // Close mobile sidebar if open while switching between different languages
                if (isOpenSidebarMobile) {
                  let newSideBarInfo = {};
                  newSideBarInfo = {
                    isOpenSidebarMobile: false,
                  };
                  dispatchSidebar(setSideBar(newSideBarInfo));
                }
                toggleNotification(e);
              }}
            ></Button>
            <OverlayPanel
              ref={(el) => (op = el)}
              id="overlay_panel"
              className={`${
                props.isAuthenticated
                  ? "main-language-panel"
                  : "main-language-login-panel"
              }`}
              appendTo={
                props.isAuthenticated
                  ? document.getElementById("main-header")
                  : document.getElementById("ep-login-main-top-toolbar")
              }
              // Disable scrolling in mobile when overlay panel is open
              // onShow={() =>
              //   document.body.classList.add("block-scroll-in-mobile")
              // }
              // onHide={() =>
              //   document.body.classList.remove("block-scroll-in-mobile")
              // }
            >
              <Tree
                className="c-setting-tree"
                selectionMode="single"
                selectionKeys={i18n.language}
                onSelectionChange={async (_) => {
                  let selectedLanguage = state.languageList.find(
                    (x) => x.key === _.value
                  );

                  if (_.value !== i18n.language) {
                    await updateLanguage(selectedLanguage);
                  }
                }}
                value={state.languageList || []}
              />
            </OverlayPanel>
          </>
        )}
      </div>
    </>
  );
}
export default MainLanguage;
