import { SET_SIDEBAR, SIDEBAR_CLEAR } from "../redux-constants";
const initialState = {
  isOpenSidebar: true,
  isOpenSidebarMobile: false,
  mustLoad: false,
  data: [],
};

export default function sidebar(state = initialState, action) {
  switch (action.type) {
    case SET_SIDEBAR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SIDEBAR_CLEAR: {
      // reset to initialState
      return initialState;
    }
    default: {
      return state;
    }
  }
}
