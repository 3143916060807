// constants
export const AUTH_SET_INFO = "AUTH_SET_INFO";
export const AUTH_RESET_INFO = "AUTH_RESET_INFO";
export const SET_LIST_TENANT = "SET_LIST_TENANT";
export const SET_SELECTED_TENANT = "SET_SELECTED_TENANT";
export const SET_CLIENT_INFO = "SET_CLIENT_INFO";
export const SET_GLOBAL = "SET_GLOBAL";
export const SET_SIDEBAR = "SET_SIDEBAR";
export const SIDEBAR_CLEAR = "SIDEBAR_CLEAR";
export const SIDEBAR_MENU = "SIDEBAR_MENU";

// -- languages
export const SET_USER_LANGUAGE = "SET_USER_LANGUAGE";
export const SET_USER_SELECTED_LANGUAGE = "SET_USER_SELECTED_LANGUAGE";
export const LANGUAGE_SET_LOADED_CONTEXT = "LANGUAGE_SET_LOADED_CONTEXT";
export const LANGUAGE_CLEAR_LOADED_CONTEXT = "LANGUAGE_CLEAR_LOADED_CONTEXT";

export const SET_BREAD_CRUMB = "SET_BREAD_CRUMB";
export const SET_MODAL = "SET_MODAL";
export const SET_LOADING = "SET_LOADING";
export const SET_SIDE_MODAL = "SET_SIDE_MODAL";
export const SET_SIDE_MODAL_DYNA = "SET_SIDE_MODAL_DYNA";

export const SET_USER_INFO = "SET_USER_INFO";
export const CLEAR_USER_INFO = "CLEAR_USER_INFO";

export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_COUNTDOWN_TIMER = "SET_COUNTDOWN_TIMER";

export const CLEAR_REDUX = "CLEAR_REDUX";

export const SET_PASSWORD_POLICY = "SET_PASSWORD_POLICY";

export const BREADCRUMB_SET_DYNAMIC_PATHS = "BREADCRUMB_SET_DYNAMIC_PATHS";
export const BREADCRUMB_RESET_DYNAMIC_PATHS = "BREADCRUMB_RESET_DYNAMIC_PATHS";
