import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { jsonEqual, sleep } from "./../../../utils/index";
import {
  BCProps,
  BCStates,
  isError,
  renderError,
  validateControl,
} from "./../base-control";
import "./password-control.scss";

export interface PasswordControlAction {
  /**
   * Max length
   */
  label?: string;
  /**
   * Max length
   */
  icon?: string;
  /**
   * Max length
   */
  tooltip?: string;
  /**
   * Max length
   */
  clickFn?: () => any;
  /**
   * Max length
   */
  className?: string;
  /**
   * onMouseDown
   */
  onMouseDown?: () => any; //aw
  /**
   * onMouseLeave
   */
  onMouseLeave?: () => any; //aw
  /**
   * onTouchStart
   */
  onTouchStart?: () => any; //aw
  /**
   * onTouchEnd
   */
  onTouchEnd?: () => any; //aw
  /**
   * onMouseUp
   */
  onMouseUp?: () => any; //aw
  /**
   * onTouchMove
   */
  onTouchMove?: () => any; //aw
}
export interface PasswordControlProps extends BCProps {
  /**
   * Show password hint
   */
  showPasswordHint?: boolean;
  /**
   * pattern
   */
  pattern?: string;
  /**
   * pattern message
   */
  patternMessage?: string;
  /**
   * Action
   */
  action?: PasswordControlAction;
  /**
   * revealState
   */
  revealState?: boolean; // aw
  /**
   * viewMode
   */
  viewMode?: boolean; //aw
  /**
   * ruleList
   */
  ruleList?: any; //aw
  /**
   * passwordPolicyPattern
   */
  passwordPolicyPattern?: RegExp; //aw
  /**
   * fromFilter
   */
  fromFilter?: any; //aw
  /**
   * required
   */
  required?: boolean; //aw
  /**
   * id
   */
  id?: string; //aw
  /**
   * company policy rule
   */
  rule?: any; //aw
  /**
   * Max length
   */
  maxLength?: number; //aw
  /**
   * Character set
   */
  charset?: string; //aw
}
export interface PasswordControlState extends BCStates {}
const PasswordControl: React.FC<PasswordControlProps> = (props) => {
  const { t } = useTranslation();
  // extract props data
  const id = props.id || "";
  const lazy = props.fromFilter;
  const maxLength = props.maxLength || 20; //aw
  const ruleList = props.ruleList || [];
  if (props.required) {
    ruleList.push({
      name: "required",
    });
  }

  if (props.showPasswordHint && !props.passwordPolicyPattern) {
    ruleList.push({
      name: "pattern",
      param: /^(?=.*[A-Za-z])(?=.*\d).{6,}$/, //aw - support special characters
      error: t("base_control_password_invalid"),
    });
  }

  if (props.showPasswordHint && props.passwordPolicyPattern) {
    ruleList.push({
      name: "pattern",
      param: new RegExp(props.passwordPolicyPattern), //aw - support company policy password
      error: t("base_control_password_incompliance"),
    });
  }

  if (props.pattern) {
    ruleList.push({
      name: "pattern",
      param: props.pattern,
      error: props.patternMessage || t("base_control_password_field_invalid"),
    });
  }

  // init state control
  let initState: PasswordControlState = {
    touched: false,
    value: props.value,
    valueStr: (props.value || "").toString(),
    controlState: {
      invalid: false,
    },
  };
  initState.controlState =
    props.controlState || validateControl(ruleList || [], initState.value, t);
  const [state, setState] = useState(initState);
  const mountedRef = useRef(true);
  // unsubcribe
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  // Update state if control state changed
  useEffect(() => {
    const ruleList = props.ruleList || [];
    if (props.required) {
      ruleList.push({
        name: "required",
      });
    }

    let controlState =
      props.controlState || validateControl(ruleList || [], props.value, t);
    if (!mountedRef.current) return;
    setState({
      ...state,
      value: props.value,
      setDefault: true,
      controlState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.controlState, props.required, props.value]);
  // Update if rule update
  useEffect(() => {
    if (!mountedRef.current) return;
    onChange(state.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.required, props.ruleList]);

  useEffect(() => {
    if (
      props.defaultValue &&
      props.defaultValue !== state.lastDefault &&
      state.setDefault
    ) {
      onChange(props.defaultValue, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultValue, state.lastDefault]);

  // Update display value if state value changed
  useEffect(() => {
    if (lazy) {
      try {
        // debugger;
        if (id) {
          let elm = document.getElementById(id) as any;
          if (elm) elm.value = state.value || "";
        }
      } catch (error) {}
    }
  }, [id, lazy, state.value]);

  const onChange = async (value: string, updateLastDefault = false) => {
    let valueStr = value ? value.toString() : "";

    const controlState = validateControl(ruleList, value, t);
    let _state = { ...state };
    if (updateLastDefault) {
      _state.lastDefault = props.defaultValue;
    }
    // onChange
    if (!lazy && props.onChange) {
      props.onChange({
        controlState: controlState,
        value: value,
        valueStr: valueStr,
      });
    }
    if (!lazy) {
      // do set state
      _state.value = value;
      _state.valueStr = valueStr;
      _state.controlState = controlState;
      if (!jsonEqual(_state, state)) {
        setState(_state);
      }
      return;
    }
    await localStorage.setItem(id, value);
    await sleep(500);
    const storageValue = await localStorage.getItem(id);
    if (storageValue === value) {
      if (props.onTrueUpdateValue) {
        props.onTrueUpdateValue({
          controlState: controlState,
          value: value,
          valueStr: valueStr,
        });
      }
      if (lazy && props.onChange) {
        props.onChange({
          controlState: controlState,
          value: value,
          valueStr: valueStr,
        });
      }

      if (lazy) {
        _state.value = value;
        _state.valueStr = valueStr;
        _state.controlState = controlState;
        if (_state !== state) {
          setState(_state);
        }
      }
    }
  };
  const onBlur = () => {
    if (props.onTrueUpdateValue) {
      props.onTrueUpdateValue({
        controlState: state.controlState,
        value: state.value,
        valueStr: state.valueStr,
      });
    }
  };
  const onKeyUp = (event: any) => {
    if (event.key === "Enter") {
      if (props.onTrueUpdateValue) {
        props.onTrueUpdateValue({
          controlState: state.controlState,
          value: state.value,
          valueStr: state.valueStr,
        });
      }
    }
  };
  const onKeyDown = (event: any) => {
    if (props.fromFilter) {
      try {
        if (id) {
          let elm = document.getElementById(id) as any;
          var startPos = elm.selectionStart;
          if (
            (!elm.value || elm.value.trim() === "" || startPos === 0) &&
            event.keyCode === 32
          ) {
            event.preventDefault();
          }
        }
      } catch (error) {}
    }
  };
  const onFocus = () => {
    if (props.onTouched) {
      props.onTouched();
      return;
    }
    if (!state.touched) {
      setState({
        ...state,
        touched: true,
      });
    }
  };
  const renderControl = () => {
    let commonProp = {
      ...props.config,
      id,
      className: `${props.className} ${
        state.controlState.invalid && state.touched ? "p-invalid" : ""
      }`,
      placeholder: props.placeholder,
      autoFocus: props.autoFocus,
      onChange: (event: any) => onChange(event.currentTarget.value),
      onBlur: () => onBlur(),
      onKeyUp: (event: any) => onKeyUp(event),
      onFocus: () => onFocus(),
      onKeyDown: (event: any) => onKeyDown(event),
      tooltipOptions: { position: "top" },
    };
    commonProp.tooltip = state.valueStr || "";
    if (!lazy) {
      commonProp.value = state.value || "";
    }
    commonProp.tooltip = null;
    const footer = (
      <>
        <ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: "1.5" }}>
          <li>{t("base_control_password_hint1")}</li>
          <li>{t("base_control_password_hint2")}</li>
          <li>{t("base_control_password_hint3")}</li>
          <li>{t("base_control_password_hint4")}</li>
        </ul>
      </>
    );

    if (props.viewMode === true || props.viewMode) {
      // commonProp.tooltip = null; //aw
      // commonProp.value = "********";
      // pholder = "*******"; //added custom value - aw
      // [19 May 2021] kc - for some reason the iPhone Chrome keep
      // prompting for saving password if use Password.
      // try to return as InputText instead
      return (
        <InputText
          // panelClassName="bc-pc-panel"
          {...commonProp}
          value="********"
          // placeholder={props.placeholder || t("Password password")} //aw
          placeholder={props.placeholder} //aw
        ></InputText>
      );
    }
    // console.log("testing", props);

    const header = <></>;
    return (
      <Password
        maxLength={maxLength}
        panelClassName="bc-pc-panel"
        header={header}
        footer={footer}
        weakLabel={t("base_control_password_weak")}
        mediumLabel={t("base_control_password_medium")}
        strongLabel={t("base_control_password_strong")}
        feedback={false}
        {...commonProp}
        // placeholder={props.placeholder || t("Password password")} //aw
        placeholder={props.placeholder} //aw
      />
    );
  };
  const renderAction = () => {
    if (!props.action) {
      return null;
    } else {
      return (
        <Button
          label={props.action?.label}
          icon={props.action?.icon}
          tooltip={props.action?.tooltip}
          tooltipOptions={{ position: "top" }}
          onClick={props.action?.clickFn}
          onMouseDown={props.action?.onMouseDown} // added custom function - aw
          onMouseLeave={props.action?.onMouseLeave} // added custom function - aw
          onTouchStart={props.action?.onTouchStart} // added custom function - aw
          onTouchEnd={props.action?.onTouchEnd} // added custom function - aw
          onMouseUp={props.action?.onMouseUp} // added custom function - aw
          onTouchMove={props.action?.onTouchMove} // added custom function - aw
          className={props.action?.className + " custom-input-action-btn"} // fix missing reveal button - aw
          type="button"
        />
      );
    }
  };

  const passwordHintWrapper = (
    rule: boolean,
    message: string,
    param: {},
    index: number
  ) => {
    return (
      <li key={index}>
        <i
          className={
            rule
              ? "pi pi-check-circle valid"
              : "pi pi-exclamation-circle invalid"
          }
        ></i>
        {t(message, param)}
      </li>
    );
  };

  const renderPasswordHint = () => {
    let value = state.value || "";
    const noError =
      props.rule?.filter((x: any) => {
        let rulePattern = new RegExp(x.param);
        let regexTest = x.negate
          ? !rulePattern.test(value)
          : rulePattern.test(value);
        return !regexTest;
      }) || [];

    if (noError.length === 0) {
      return;
    }

    let rule = props.rule;
    if (props.charset && props.charset.length > 0) {
      const regex = "[^" + props.charset + "]";
      const noncomplianceCharacters = value.match(new RegExp(regex, "g"));
      const index = rule.findIndex(
        (x: any) =>
          x.message.translation === "passwordPolicy_noIlegalPunctuation"
      );
      if (index > -1) {
        rule.splice(index, 1);
      }

      if (noncomplianceCharacters && noncomplianceCharacters.length > 0) {
        rule.push({
          negate: true,
          param: regex,
          flags: "g",
          message: {
            translation: "passwordPolicy_noIlegalPunctuation",
            param: { 0: ":" + noncomplianceCharacters.join(",") },
          },
        });
      }
    }

    return (
      <div className="passwordHint">
        <span className="message">
          {t("base_control_password_include_at_least")}
        </span>
        <ul>
          {rule?.map((x: any, index: number) => {
            let rulePattern = new RegExp(x.param, x.flags);
            let regexTest = x.negate
              ? !rulePattern.test(value)
              : rulePattern.test(value);
            regexTest = regexTest && !(value === "");
            return passwordHintWrapper(
              regexTest,
              x.message?.translation,
              x.message?.param,
              index
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <>
      <div
        className={`password-control-inner p-field ${
          props.noLabel ? "no-label" : ""
        }`}
      >
        <label htmlFor={props.id}>
          {props.label}
          {props.required && !props.noRequiredLabel ? (
            <small className="required p-invalid">&nbsp;*</small>
          ) : null}
          {props.tooltip ? (
            <Button
              type="button"
              tooltip={props.tooltip}
              tooltipOptions={{ position: "top" }}
              icon="pi pi-info-circle"
              className="p-button-rounded label-help p-button-text p-button-plain"
            />
          ) : null}
        </label>
        <div
          className={`p-inputgroup ${
            isError(state, props) ? "p-inputgroup-error" : ""
          }`}
        >
          {renderControl()}
          {renderAction()}
        </div>
        {props.showPasswordHint
          ? renderPasswordHint()
          : renderError(state, props, t)}
      </div>
    </>
  );
};

export default PasswordControl;
