import { BCProps } from "./base-control";

export const DEFAULT_CONTROL_ITEM = {
  type: "input",
};
export const getControlModel = (column: BCProps) => {
  return {
    ...DEFAULT_CONTROL_ITEM,
    ...column,
  };
};
export const getControlModelByType = (dataType: string) => {
  switch (dataType) {
    case "string":
      return {
        ...DEFAULT_CONTROL_ITEM,
        placeholder: "Input text",
      };
    case "date":
      return {
        ...DEFAULT_CONTROL_ITEM,
        placeholder: "Choose a date",
        type: "date",
      };
    default:
      break;
  }
  return {
    ...DEFAULT_CONTROL_ITEM,
  };
};
