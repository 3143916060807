import { SET_SIDE_MODAL, SET_SIDE_MODAL_DYNA } from "../redux-constants";
const initialState = {
  isOpen: false,
  data: {},
  modals: {},
};

export default function sideModal(state = initialState, action) {
  switch (action.type) {
    case SET_SIDE_MODAL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_SIDE_MODAL_DYNA: {
      let newModals = { ...state.modals };
      newModals[action.payload.key] = action.payload.modal;
      let newState = {
        ...state,
      };
      newState.modals = newModals;

      return newState;
    }

    default: {
      return state;
    }
  }
}
