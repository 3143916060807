import moment from "moment";

export const EnumsKeyToArray = (enumsKey: any[]) => {
  var translations_labels: any[][] = [];
  var translations_labels_concat: any[] = [];
  enumsKey.forEach((enumKey, index) => {
    translations_labels.push(
      Object.keys(enumKey).map((key) => enumsKey[index][key])
    );
  });
  translations_labels.forEach((t_labels) => {
    translations_labels_concat = translations_labels_concat.concat(t_labels);
  });
  return translations_labels_concat;
};

const isNumber = (value: string) => {
  return /^-?\d+$/.test(value);
};
const isDate = (value: string) => {
  let date_formart = [
    // moment.ISO_8601,
    // moment.RFC_2822,
    "DD/MM/YYYY",
    "MM/DD/YYYY",
    "YYYY/MM/DD",

    "DD/MM/YYYY hh:mm:ss",
    "MM/DD/YYYY hh:mm:ss",
    "YYYY/MM/DD hh:mm:ss",

    "DD/MM/YYYY hh:mm",
    "MM/DD/YYYY hh:mm",
    "YYYY/MM/DD hh:mm",

    "hh:mm:ss DD/MM/YYYY",
    "hh:mm:ss MM/DD/YYYY",
    "hh:mm:ss YYYY/MM/DD",

    "hh:mm DD/MM/YYYY",
    "hh:mm MM/DD/YYYY",
    "hh:mm YYYY/MM/DD",
  ];
  return moment(value, date_formart, true).isValid();
};

const getObjType = (obj: any) => {
  var dataType = typeof obj;
  if (dataType === "string") {
    if (isDate(obj)) {
      return "date";
    }
    if (isNumber(obj)) {
      return "number";
    }
  }
  return dataType;
};

export const sortArray = (list: any[], key: string, isDesc: boolean) => {
  let compare = (prev: any, next: any) => {
    let a: any;
    let b: any;
    if (isDesc) {
      a = next[key];
      b = prev[key];
    } else {
      a = prev[key];
      b = next[key];
    }
    var result;
    let type = getObjType(a);
    if (type === "undefined") {
      type = getObjType(b);
    }
    switch (type) {
      case "string":
        a = a || "";
        b = b || "";
        result = a.trim().localeCompare(b.trim());
        break;
      case "date":
        a = new Date(a);
        b = new Date(b);
        result = a - b;
        break;
      default:
        result = a - b;
        break;
    }
    return result;
  };
  return list.sort(compare);
};
