import { Animated } from "react-animated-css";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import "./error-container.scss";

const ErrorContainer = (props) => {
  const { t } = useTranslation();

  const renderHeader = () => {
    return (
      <div className="common-header">
        <Link to={`/`}>
          <LazyLoadImage
            className="common-entity-logo"
            alt={t("common_logo")}
            effect="blur"
            src="/assets/images/logo.png"
          />
        </Link>
      </div>
    );
  };

  return (
    <>
      <Animated
        animationIn="slideInRight"
        animationOut="slideOutRight"
        animationInDuration={200}
        animationOutDuration={200}
        isVisible={true}
      >
        {renderHeader()}
        <div className="common-content">{props.children}</div>
      </Animated>
    </>
  );
};

export default ErrorContainer;
