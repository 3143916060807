import { Menubar } from "primereact/menubar";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GetLink } from "../../../login-modules";
import { IMAGE_DEFAULT } from "./../../../../constants";
import { MainBreadcrumb, MainToggle, MainUser } from "./index";
import "./main-header.scss";

/**
 *
 */
const MainHeader = () => {
  const tenantName = useSelector((state) => state.client?.tenantName);
  let op = useRef();

  const renderLeftHeader = () => {
    const defaultLogo = IMAGE_DEFAULT.TRICOR_LOGO;
    const renderIcon = (tenantLogo) => {
      return (
        <Link to={GetLink()}>
          <div className="tenant-logo-container p-grid p-nogutter p-align-center">
            <img
              src={tenantLogo ?? defaultLogo}
              alt=""
              className="tenant-logo-size p-justify-center p-col-align-center p-ml-lg-3 p-ml-md-3 p-ml-1"
            />
          </div>
        </Link>
      );
    };

    return (
      <div className="p-d-flex tenant-left-header">
        {tenantName && <MainToggle />}
        {renderIcon()}
        {/* {tenantName && clientDatas && <MainEntityInfo ref={op} />} */}
        <MainBreadcrumb overlayPanelControl={op} />
      </div>
    );
  };

  const renderRightHeader = () => {
    return (
      <>
        <div className="p-d-flex">
          {/*{isMyPortalDisplay && <MainMyPortal tenantName={tenantName} />}*/}
          <MainUser />
        </div>
      </>
    );
  };

  return (
    <>
      <div id="main-header" className="main-header">
        <Menubar start={renderLeftHeader()} end={renderRightHeader()} />
      </div>
    </>
  );
};
export default MainHeader;
