import "./main-modal.scss";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Animated } from "react-animated-css";
import BaseForm from "./../base-form/base-form";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { closeModal } from "../../redux/actions/modal";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const submitObjForm = async (form, refFormObj) => {
  let formState = false;
  for (const key in refFormObj) {
    if (Object.hasOwnProperty.call(refFormObj, key)) {
      const element = refFormObj[key];
      if (element) {
        let formData = await element.submitForm();
        form = {
          ...form,
          ...formData.form,
        };
        formState = {
          ...formState,
          ...formData.formState,
        };
      }
    }
  }
  return {
    formState,
    form,
  };
};
const MainModal = () => {
  const refFormObj = useRef({});
  let history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    form: {},
    formState: {},
    touched: false,
  });
  const modal = useSelector((state) => state.modal);

  // onbeforeunload
  useEffect(() => {
    window.onbeforeunload = function () {};
    // window.onbeforeunload = function () {
    //   return "Your work will be lost.";
    // };
  });
  useEffect(
    () => () => {
      window.onbeforeunload = function () {};
    },
    []
  );

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        onClose();
      }

      if (history.action === "POP") {
        onClose();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const onClose = () => {
    dispatch(closeModal({}));
  };
  const onClickSecondaryButton = async () => {
    if (modal.secondButtonClickFn) {
      modal.secondButtonClickFn({ closeFn: () => onClose() });
      return;
    }
  };
  const onClickPrimaryButton = async () => {
    if (modal.form) {
      let objForm = await submitObjForm(state.form, refFormObj.current);
      if (modal.primaryButtonClickFn) {
        await modal.primaryButtonClickFn({
          closeFn: () => onClose(),
          formState: objForm.formState,
          form: objForm.form,
        });

        if (objForm.formState.invalid) {
          setState({
            ...state,
            formState: objForm.formState,
          });
        }
      }
      return;
    }
    if (modal.primaryButtonClickFn) {
      await modal.primaryButtonClickFn({ closeFn: () => onClose() });
      return;
    }
  };

  /** Other function */
  const onStateChange = (e, index) => {
    const { submitting, invalid, touched } = e;
    let _state = {
      ...state,
      submitting,
    };
    _state[index + "_invalid"] = invalid;
    _state[index + "_touched"] = touched;
    let main_invalid = false;
    let main_touched = false;
    ["11"].forEach((item_index) => {
      if (_state[item_index + "_invalid"]) {
        main_invalid = true;
      }
      if (_state[item_index + "_touched"]) {
        main_touched = true;
      }
    });
    _state.invalid = main_invalid;
    _state.touched = main_touched;
    if (modal.force_touched) {
      _state.touched = true;
    }
    setState(_state);
  };
  const renderModalBody = () => {
    if (modal.renderModalBody) {
      return modal.renderModalBody();
    } else if (modal.form) {
      return (
        <BaseForm
          id="modal"
          ref={(el) => (refFormObj.current["11"] = el)}
          {...modal.form}
          onStateChange={(e) => onStateChange(e, "11")}
        />
      );
    } else if (modal.content) {
      return modal.content;
    } else return null;
  };
  const renderFooter = () => {
    if (modal.renderFooter) {
      return modal.renderFooter();
    } else {
      return (
        <div className="carbon-btn-group-full main-modal-footer">
          {modal.hideSecond || modal.hidePrimary ? (
            <div style={{ flexGrow: 1 }}></div>
          ) : null}
          {!modal.hideSecond ? (
            <Button
              type="button"
              label={modal.secondaryButtonText || "Cancel"}
              // icon="pi pi-times"
              className="p-button-secondary"
              disabled={modal.preventClose}
              onClick={onClickSecondaryButton}
            />
          ) : null}
          {!modal.hidePrimary ? (
            <Button
              type="submit"
              onClick={onClickPrimaryButton}
              disabled={
                modal.form ? state.formState.invalid || !state.touched : false
              }
              label={modal.primaryButtonText || t("modal_save")}
              autoFocus
            />
          ) : null}
        </div>
      );
    }
  };
  return (
    <div className="MainModal">
      <Dialog
        header={modal.title || t("modal_info")}
        footer={renderFooter()}
        visible={modal.isOpen}
        modal
        onHide={onClose}
        closable={modal.isClosable}
        className={
          modal.classNameMainDialog
            ? `${modal.classNameMainDialog} main-modal`
            : "main-modal"
        }
        // Disable scrolling in mobile when dialog box is open
        blockScroll={true}
      >
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutRight"
          animationInDuration={200}
          animationOutDuration={200}
          isVisible={modal.isOpen}
        ></Animated>
        <div className={modal.className}>{renderModalBody()}</div>
      </Dialog>
    </div>
  );
};

export default MainModal;
