import { STORAGE_KEY } from "./../constants";

export const sleep = async (ms: any) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

function setCookie(name: string, value: string, days: number) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name: string) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name: string) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}
function checkLocalStorage(): boolean {
  var test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}
function checkSectionStorage(): boolean {
  var test = "test";
  try {
    sessionStorage.setItem(test, test);
    sessionStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

function checkIndexedDB(): boolean {
  let _window = window as any;
  //prefixes of implementation that we want to test
  // _window.indexedDB = _window.indexedDB
  //   || _window.mozIndexedDB
  //   || _window.webkitIndexedDB
  //   || _window.msIndexedDB;

  // /**
  // * prefixes of window.IDB objects
  // * IDBTransaction interface of the IndexedDB API provides a static,
  // * asynchronous transaction on a database using event handler attributes.
  // */
  // _window.IDBTransaction = _window.IDBTransaction
  //   || _window.webkitIDBTransaction
  //   || _window.msIDBTransaction;

  // _window.IDBKeyRange = _window.IDBKeyRange
  //   || _window.webkitIDBKeyRange
  //   || _window.msIDBKeyRange;

  if (!_window.indexedDB) {
    return false;
  }
  return true;
}

async function initStorage() {
  // let preferStorage = "cookie";
  // let preferStorage = "localStorage";
  // let preferStorage = "sessionStorage";
  let preferStorage = "IndexedDB";
  const cookieStorage = {
    removeItem: async (key: string) => {
      await sleep(1);
      return eraseCookie(key);
    },
    setItem: async (key: string, value: string) => {
      await sleep(1);
      return setCookie(key, value, 100);
    },
    getItem: async (key: string) => {
      await sleep(1);
      return getCookie(key);
    },
  };
  switch (preferStorage) {
    case "localStorage":
      if (checkLocalStorage()) {
        return {
          removeItem: async (key: string) => {
            await sleep(1);
            return localStorage.removeItem(key);
          },
          setItem: async (key: string, value: string) => {
            await sleep(1);
            return localStorage.setItem(key, value);
          },
          getItem: async (key: string) => {
            await sleep(1);
            return localStorage.getItem(key);
          },
        };
      }
      break;
    case "sessionStorage":
      if (checkSectionStorage()) {
        return {
          removeItem: async (key: string) => {
            await sleep(1);
            return sessionStorage.removeItem(key);
          },
          setItem: async (key: string, value: string) => {
            await sleep(1);
            return sessionStorage.setItem(key, value);
          },
          getItem: async (key: string) => {
            await sleep(1);
            return sessionStorage.getItem(key);
          },
        };
      }
      break;
    case "IndexedDB":
      if (checkIndexedDB()) {
        let promiseRoot = new Promise(function (resolve, reject) {
          var request = (window as any).indexedDB.open("HRIS_STORE", 1);
          request.onupgradeneeded = (event: any) => {
            let db = event.target.result;
            db.createObjectStore("HRIS_STORE", {
              autoIncrement: true,
            });
          };
          request.onerror = function (event: any) {
            reject(event);
          };
          request.onsuccess = function (event: any) {
            let db = event.target.result;
            resolve(db);
          };
        });

        return {
          removeItem: async (key: string) => {
            let promise = new Promise(async function (resolve, reject) {
              let db = (await promiseRoot) as any;
              var request = db
                .transaction("HRIS_STORE", "readwrite")
                .objectStore("HRIS_STORE")
                .delete(key);
              request.onerror = function (event: any) {
                reject(event);
              };
              request.onsuccess = function (event: any) {
                resolve(event);
              };
            });
            return await promise;
          },
          setItem: async (key: string, value: string) => {
            let promise = new Promise(async function (resolve, reject) {
              let db = (await promiseRoot) as any;
              db.transaction("HRIS_STORE", "readwrite")
                .objectStore("HRIS_STORE")
                .delete(key);
              // let request = db.transaction("HRIS_STORE", "readwrite").objectStore("HRIS_STORE").index(key).add(value);

              var objectStore = db
                .transaction("HRIS_STORE", "readwrite")
                .objectStore("HRIS_STORE");
              var request = objectStore.get(key);

              request.onsuccess = (event: any) => {
                var data = event.target.result;
                if (!data) {
                  data = value;
                }
                objectStore.put(data, key);
                resolve(event);
              };

              request.onerror = function (event: any) {
                reject(event);
              };
            });
            return await promise;
          },
          getItem: async (key: string) => {
            await sleep(1);
            let promise = new Promise(async function (resolve, reject) {
              let db = (await promiseRoot) as any;
              var request = db
                .transaction("HRIS_STORE", "readwrite")
                .objectStore("HRIS_STORE")
                .get(key);
              request.onerror = function (event: any) {
                reject(event);
              };
              request.onsuccess = function (event: any) {
                resolve(request.result);
              };
            });
            return await promise;
          },
        };
      }
      break;
    default:
      break;
  }
  return cookieStorage;
}

export class StorageSerive {
  /** Remove item */
  static async removeItem(key: string) {
    await sleep(1);
    return await (await initStorage()).removeItem(key);
  }
  /** Set item */
  static async setItem(key: string, value: string) {
    await sleep(1);
    return await (await initStorage()).setItem(key, value);
  }
  /** Get item */
  static async getItem(key: string) {
    await sleep(1);
    return await (await initStorage()).getItem(key);
  }
  /** Get item */
  static async getItemAsString(key: string) {
    await sleep(1);
    return (await (await initStorage()).getItem(key)) as string;
  }

  /** Get item */
  static async getItemJSON(key: string) {
    await sleep(1);
    let value = (await this.getItem(key)) as string;
    if (!value) {
      return null;
    }
    try {
      return JSON.parse(value);
    } catch (error) {
      return null;
    }
  }

  /** Remove token */
  static async removeToken() {
    return await this.removeItem(STORAGE_KEY.TOKEN_INFO);
  }

  /** Set token */
  static async setToken(value: string) {
    return await this.setItem(STORAGE_KEY.TOKEN_INFO, JSON.stringify(value));
  }
  /** Get token */
  static async getToken() {
    return await this.getItemJSON(STORAGE_KEY.TOKEN_INFO);
  }
}
