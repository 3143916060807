import React from "react";
import { Route, Switch } from "react-router-dom";
import { PageNotFound } from "../components";
import { PATH } from "../constants";
import ClientDetails from "./client-management/client-details/client-details";
import ClientManagement from "./client-management/client-management";
import Configuration from "./client-management/configuration/configuration";
import Features from "./client-management/features/features";
import Clients from "./clients-modules/clients";
import Home from "./home-modules/home";
import LogFiles from "./log-files/log-files";
import NewClientOnboard from "./new-client-onboard/new-client-onboard";
import "./portal.scss";
import PrivilegedAccessManagement from "./privileged-access-management/privileged-access-management";
import {
  EmployeeTabConfiguration,
  RentalFormConfiguration,
  TranslationConfiguration,
} from "./upload-modules";
import SettingsConfiguration from "./upload-modules/components/settings-configuration/settings-configuration";

function PortalRouter(props) {
  return (
    <Switch>
      {PrivateRoutes.map((route, index) => {
        return (
          <Route
            key={index}
            path={`/${route.path}`}
            render={() => {
              return React.createElement(PortalContent, route);
            }}
            {...route.routeProperty}
          />
        );
      })}
      <Route component={PageNotFound} />
    </Switch>
  );
}

const PortalContent = React.forwardRef((props, ref) => {
  return React.createElement(props.component, props);
});

export default PortalRouter;

export const HomeMenus = [
  {
    id: "privileged-access-management",
    path: PATH.PRIVILEGED_ACCESS_MANAGEMENT,
    name: "Privileged Access Management",
    component: PrivilegedAccessManagement,
    displayOnBreadCrumb: true,
  },
  {
    id: "new-client-onboard",
    path: PATH.NEW_CLIENT_ONBOARD,
    name: "New Client Onboard (eForm)",
    component: NewClientOnboard,
    displayOnBreadCrumb: true,
  },
  {
    id: "client-management",
    path: PATH.CLIENT_MANAGEMENT,
    name: "Client Management",
    component: ClientManagement,
    displayOnBreadCrumb: true,
    subMenus: [
      {
        id: "client-list",
        path: ``,
        component: Clients,
        routeProperty: {
          exact: true,
        },
      },
      {
        id: "client-list",
        path: `${PATH.CLIENTS}`,
        component: Clients,
      },
      {
        id: "client-details",
        path: `${PATH.CLIENT_DETAILS}`,
        component: ClientDetails,
        name: "Client Details",
        displayOnBreadCrumb: false,
      },
      {
        id: "manage-client-configuration",
        path: `${PATH.CONFIGURATION}`,
        component: Configuration,
        name: "Manage Client Configuration",
        displaySideBarMenu: true,
        displayOnBreadCrumb: false,
        subMenus: [
          {
            id: "rental-configuration",
            path: `${PATH.RENTAL_FORM_CONFIGURATOIN}`,
            component: RentalFormConfiguration,
            name: "Rental Form Configuration",
            displayOnBreadCrumb: true,
          },
          {
            id: "profile-tab-configuration",
            path: `${PATH.EMPLOYEE_TAB_CONFIGURATION}`,
            component: EmployeeTabConfiguration,
            name: "Employee Tabs Configuration",
            displayOnBreadCrumb: true,
          },
          {
            id: "translation-configuration",
            path: `${PATH.TRANSLATION}`,
            component: TranslationConfiguration,
            name: "Translation Configuration",
            displayOnBreadCrumb: true,
          },
          {
            id: "settings-configuration",
            path: `${PATH.SETTINGS_CONFIGURATION}`,
            component: SettingsConfiguration,
            name: "Settings Configuration",
            displayOnBreadCrumb: true,
          },
        ],
      },
      {
        id: "client-subscriptions",
        path: `${PATH.FEATURES}`,
        component: Features,
        name: "Manage Client Features",
        displaySideBarMenu: true,
        displayOnBreadCrumb: true,
      },
    ],
  },
  {
    id: "log-files",
    path: PATH.LOG_FILES,
    name: "Log Files",
    component: LogFiles,
    displayOnBreadCrumb: true,
  },
];

export const PrivateRoutes = [
  {
    id: "default",
    path: "",
    component: Home,
    menu: HomeMenus,
    menuStyle: { justifyContent: "center" },
    routeProperty: {
      exact: true,
    },
    displayOnBreadCrumb: false,
  },
  ...HomeMenus,
];
