import "./multiselect-control.scss";

import {
  BCProps,
  BCStates,
  isError,
  renderError,
  validateControl,
} from "./../base-control";
import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { useTranslation } from "react-i18next";
import { jsonEqual } from "../../../utils";

//aw
export interface MultiSelectControlAction {
  /**
   * Max length
   */
  label?: string;
  /**
   * Max length
   */
  icon?: string;
  /**
   * Max length
   */
  tooltip?: string;
  /**
   * Max length
   */
  clickFn?: () => any;
  /**
   * Max length
   */
  className?: string;
  /**
   * onMouseDown
   */
  onMouseDown?: () => any; //aw
  /**
   * onMouseLeave
   */
  onMouseLeave?: () => any; //aw
  /**
   * onTouchStart
   */
  onTouchStart?: () => any; //aw
  /**
   * onTouchEnd
   */
  onTouchEnd?: () => any; //aw
  /**
   * onMouseUp
   */
  onMouseUp?: () => any; //aw
  /**
   * onTouchMove
   */
  onTouchMove?: () => any; //aw
}

export interface MultiSelectControlProps extends BCProps {
  /**
   * search Function
   */
  searchFn?: () => any;
  /**
   * data options
   */
  enum?: MultiSelectControlOption[];
  /**
   * Display tree
   */
  isTree?: boolean;
  /**
   * Action
   */
  action?: MultiSelectControlAction; //aw
}

export interface MultiSelectControlState extends BCStates {}
export interface MultiSelectControlOption {
  /**
   * Item group
   */
  group?: string;
  /**
   * Item label
   */
  label: string;
  /**
   * Item value
   */
  value: string;
  /**
   * Item data
   */
  className?: string;
  /**
   * Item data
   */
  data?: string;

  /**
   * Addtional props
   */
  [key: string]: any;
}

const groupOption = (items: MultiSelectControlOption[]) => {
  items = items.sort((a, b) => {
    return (a.group || "").trim().localeCompare((b.group || "").trim());
  });
  let listGroup: string[] = [];
  let newItems: MultiSelectControlOption[] = [];
  items.forEach((item) => {
    if (!listGroup.includes(item.group || "")) {
      listGroup.push(item.group || "");
      newItems.push({
        label: item.group || "",
        value: item.group || "",
        disabled: true,
        className: "menu-group",
      });
    }
    newItems.push(item);
  });
  return newItems;
};
const MultiSelectControl: React.FC<MultiSelectControlProps> = (props) => {
  const { t } = useTranslation();
  // extract props data
  const ruleList = props.ruleList || [];
  if (props.required) {
    ruleList.push({
      name: "required",
    });
  }

  // init state control
  let initState: MultiSelectControlState = {
    touched: false,
    loading: true,
    items: [],
    value: props.value ? props.value : null,
    valueStr: props.value ? props.value.join(", ") : null,
    valueObj: {
      value: props.value ? props.value : null,
    },
    controlState: {
      invalid: true,
    },
    parentState: {
      root: true,
    },
  };

  const [state, setState] = useState(initState);
  // unsubcribe
  const mountedRef = useRef(true);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  // init list selected item
  useEffect(() => {
    const getData = async () => {
      let items: any = [];
      if (props.searchFn) {
        const res = await props.searchFn();
        if (res) {
          items = res;
          if (items && items.length > 0 && items[0].group) {
            items = groupOption(items);
          }
        }
      } else {
        items = props.enum || [];
        if (items && items.length > 0 && items[0].group) {
          items = groupOption(items);
        }
      }

      const value = props.value || [];
      let valueObj = [];
      let valueStr = "";
      if (value && Array.isArray(value)) {
        valueObj = items.filter((x: any) => value.includes(x.value));
        valueStr = valueObj.map((x: any) => x.label).join(", ");
      }

      const ruleList = props.ruleList || [];
      if (props.required) {
        ruleList.push({
          name: "required",
        });
      }
      let controlState =
        props.controlState ||
        validateControl(ruleList || [], value, t, "multiselect");
      if (!mountedRef.current) return;
      setState({
        ...state,
        value,
        valueStr,
        valueObj,
        controlState,
        loading: false,
        touched: false,
        items,
      });
    };
    getData();

    return () => {
      setState(initState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.searchFn,
    props.enum,
    props.controlState,
    props.value,
    props.required,
    props.ruleList,
    t,
  ]);

  const onChange = async (value: any) => {
    let valueObj = [];
    let valueStr = "";
    if (value && Array.isArray(value)) {
      valueObj = state.items.filter((x: any) => value.includes(x.value));
      valueStr = valueObj.map((x: any) => x.label).join(", ");
    }

    const controlState = validateControl(ruleList, value, t);
    let _state = {
      ...state,
      value,
      valueStr,
      controlState,
      valueObj,
      loading: false,
    };

    if (props.onChange) {
      props.onChange({
        controlState: _state.controlState,
        value: _state.value,
        valueStr: _state.valueStr,
        valueObj: _state.valueObj,
      });
    }
    if (props.onTrueUpdateValue) {
      props.onTrueUpdateValue({
        controlState: _state.controlState,
        value: _state.value,
        valueStr: _state.valueStr,
        valueObj: _state.valueObj,
      });
    }
    if (!jsonEqual(_state, state)) {
      setState(_state);
    }
  };

  const onFocus = () => {
    if (props.onTouched) {
      props.onTouched();
      return;
    }
    if (!state.touched) {
      setState({
        ...state,
        touched: true,
      });
    }
  };

  const valueTemplate = (option: any, props: any) => {
    if (state.loading) {
      return (
        <div className="bc-sc-loading">
          <i className="pi pi-spin pi-spinner"></i>&nbsp;
          {t("base_control_multiselect_loading")}
        </div>
      );
    }
    if (option) {
      if (option.displayLabel) {
        return (
          <div className={`menu-selected-option`}>{option.displayLabel}</div>
        );
      }

      return (
        <div className={`menu-selected-option`}>
          <div>
            {option.group ? `${option.group} / ` : null}
            {option.label}
          </div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const onclickOptionParent = (e: any, parentId: string) => {
    e.preventDefault();
    e.stopPropagation();
    let _state = {
      ...state,
    };
    if (!_state.parentState) {
      _state.parentState = {};
    }
    _state.parentState[parentId] = !_state.parentState[parentId];
    setState(_state);
  };

  const renderAction = () => {
    if (!props.action) {
      return null;
    } else {
      return (
        <Button
          label={props.action?.label}
          icon={props.action?.icon}
          tooltip={props.action?.tooltip}
          tooltipOptions={{ position: "top" }}
          onClick={props.action?.clickFn}
          onMouseDown={props.action?.onMouseDown} // added custom function - aw
          onMouseLeave={props.action?.onMouseLeave} // added custom function - aw
          onTouchStart={props.action?.onTouchStart} // added custom function - aw
          onTouchEnd={props.action?.onTouchEnd} // added custom function - aw
          onMouseUp={props.action?.onMouseUp} // added custom function - aw
          onTouchMove={props.action?.onTouchMove} // added custom function - aw
          className={props.action?.className + " custom-input-action-btn"}
          type="button"
        />
      );
    }
  };
  const renderControl = () => {
    if (props.config?.readOnly && state.valueStr) {
      return (
        <InputText tooltip={state.valueStr} readOnly value={state.valueStr} />
      );
    } else {
      return (
        <MultiSelect
          emptyFilterMessage={
            state.loading
              ? t("base_control_multiselect_loading")
              : t("base_control_multiselect_no_results")
          }
          tooltip={state.valueStr}
          tooltipOptions={{ position: "top" }}
          options={state.items || []}
          style={{ width: "100%" }}
          appendTo={document.body}
          filterPlaceholder={
            props.config?.filter
              ? props.config?.filterPlaceholder
              : t("base_control_multiselect_all")
          }
          {...props.config}
          className={`${props.className} ${
            isError(state, props) ? "p-invalid" : ""
          }`}
          filter={true}
          disabled={props.config?.disabled}
          placeholder={props.placeholder}
          id={props.id}
          value={state.value}
          optionLabel="label"
          filterBy="label"
          panelClassName={`${props.isTree ? "dropdown-tree" : ""} ${
            props.config?.readOnly ? "dropdown-read-only" : ""
          } ${
            props.config?.filter === false
              ? "multiselect-control-no-filter"
              : ""
          }`}
          valueTemplate={valueTemplate}
          itemTemplate={itemTemplate}
          onChange={(event) => onChange(event.target.value)}
          onFocus={() => onFocus()}
          autoFocus={props.autoFocus}
        />
      );
    }
  };

  const itemTemplate = (option: any) => {
    return (
      <div
        className={`menu-option ${props.isTree ? "has-tree" : ""} 
        ${state?.parentState[option.parentId] ? "show-option" : ""} `}
        style={option.style}
      >
        {option.hasChild ? (
          <Button
            type="button"
            icon={`pi ${
              state?.parentState[option.value]
                ? "pi-angle-down"
                : "pi-angle-right"
            }`}
            onClick={(e) => onclickOptionParent(e, option.value)}
            className="toggle-menu-btn  p-button-sm p-button-text"
          />
        ) : null}
        {option.isLeaf ? (
          <Button
            type="button"
            icon="pi pi-plus"
            className="toggle-menu-btn opacity-0 p-button-sm p-button-text"
          />
        ) : null}
        <div>{option.label}</div>
      </div>
    );
  };
  return (
    <>
      <div
        className={`multiselect-control-inner p-field ${
          props.noLabel ? "no-label" : ""
        }`}
      >
        <label htmlFor={props.id}>
          {props.label}
          {props.required && !props.noRequiredLabel ? (
            <small className="required p-invalid">&nbsp;*</small>
          ) : null}
          {props.tooltip ? (
            <Button
              type="button"
              tooltip={props.tooltip}
              tooltipOptions={{ position: "top" }}
              icon="pi pi-info-circle"
              className="p-button-rounded label-help p-button-text p-button-plain"
            />
          ) : null}
        </label>
        <div
          className={`p-inputgroup ${
            isError(state, props) ? "p-inputgroup-error" : ""
          }`}
        >
          {renderControl()}
          {renderAction()}
        </div>
        {props.hintBottom && (
          <div className={"control-hint-bottom"}>{props.hintBottom}</div>
        )}
        {renderError(state, props, t)}
      </div>
    </>
  );
};

export default MultiSelectControl;
